import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CompaniesService } from '../../../page-modules/desk/services/companies/companies.service';
import { DialogService } from '../../services/dialogs/dialog.service';
import { DateFormatService } from '../../../../@vex/services/date-format.service';
import { ICompanyEntity } from '@ipnote/interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.states';
import { selectStateSelectedCompany } from '../../../store/selectors/company.selectors';

@UntilDestroy()
@Component({
  selector: 'app-company-subscriptions',
  templateUrl: './company-subscriptions.component.html',
  styleUrls: ['./company-subscriptions.component.scss'],
})
export class CompanySubscriptionsComponent implements OnInit, OnDestroy {
  selectorIsOpen = false;
  currentSubs: any;
  company$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  company: ICompanyEntity;
  @Input() count: number;
  @Input() commonView: boolean;

  get subscriptionType(): any | null {
    if (this.currentSubs) {
      return this.currentSubs.subscriptionType;
    } else {
      return null;
    }
  }

  get currency(): string {
    if (this.company?.defaultCurrency && ['USD', 'RUB'].includes(this.company.defaultCurrency)) {
      return this.company.defaultCurrency;
    } else {
      return 'USD';
    }
  }

  get price(): number {
    if (!this.currentSubs || !this.currentSubs.price) {
      return 0;
    } else {
      return this.currentSubs?.price;
    }
  }

  constructor(
    private companiesService: CompaniesService,
    private dialogs: DialogService,
    private cd: ChangeDetectorRef,
    public dateFormatService: DateFormatService,
    private store: Store<AppState>,
  ) {
    this.company$.pipe(untilDestroyed(this)).subscribe((company) => {
      this.company = company;
    });
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
