import { TransactionsService } from '../../../page-modules/desk/services/transactions/transactions.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaskFlowService } from '../../../page-modules/desk/services/task-flow/task-flow.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorService } from '../../services/error-service/error-service';
import { ServiceRequestsService } from '../../../page-modules/desk/services/service-requests/service-requests.service';
import { DialogService } from '../../services/dialogs/dialog.service';
import { ServicesService } from '../../../page-modules/desk/services/services/services.service';
import { TranslocoService } from '@ngneat/transloco';
import {
  ICompanyEntity,
  IServiceEntity,
  IServiceRequestEntity,
  ITaskEntity,
  ITransactionEntity,
} from '@ipnote/interface';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { RelationsService } from '../../services/links/relations.service';
import { DownloadService } from '../../services/download/download.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { filter } from 'rxjs/operators';
import { CompaniesService } from '../../../page-modules/desk/services/companies/companies.service';
import { selectStateSelectedCompany } from '#selectors';
import { SpinnerViewService } from '../../services/spinner/spinner-view.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { OIPService } from '../../../page-modules/desk/services/oip/oip.service';
import { FeedbackService } from '../../../page-modules/desk/services/feedback/feedback.service';
import { AnalyticsEventEnum } from '@ipnote/enum';
import { TasksService } from '../../../page-modules/desk/services/tasks/tasks.service';

@UntilDestroy()
@Component({
  selector: 'app-task-action',
  templateUrl: './task-action.component.html',
  styleUrls: ['./task-action.component.scss'],
})
export class TaskActionComponent implements OnDestroy, OnInit {
  //@Input() serviceRequest: IServiceRequestEntity;
  @Input() task: ITaskEntity;
  @Input() category: string;
  @Input() executors: ICompanyEntity[];
  @Input() position: string;

  @Output() rejectSrHandler = new EventEmitter<IServiceRequestEntity>();
  @Output() getMessages = new EventEmitter();
  @Output() updateParent = new EventEmitter<ITaskEntity>();
  @Output() removeTaskFromList = new EventEmitter<number>();
  @Output() getServiceProviders = new EventEmitter();
  services: IServiceEntity[];
  stripeUrlBlank;
  company$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  company: ICompanyEntity = null;

  constructor(
    private downloadService: DownloadService,
    private taskService: TasksService,
    private taskFlowService: TaskFlowService,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private transactionsService: TransactionsService,
    private errorService: ErrorService,
    private serviceRequestsService: ServiceRequestsService,
    private dialogs: DialogService,
    private servicesService: ServicesService,
    private translocoService: TranslocoService,
    public enums: EnumsService,
    private relationsService: RelationsService,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private companiesService: CompaniesService,
    private spinnerService: SpinnerViewService,
    private analyticsService: AnalyticsService,
    private oipService: OIPService,
    private feedbackService: FeedbackService,
  ) {
    this.company$.pipe().subscribe((company) => {
      this.company = company;
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.cd.detach();
  }

  reopen(): void {
    this.taskFlowService.reopen(this.task).subscribe((res: ITaskEntity) => {
      if (res) {
        this.task = Object.assign(this.task, res);
        this.detect();
        this.getMessages.emit();
        this.updateParent.emit(this.task);
      }
    });
  }

  get PayInvoiceButton(): boolean {
    const refillTransaction = this.getRefillTransaction();

    return (
      !this.task.incoming &&
      (refillTransaction?.invoice || refillTransaction?.invoicePaymentUrl) &&
      refillTransaction?.stage === this.enums.transactionStages.PLANNED &&
      [
        this.enums.taskStages.RUNNING,
        this.enums.taskStages.IN_PROGRESS,
        this.enums.taskStages.REOPEN,
        this.enums.taskStages.DONE,
      ].includes(this.task.stage) &&
      this.company.type !== this.enums.companyTypeEnum.PROVIDER_CLIENT
    );
  }
  getRefillTransaction() {
    return this.task?.transactions.find((transaction) => transaction.type === this.enums.transactionTypes.REFILL);
  }

  acceptWork(): void {
    this.taskFlowService.acceptJob(this.task).then((result) => {
      result.subscribe({
        next: (task) => {
          this.task = Object.assign(this.task, task);
          this.getMessages.emit();
          this.detect();
          this.updateParent.emit(this.task);
        },
      });
    });
  }

  leaveFeedback(): void {
    this.taskFlowService.leaveFeedback(this.task).subscribe({
      next: (task) => {
        this.task = Object.assign(this.task, task);
        this.detect();
        this.removeTaskFromList.emit(this.task.id);
      },
    });
  }

  completeWork(): void {
    this.taskFlowService.completeWork(this.task).subscribe({
      next: (res) => {
        if (res) {
          this.task = Object.assign(this.task, res);
          this.getMessages.emit();
          this.detect();
        }
      },
    });
  }

  initiateWork(): void {
    this.taskFlowService.initiateWork(this.task).then((task) => {
      this.task = Object.assign(this.task, task);
      this.getMessages.emit();
      this.detect();
    });
  }

  get serviceRequest(): IServiceRequestEntity {
    if (this.task.serviceRequests) {
      return this.task.serviceRequests[0];
    }
  }

  get transactions(): ITransactionEntity[] {
    return this.task.transactions;
  }

  handleInvoice(task: ITaskEntity, $event?): void {
    this.prevent($event);
    const stripeUrl = task.transactions.find(
      (transaction) => transaction.type === this.enums.transactionTypes.REFILL,
    ).invoicePaymentUrl; // if stripe url exist
    const file = task.transactions.find(
      (transaction) => transaction.type === this.enums.transactionTypes.REFILL,
    ).invoice; // if stripe url non exist
    if (stripeUrl) {
      if (!this.stripeUrlBlank) {
        this.stripeUrlBlank = window.open('', '_blank');
      }
      this.stripeUrlBlank.document.write('Loading preview...');
      this.stripeUrlBlank.location.href = stripeUrl;
    } else if (file) {
      this.downloadService.getDownloadFileS3(file.url, file.name).subscribe();
    }
    this.analyticsService.sendEvent(AnalyticsEventEnum.PAY_INVOICE, { prices: task.prices });
  }

  get tr(): ITransactionEntity {
    return this.transactions[0];
  }

  detect(): void {
    this.cd.detectChanges();
  }

  prevent($event: Event): void {
    $event?.stopPropagation();
    $event?.preventDefault();
  }

  get leaveFeedBackButton() {
    return (
      !this.task.feedback &&
      !this.PayInvoiceButton &&
      this.task.stage === this.enums.taskStages.DONE &&
      !this.task.incoming
    );
  }

  async acceptServiceRequest(sr: IServiceRequestEntity) {
    const task = await this.taskFlowService.acceptServiceRequest(sr, this.task);
    this.task = task;
    this.updateParent.emit(task);
    this.getMessages.emit();
    this.handleInvoice(task);
  }

  offerPrice(): void {
    this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_OFFER_STARTED, { taskId: this.task.id });
    this.taskFlowService
      .offerPrice(this.task)
      .pipe(filter((p) => !!p))
      .subscribe({
        next: (offer) => {
          this.task.serviceRequests[0].offer = offer;
          this.getMessages.emit();
          this.detect();
          this.updateParent.emit(this.task);
        },
        error: (err) => this.errorService.handleError(err),
      });
  }

  rejectServiceRequest(serviceRequest: IServiceRequestEntity): void {
    this.taskFlowService.rejectServiceRequest(serviceRequest).subscribe(
      async () => {
        this.rejectSrHandler.emit(serviceRequest);
        await this.router.navigate(['../'], {
          relativeTo: this.route,
        });
      },
      (err) => this.errorService.handleError(err),
    );
  }
}
