import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RelationsService } from '../../services/links/relations.service';
import { flatMap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CollaboratorsService } from 'app/page-modules/desk/services/collaborators/collaborators.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateFormatService } from '../../../../@vex/services/date-format.service';
import { ITaskEntity, IUserEntity } from '@ipnote/interface';

@UntilDestroy()
@Component({
  selector: 'app-entity-tasks',
  templateUrl: './entity-tasks.component.html',
  styleUrls: ['./entity-tasks.component.scss']
})
export class EntityTasksComponent implements OnInit, OnDestroy {
  tasks: ITaskEntity[] = [];
  displayedColumns: string[] = ['title', 'responsible', 'dateEnd', 'internalExecutor', 'amount', 'stage'];
  collaborators: any;

  private _target: number;
  private _company: number;

  @Input() set target(target: number) {
    this._target = target;
    this.reset();
  }

  get target(): number {
    return this._target;
  }

  @Input() set company(company: number) {
    this._company = company;
  }

  get company(): number {
    return this._company;
  }

  constructor(
    private relationsService: RelationsService,
    private route: ActivatedRoute,
    private router: Router,
    private collaboratorsService: CollaboratorsService,
    public dateFormatService: DateFormatService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  private reset() {
    this.relationsService
      .getAll(this.target, { $or: [{ toModel: 'Task' }, { fromModel: 'Task' }] })
      .pipe(
        map((links) =>
          links
            .filter((link) => link.fromModel === 'Task' || link.toModel === 'Task')
            .map((link) => (link.fromModel === 'Task' ? link.from : link.to))
        ),
        flatMap((tasks) =>
          this.collaboratorsService.get(this.company).pipe(
            untilDestroyed(this),
            map((collaborators) => {
              // this.collaboratorsService.convertCollaborators();
              this.collaborators = collaborators ? collaborators.collaborators : null;
              return tasks;
            })
          )
        )
      )
      .subscribe((data) => {
        for (let i = 0; i < data.length; i++) {
          const c = this.collaborators.find((item: IUserEntity) => {
            return item.id === data[i].responsible;
          });

          const e = this.collaborators.find((item: IUserEntity) => {
            return item.id === data[i].internalExecutor;
          });

          data[i].responsible = c ? c.name : c;
          data[i].internalExecutor = e ? e.name : e;
        }
        this.tasks = data;
      });
  }

  async navigateToTask(task: ITaskEntity) {
    await this.router.navigate(['../../..', 'tasks', task.id, 'info'], {
      relativeTo: this.route.parent
    });
  }
}
