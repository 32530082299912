<button *ngIf="pages > 1"
        mat-button
        (click)="previous()"
        [disabled]="pageIndex <= 0"
        matTooltip="{{ 'previous-page' | transloco }}">
  <mat-icon class="arrow-icon">keyboard_arrow_left</mat-icon>
</button>
<span *ngIf="pages > 1"
      class="pageIndex">{{pageIndex + 1}} из {{pages}}</span>
<button *ngIf="pages > 1"
        mat-button
        (click)="next()"
        [disabled]="pageIndex >= pages - 1"
        matTooltip="{{'next-page' | transloco}}">
  <mat-icon class="arrow-icon">keyboard_arrow_right</mat-icon>
</button>
