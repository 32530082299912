import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ICompanyEntity, IUserEntity } from '@ipnote/interface';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChoiceCompanyDialogComponent } from '../choice-company-dialog/choice-company-dialog.component';
import icSearch from '@iconify/icons-ic/twotone-search';

@UntilDestroy()
@Component({
  selector: 'app-choice-user-button',
  templateUrl: './choice-user-button.component.html',
  styleUrls: ['./choice-user-button.component.scss'],
})
export class ChoiceUserButtonComponent {
  @Input() users: IUserEntity[];
  @Output() saveUser = new EventEmitter<IUserEntity>();

  searchCtrl = new UntypedFormControl();
  activeUser: IUserEntity;
  icSearch = icSearch;

  constructor() {
  }

  @ViewChild(ChoiceCompanyDialogComponent, { static: true }) dropdown: ChoiceCompanyDialogComponent;
  @ViewChild('ref') ref: ElementRef<HTMLElement>;

  public showDropdown($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.dropdown.show();
  }

  selectUser(): void {
    this.saveUser.emit(this.activeUser);
    this.dropdown.hide();
  }
}
