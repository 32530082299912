import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOfferEntity } from '@ipnote/interface';
import { concatLatestFrom } from '@ngrx/effects';
import { flatMap, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import {
  RequestQueryBuilderService
} from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { MinOfferDto } from '@ipnote/dto';

@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(private http: HttpClient, private baseUrl: BaseUrlService, public queryBuilder: RequestQueryBuilderService) {
  }

  offer(id: number, body: any): Observable<IOfferEntity> {
    body = { ...body, serviceRequestId: id };
    return this.baseUrl.getBaseUrl('offer').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({})
      ]),
      flatMap(([url, params]) => this.http.post<IOfferEntity>(url, body, { params }))
    );
  }

  updateOffer(id: number, body: any): Observable<IOfferEntity> {
    body = { ...body };
    return this.baseUrl.getBaseUrl(`offer/${id}`).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({})
      ]),
      flatMap(([url, params]) => this.http.patch<IOfferEntity>(url, body, { params }))
    );
  }

  adminEditOffer(id: number, body: any): Observable<IOfferEntity> {
    body = { ...body };
    return this.baseUrl.getBaseUrl(`offer/${id}`).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({})
      ]),
      mergeMap(([url, params]) => this.http.put<IOfferEntity>(url, body, { params }))
    );
  }

  minOffer(id: number): Observable<MinOfferDto> {
    return this.baseUrl.getBaseUrl('offer/get-min-offer/').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({ additionalOptions: { forTask: id } })
      ]),
      flatMap(([url, params]) => this.http.get<MinOfferDto>(url, { params }))
    );
  }
}
