import { FileTagsComponent } from './file-tags/file-tags.component';
import { FilesOrderComponent } from './files-order/files-order.component';
import { FilesSearchComponent } from './files-search/files-search.component';
import { FilesComponent } from './files.component';

export {
  FilesSearchComponent,
  FilesOrderComponent,
  FileTagsComponent,
  FilesComponent
};
