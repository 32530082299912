import { TaskFlowDialogComponent } from './../task-flow-dialog/task-flow-dialog.component';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IServiceEntity } from '@ipnote/interface';
import { uniqBy } from 'lodash';

@Component({
  selector: 'app-choice-service-button',
  templateUrl: './choice-service-button.component.html',
  styleUrls: ['./choice-service-button.component.scss']
})
export class ChoiceServiceButtonComponent {
  @Input() services: IServiceEntity[];
  @Input() color: string;
  @Input() position: string;
  @Output() saveService = new EventEmitter<IServiceEntity>();

  public activeService: number;

  constructor() {
  }

  @ViewChild(TaskFlowDialogComponent, { static: true }) dropdown: TaskFlowDialogComponent;
  @ViewChild('ref') ref: ElementRef<HTMLElement>;

  public showDropdown($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.dropdown.show();
  }

  choiceService(service: IServiceEntity): void {
    const { id } = service;
    this.activeService = id;

    this.saveService.emit(service);
    this.dropdown.hide();
  }

  getCategories() {
    if (!this.services) return;
    return uniqBy(
      this.services.map((item) => {
        return item.category;
      }),
      'id'
    );
  }

  getCategoryServices(category) {
    return this.services.filter((item) => item.category.id === category.id);
  }
}
