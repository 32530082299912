<mat-form-field class="example-chip-list">
  <mat-label>{{ (label | transloco) || ('countries' | transloco) }}</mat-label>
  <mat-chip-list
    #chipList
    aria-label="Country selection"
    [required]=requireState>
    <mat-chip class="mat-chip-main"
              *ngFor="let country of selectedCountries"
              [selectable]="true"
              [removable]="true"
              [disabled]="disabled"
              (removed)="remove(country)">
      <div class="flex justify-between w-full">
        <div class="mat-chip-content">
          <img [src]="country|countryFlag"
               *ngIf="country|countryFlag"
               class="flag inline-block" alt="">
          <span>{{country | transloco }}</span>
        </div>
        <mat-icon matChipRemove>cancel</mat-icon>
      </div>
    </mat-chip>
    <input
      #countryInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selected($event)" [classList]="'mat-select-arrow arrow-position'">
    <ng-container *ngIf="categories && categories.length">
      <mat-optgroup *ngFor="let category of categories"
                    [label]="getCategoryCountries(category, filtered | async)?.length ? (category | transloco) : null">
        <mat-option *ngFor="let country of getCategoryCountries(category, filtered | async)" [value]="country">
          <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="start center">
            <span>{{country.toString() | transloco}}</span>
          </div>
        </mat-option>
      </mat-optgroup>
    </ng-container>
    <ng-container *ngIf="!categories || !categories.length">
      <mat-option *ngFor="let country of filtered | async" [value]="country">
        <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="start center">
          <!--        <img *ngIf="country|countryFlag" class="flag" [src]="country|countryFlag">-->
          <span>{{country | transloco}}</span>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
