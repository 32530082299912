import { UserSettingsInterface } from './user-settings.interface';
import { UserNotificationType } from '../type/user-notification.type';
import { ICompanyEntity } from '../../company/interface/company.entity.interface';

export class IUserUpdateDto {
  name?: string;
  secondName?: string;
  avatar?: string | null;
  settings?: UserSettingsInterface;
  companyToPay?: ICompanyEntity;
  notifications?: Partial<UserNotificationType>;
  skipTwoFAUntil?: Date | null;
  twoFASkippedTimes?: number;
}
