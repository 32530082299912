import { UserNotificationTransport } from './enums/user-notification-transport.enum';
import { NotificationPurpose } from '../notification/notification-purpose.enum';
import { UserNotificationType } from './type/user-notification.type';

const defaultNotifications = {
  [UserNotificationTransport.EMAIL]: true,
  [UserNotificationTransport.DISCORD]: false,
};

const defaultTurnOffNotifications = {
  [UserNotificationTransport.EMAIL]: false,
  [UserNotificationTransport.DISCORD]: false,
};

export const userNotifications: Partial<UserNotificationType> = {
  [NotificationPurpose.NEW_DOCUMENT_RECEIVED]: defaultNotifications,
  [NotificationPurpose.MISSED_MESSAGES]: defaultNotifications,
  [NotificationPurpose.NEW_TASK_RECEIVED]: defaultTurnOffNotifications,
  [NotificationPurpose.TASK_UPDATED]: defaultTurnOffNotifications,
  [NotificationPurpose.CHANGE_TASK_STATUS]: defaultNotifications,
  [NotificationPurpose.TASK_STARTED_TIME]: defaultNotifications,
  [NotificationPurpose.TASK_EXPIRED]: defaultNotifications,
  [NotificationPurpose.REMINDER]: defaultNotifications,
  [NotificationPurpose.GOT_SERVICE_REQUEST]: defaultNotifications,
  [NotificationPurpose.TOP_UP_COMPANY_BALANCE]: defaultNotifications,
  [NotificationPurpose.TASK_IS_PAID]: defaultNotifications,
};
