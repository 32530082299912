<span
  class="whitespace-nowrap completed-tasks"
  [matTooltipPosition]="'above'"
  *ngIf="provider?.companyPublicInfo?.completedTaskCount; else noCompletedTasks"
  [matTooltip]="
    !showToolTip
      ? null
      : provider?.companyPublicInfo?.completedTaskCount
      ? 'The provider has completed ' +
        provider?.companyPublicInfo?.completedTaskCount +
        ' orders for ' +
        (provider?.companyPublicInfo?.totalPriceForCompletedTasks | currency : 'USD' : 'symbol' : '1.0-2')
      : 'Provider has no completed orders'
  "
>
  {{ provider?.companyPublicInfo?.completedTaskCount }} for
  {{ provider?.companyPublicInfo?.totalPriceForCompletedTasks | currency : 'USD' : 'symbol' : '1.0-0' }}
</span>
<ng-template #noCompletedTasks>
  <span>No completed tasks</span>
</ng-template>
