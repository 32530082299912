import { IpFeesCurrency } from '../enums/ip-fees-currency.enum';
import { Jurisdiction } from '../enums/jurisdiction.enum';

export interface ICalculateIpFeesRes {
  jurisdictionFees: IJurisdictionFeesItem[];
  totalOfficialFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  totalPartnerFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  totalTranslationFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  totalServiceFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  grandTotalFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  errors: [];
}

export interface IJurisdictionFeesItem {
  jurisdiction: Jurisdiction;
  language: string;
  officialFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  partnerFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  translationFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  serviceFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
  totalFee: {
    mandatoryAmount: number;
    optionalAmount: number;
    currency: IpFeesCurrency;
  };
}
