import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: 'million'
})
export class MillionPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe, private currencyPipe: CurrencyPipe) {

  }

  transform(value: any, digits?: any): any {
    if (value >= 1000000) {
      return '$' + this.decimalPipe.transform(value / 1000000, digits) + 'M';
    } else {
      return this.currencyPipe.transform(value, 'USD', true, '3.0-0');
    }
  }
}
