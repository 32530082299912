import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OnboardingProviderPanelState } from '../reducers/onboarding-provider-panel.reduser';

export const featureSelector = createFeatureSelector<OnboardingProviderPanelState>('onboardingProviderPanel');
export const publicProfileButtonSelector = createSelector(
  featureSelector,
  state => state.actionButton
);
export const panelStateSelector = createSelector(
  featureSelector,
  state => state.panelIsOpen
);
