import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';

@Pipe({
  name: 'without'
})
export class WithoutPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return filter(value, (item) => {
      return item.fromModel !== 'Company' && item.toModel !== 'Company' && item.link !== 'Company';
    }).length;
  }
}
