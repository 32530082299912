import { Component, EventEmitter, Input, Output } from '@angular/core';
import icSort from '@iconify/icons-ic/baseline-sort';
import icDone from '@iconify/icons-ic/baseline-done';
import icArrowUp from '@iconify/icons-ic/arrow-upward';
import icArrowDown from '@iconify/icons-ic/arrow-downward';
import { fileOrders, IFileSortSelect } from '@ipnote/interface';

@Component({
  selector: 'app-files-order',
  templateUrl: './files-order.component.html',
  styleUrls: ['./files-order.component.scss']
})
export class FilesOrderComponent {
  icSort = icSort;
  icDone = icDone;
  icArrowUp = icArrowUp;
  icArrowDown = icArrowDown;

  orders: IFileSortSelect[] = fileOrders;

  _ordersSelect: IFileSortSelect;
  @Input()
  set ordersSelect(order: IFileSortSelect) {
    this._ordersSelect = order;
  }

  get ordersSelect(): IFileSortSelect {
    return this._ordersSelect;
  }

  @Output()
  changeOrder = new EventEmitter<IFileSortSelect>();

  constructor() {
  }


  async setOrderSelect(order: IFileSortSelect, index: number) {
    if (this.ordersSelect === order) {
      this.orders[index].order = this.orders[index].order === 'ASC' ? 'DESC' : 'ASC';
    }
    this.ordersSelect = order;
    this.changeOrder.emit(order);
  }

  async changeDirectionSort(order: IFileSortSelect, e: Event) {
    e.preventDefault();
    const indexOrder = this.orders.findIndex((item) => item.nameTranslocoKey === order.nameTranslocoKey);
    await this.setOrderSelect(order, indexOrder);
  }

}
