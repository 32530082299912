import { Pipe, PipeTransform } from '@angular/core';
import { ICompanyEntity } from '@ipnote/interface';

@Pipe({
  name: 'company'
})
export class CompanyPipe implements PipeTransform {
  transform(value: ICompanyEntity, field: string): string {
    return value ? value[field] : null;
  }
}
