<div class="wrapper">
  <div class="title">
    <h2 mat-dialog-title [innerHTML]="'Cancel subscription'"></h2>
  </div>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>
    <div>We’re sorry to see you go! Your plan will be available until the end of a billing cycle.</div>
    <div>Here’s what you’ll no longer have after {{data.currentPeriodEnd | date: 'MMM d, y'}}</div>
    <ul *ngIf="company?.isServiceProvider">
      <li>50% commission reduction</li>
      <li>credibility & promotion</li>
      <li>personal assistance</li>
      <li>top priority in the list of providers</li>
      <li>competitor insights</li>
    </ul>
    <ul *ngIf="!company?.isServiceProvider">
      <li>commission reduction</li>
      <li>personal assistance</li>
<!--      <li>up to {{(subscriptionStore$ | async).subscription?.subscriptionType.allowedOipCount}} IP rights</li>-->
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions class="pt-6">
    <button mat-raised-button color="primary" class="modal-button yes-button" (click)="decline()">
      <span>Keep Plan</span>
    </button>
    <button mat-button class="modal-button" (click)="accept()">
      Cancel subscription
    </button>
  </mat-dialog-actions>
</div>
