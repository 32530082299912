import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ResetFilterService } from '../../services/reset-filter/reset-filter.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chips-bar',
  templateUrl: './chips-bar.component.html',
  styleUrls: ['./chips-bar.component.scss'],
})
export class ChipsBarComponent implements OnInit {
  _filterChips: string[] = [];
  _visibleChips: string[];
  allChipsState = false;
  countVisibleChips = 3;

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  // @Output() reset: EventEmitter<void> = new EventEmitter<void>();

  get filterChips() {
    return this._filterChips;
  }
  @Input() set filterChips(values: string[]) {
    this._filterChips = values;
  }
  constructor(
    private renderer: Renderer2,
    private resetFilterService: ResetFilterService,
    private route: ActivatedRoute,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.toggleButton && this.menu) {
        if (
          e.target !== this.toggleButton.nativeElement &&
          e.target !== this.menu.nativeElement &&
          !this.menu.nativeElement.contains(e.target)
        ) {
          this.allChipsState = false;
        }
      }
    });
  }

  get visibleChips() {
    return this.filterChips.slice(0, this.countVisibleChips);
  }

  get allChips() {
    return this.filterChips.slice(this.countVisibleChips);
  }

  toggleAllChips() {
    this.allChipsState = !this.allChipsState;
  }

  ngOnInit() {}

  resetFilter() {
    this.resetFilterService.reset(this.route.snapshot.queryParams, this.route);
    this._filterChips = [];
  }
}
