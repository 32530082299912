<div class="settings-sidenav">
  <h2 class="settings-sidenav__title">
    <app-edit-columns-icon fill="#8997B1" class="title-icon"></app-edit-columns-icon>
    {{ 'settings-columns' | transloco }}
    <button mat-icon-button type="button" (click)="close()">
      <mat-icon class="close-icon" size="14px">clear</mat-icon>
    </button>
  </h2>
  <div>
    <div *ngFor="let item of fixedFields" class="item">
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px">
        <mat-checkbox [disabled]=true
                      [checked]="item.isChecked"
                      color="primary"
                      (change)="toggleVisibility(item)"
                      matTooltip="{{ 'toggle-visibility-column' | transloco }}">
        </mat-checkbox>
        <h4 fxFlex matLine>{{ item.label | transloco }}</h4>
      </div>
    </div>
  </div>
  <div class="list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div *ngFor="let item of fields" cdkDrag class="item">
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="16px">
        <mat-checkbox
          [checked]="item.isChecked"
          color="primary"
          (change)="toggleVisibility(item)"
          matTooltip="{{ 'toggle-visibility-column' | transloco }}">
        </mat-checkbox>
        <h4 fxFlex matLine>{{ item.label | transloco }}</h4>
        <mat-icon class="drag-handle" cdkDragHandle>drag_indicator</mat-icon>
      </div>
    </div>
  </div>
  <div class="filter-buttons">
    <button
      (click)="save()"
      class="w-full"
      mat-raised-button
      [disabled]="!dirty"
      color="primary">
      {{ 'save' | transloco }}
    </button>
    <button
      mat-button
      class="reset-button"
      (click)="close()"
      type="reset"
      [disabled]="!dirty">
      {{'reset' | transloco}}
    </button>
  </div>
</div>

