import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'settingsFields'
})
export class SettingsFieldsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return value.filter((item) => item.type.indexOf('checkbox') === -1);
  }
}
