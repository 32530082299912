import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-columns-icon',
  templateUrl: './edit-columns-icon.component.svg',
  styleUrls: ['./edit-columns-icon.component.scss']
})
export class EditColumnsIconComponent {
  @Input() fill: string;
}
