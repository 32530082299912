export enum NotificationPurpose {
  CHANGE_TASK_STATUS = 'change_task_status',
  NEW_DOCUMENT_RECEIVED = 'new_document_received',
  NEW_TASK_RECEIVED = 'new_task_received',
  TASK_UPDATED = 'task_updated',
  TASK_STARTED_TIME = 'task_started_time',
  TASK_EXPIRED = 'task_expired',
  MISSED_MESSAGES = 'missed_messages',
  REMINDER = 'reminder',
  NEW_PASSWORD = 'new_password',
  EMAIL_CONFIRMATION = 'email_confirmation',
  GOT_SERVICE_REQUEST = 'got_service_request',
  INVITE_UNREGISTERED_USER = 'invite_unregistered_user',
  INVITE_REGISTERED_USER = 'invite_registered_user',
  EXPULSE_FROM_COMPANY = 'expulse_from_company',
  NEW_REGISTRATION = 'new_registration',
  TOP_UP_COMPANY_BALANCE = 'top_up_company_balance',
  TASK_IS_PAID = 'task_is_paid',
  NEW_COMPANY_PUBLIC_INFO = 'new_company_public_info',
  TASK_CONFIRMED_TO_MANAGER = 'task_confirmed_to_manager',
  ONBOARDING = 'onboarding',
  PUBLIC_PROFILE = 'public_profile',
  TASK_ACCEPTED_TO_MANAGER = 'task_accepted_to_manager',
  TASK_AUTOMATICALLY_ACCEPTED = 'task_automatically_accepted',
  CUSTOM_NOTIFICATION = 'custom_notification',
  VERIFIED_EMAIL = 'verified_email',
  PAYOUT = 'payout',
  TASK_AUTOMATICALLY_DELETED = 'task_automatically_deleted',
  REMINDER_DEADLINE = 'reminder_deadline', // for reminder entities
  PERSONAL_ASSISTANCE = 'personal_assistance',
  GOT_NEW_FEEDBACK = 'got_new_feedback',
  AGENT_ASSIGNMENT = 'agent_assignment',
  NEW_PAYOUTS_INVOICE = 'new_payouts_invoice',
  IP_RIGHT_TRACKING = 'ip_right_tracking',
  PRICE_UPDATED_TO_MANAGER = 'price_updated_to_manager',
  VERIFICATION_CODE = 'verification_code',
  NEW_PASSWORD_GENERATED = 'new_password_generated',
  NEW_AUTO_OFFER = 'new_auto_offer',
  AUTO_OFFER_UPDATED = 'auto_offer_updated',
  OFFER_HAS_BEEN_RESET = 'offer_has_been_reset',
  INVITE_REFERRAL = 'invite_referral',
  OIPS_HAVE_BEEN_UPDATED = 'oips_have_been_updated',
  OIPS_HAVE_BEEN_UPDATED_TO_RESPONSIBLES = 'oips_have_been_updated_to_responsibles',
  NOT_FOR_PLATFORM_USER = 'not_for_platform_user ',
  TRADEMARK_SEARCH_SUBSCRIPTION_HAS_BEEN_PAID = 'trademark_search_subscription_has_been_paid',
  PAYMENT_SUCCEEDED = 'payment_succeeded',
  INVITEE_REWARD = 'invitee_reward',
  FOR_ALL_USERS = 'for_all_users',
  TASK_DESCRIPTION_UPDATED = 'task_description_updated',
  PAYOUT_ERROR = 'payout_error',
  TRANSFER_ERROR = 'transfer_error',
}
