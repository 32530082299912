import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import icSearch from '@iconify/icons-ic/twotone-search';
import icClose from '@iconify/icons-ic/twotone-close';

@UntilDestroy()
@Component({
  selector: 'app-files-search',
  templateUrl: './files-search.component.html',
  styleUrls: ['./files-search.component.scss']
})
export class FilesSearchComponent implements OnInit {
  searchCtrl = new UntypedFormControl();
  icSearch = icSearch;
  icClose = icClose;
  isFocus: boolean;
  @Input() isShown: boolean;
  @Output() isShownChange = new EventEmitter<boolean>();

  @Input() set token(token: string) {
    this.searchCtrl.setValue(token);
  }

  @Output() tokenChange = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
    this.searchCtrl.valueChanges.pipe(debounceTime(400), untilDestroyed(this)).subscribe((token) => this.tokenChange.emit(token));
  }

  updateShown(b: boolean) {
    this.isShown = b;
    this.isShownChange.emit(b);
  }

  reset(e) {
    e.preventDefault();
    this.searchCtrl.setValue('');
    this.tokenChange.emit('');
  }

  ngOnDestroy(): void {
  }

}
