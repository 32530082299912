export enum MarkifyDatabase {
  // EU
  AT = 'at', // Austria
  BX = 'bx', // Benelux -
  BG = 'bg', // Bulgaria
  HR = 'hr', // Croatia
  CY = 'cy', // Cyprus
  CZ = 'cz', // Czech Republic
  DK = 'dk', // Denmark
  EE = 'ee', // Estonia
  EUIPO = 'euipo', // EUIPO -
  FI = 'fi', // Finland
  FR = 'fr', // France
  DE = 'de', // Germany
  GR = 'gr', // Greece
  HU = 'hu', // Hungary
  IE = 'ie', // Ireland
  IT = 'it', // Italy
  LV = 'lv', // Latvia
  LT = 'lt', // Lithuania
  MT = 'mt', // Malta
  PL = 'pl', // Poland
  PT = 'pt', // Portugal
  RO = 'ro', // Romania
  SK = 'sk', // Slovakia
  SI = 'si', // Slovenia
  ES = 'es', // Spain
  SE = 'se', // Sweden

  // Europe (non-EU)
  AL = 'al', // Albania
  AD = 'ad', // Andorra
  AM = 'am', // Armenia
  AZ = 'az', // Azerbaijan
  BY = 'by', // Belarus
  BA = 'ba', // Bosnia and Herzegovina
  GE = 'ge', // Georgia
  GI = 'gi', // Gibraltar
  GG = 'gg', // Guernsey
  IS = 'is', // Iceland
  JE = 'je', // Jersey
  XK = 'xk', // Kosovo
  LI = 'li', // Liechtenstein
  MK = 'mk', // North Macedonia
  MD = 'md', // Moldova
  MC = 'mc', // Monaco
  ME = 'me', // Montenegro
  NO = 'no', // Norway
  RU = 'ru', // Russia
  SM = 'sm', // San Marino
  RS = 'rs', // Serbia
  CH = 'ch', // Switzerland
  TR = 'tr', // Turkey
  NCY = 'ncy', // Turk Rep Cyprus -
  UA = 'ua', // Ukraine
  UK = 'uk', // United Kingdom -(GB)

  // North America
  BM = 'bm', // Bermuda
  CA = 'ca', // Canada
  MX = 'mx', // Mexico
  USPTO = 'uspto', // United States -(US)

  // Central America
  CR = 'cr', // Costa Rica
  SV = 'sv', // El Salvador
  GT = 'gt', // Guatemala
  HN = 'hn', // Honduras
  NI = 'ni', // Nicaragua
  PA = 'pa', // Panama

  // Caribbean
  AI = 'ai', // Anguilla
  AG = 'ag', // Antigua and Barbuda
  AW = 'aw', // Aruba
  BS = 'bs', // Bahamas
  BB = 'bb', // Barbados
  VG = 'vg', // British Virgin Islands
  BQ = 'bq', // Caribbean Netherlands
  KY = 'ky', // Cayman Islands
  CU = 'cu', // Cuba
  CW = 'cw', // Curaçao
  DM = 'dm', // Dominica
  DO = 'do', // Dominican Republic
  GD = 'gd', // Grenada
  MS = 'ms', // Montserrat
  HT = 'ht', // Haiti
  JM = 'jm', // Jamaica
  KN = 'kn', // St. Kitts and Nevis
  LC = 'lc', // St. Lucia
  SX = 'sx', // St. Maarten (Dutch part)
  VC = 'vc', // St. Vincent and the Grenadines
  TT = 'tt', // Trinidad and Tobago
  TC = 'tc', // Turks & Caicos

  // South America
  AR = 'ar', // Argentina
  BR = 'br', // Brazil
  BZ = 'bz', // Belize
  BO = 'bo', // Bolivia
  CL = 'cl', // Chile
  CO = 'co', // Colombia
  EC = 'ec', // Ecuador
  GY = 'gy', // Guyana
  PY = 'py', // Paraguay
  PE = 'pe', // Peru
  SR = 'sr', // Suriname
  UY = 'uy', // Uruguay
  VE = 've', // Venezuela

  // Asia
  BD = 'bd', // Bangladesh
  BT = 'bt', // Bhutan
  BN = 'bn', // Brunei
  KH = 'kh', // Cambodia
  CN = 'cn', // China
  JP = 'jp', // Japan
  HK = 'hk', // Hong Kong
  IN = 'in', // India
  ID = 'id', // Indonesia
  KZ = 'kz', // Kazakhstan
  KG = 'kg', // Kyrgyzstan
  MO = 'mo', // Macao
  MY = 'my', // Malaysia
  MV = 'mv', // Maldives
  MN = 'mn', // Mongolia
  MM = 'mm', // Myanmar
  NP = 'np', // Nepal
  SG = 'sg', // Singapore
  KR = 'kr', // South Korea
  LK = 'lk', // Sri Lanka
  TW = 'tw', // Taiwan
  TJ = 'tj', // Tajikistan
  UZ = 'uz', // Uzbekistan
  VN = 'vn', // Vietnam
  LA = 'la', // Laos
  PH = 'ph', // Philippines
  TH = 'th', // Thailand
  TM = 'tm', // Turkmenistan

  // Middle East
  AF = 'af', // Afghanistan
  BH = 'bh', // Bahrain
  EG = 'eg', // Egypt
  GZ = 'gz', // Gaza -(is Palestine)
  IR = 'ir', // Iran
  IQ = 'iq', // Iraq
  IL = 'il', // Israel
  JO = 'jo', // Jordan
  KU = 'ku', // Kurdistan -(is Iraq)
  KW = 'kw', // Kuwait
  LB = 'lb', // Lebanon
  OM = 'om', // Oman
  PK = 'pk', // Pakistan
  QA = 'qa', // Qatar
  SA = 'sa', // Saudi Arabia
  SY = 'sy', // Syria
  AE = 'ae', // UAE (United Arab Emirates)
  PS = 'ps', // West Bank -(is Palestine)
  YD = 'yd', // Yemen (Aden) -(is Yemen)
  YE = 'ye', // Yemen (Sanaa)

  // Africa
  DZ = 'dz', // Algeria
  AP = 'ap', // ARIPO -
  AO = 'ao', // Angola
  BW = 'bw', // Botswana
  CV = 'cv', // Cape Verde (Cabo Verde)
  DJ = 'dj', // Djibouti
  ET = 'et', // Ethiopia
  GM = 'gm', // Gambia
  GH = 'gh', // Ghana
  KE = 'ke', // Kenya
  LS = 'ls', // Lesotho
  MW = 'mw', // Malawi
  MG = 'mg', // Madagascar
  MU = 'mu', // Mauritius
  MA = 'ma', // Morocco
  MZ = 'mz', // Mozambique
  NA = 'na', // Namibia
  NG = 'ng', // Nigeria
  OA = 'oa', // OAPI -
  RW = 'rw', // Rwanda
  SC = 'sc', // Seychelles
  ST = 'st', // Sao Tome and Principe
  SL = 'sl', // Sierra Leone
  ZA = 'za', // South Africa
  SD = 'sd', // Sudan
  SZ = 'sz', // Swaziland (Eswatini)
  TZ = 'tz', // Tanzania
  TN = 'tn', // Tunisia
  UG = 'ug', // Uganda
  ZM = 'zm', // Zambia
  TZZ = 'tzz', // Zanzibar -(is Tanzania)
  ZW = 'zw', // Zimbabwe

  // Oceania
  AU = 'au', // Australia
  MH = 'mh', // Marshall Islands
  NZ = 'nz', // New Zealand
  PG = 'pg', // Papua New Guinea
  WS = 'ws', // Samoa
  SB = 'sb', // Solomon Islands
  TO = 'to', // Tonga
  VU = 'vu', // Vanuatu
  PF = 'pf', // French Polynesia
  FJ = 'fj', // Fiji

  // International
  WIPO = 'wipo', // WIPO -
  LISBON = 'lisbon', // Lisbon (WIPO Appellations of Origin) -
}
