import { Component, HostListener, OnInit } from '@angular/core';
import icAssignment from '@iconify/icons-ic/baseline-assignment';
import icWork from '@iconify/icons-ic/round-work';
import icBuild from '@iconify/icons-ic/baseline-apartment';
import icAccount from '@iconify/icons-material-symbols/account-circle';
import { Observable } from 'rxjs';
import { ICompanyEntity, IUserSignInRes } from '@ipnote/interface';
import { selectStateSelectedCompany } from '../../../store/selectors/company.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app.states';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { selectStateUserInfo } from '#selectors';

@UntilDestroy()
@Component({
  selector: 'app-onboardin-preview',
  templateUrl: './onboardin-preview.component.html',
  styleUrls: ['./onboardin-preview.component.scss'],
})
export class OnboardinPreviewComponent implements OnInit {
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  public selectedCompany: ICompanyEntity;
  user: IUserSignInRes;
  user$: Observable<IUserSignInRes> = this.store.select(selectStateUserInfo);
  innerWidth;
  constructor(public store: Store<AppState>) {
    this.selectedCompany$.subscribe((company) => {
      this.selectedCompany = company;
    });
    this.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
  }

  icons = {
    icAssignment,
    icWork,
    icBuild,
    icAccount,
  };
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }
}
