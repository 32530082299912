<div class="title">
  <h2 mat-dialog-title>Request to edit</h2>
  <button class="button-close" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{'description' | transloco}}</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="pt-6">
  <div class="space"></div>
  <button [disabled]="serverRequesting" mat-raised-button color="primary" class="send-button" (click)="send()">
    request to edit
  </button>
</mat-dialog-actions>
