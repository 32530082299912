<div class="filter-sidenav">
  <h2 class="filter-sidenav__title">
    <mat-icon class="title-icon" size="18px">filter_list</mat-icon>
    {{ 'filter' | transloco }}
    <button mat-icon-button type="button" (click)="close()">
      <mat-icon class="close-icon" size="14px">clear</mat-icon>
    </button>
  </h2>
  <form [formGroup]="form"
        novalidate
        (reset)="reset()"
        (ngSubmit)="saveFilter()">
    <div class="filter-field" *ngFor="let item of data | filterFields">
      <mat-form-field *ngIf="item.filterSettings && item.filterSettings.type === 'text'">
        <mat-label>{{ item.label | transloco }}</mat-label>
        <input matInput
               placeholder="{{ item.label | transloco }}"
               [formControlName]=getFormControlName(item)
               *ngIf="item.type !== 'check-mark'">
        <mat-select #matSel [formControlName]="item.property.toString()"
                    *ngIf="item.type === 'check-mark'">
          <div (mouseleave)="matSel.close()">
            <mat-option *ngFor="let value of booleanValues"
                        [value]="value.value">
              {{value.label | transloco}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="item.filterSettings && item.filterSettings.type === 'select'">
        <mat-label>{{ item.label | transloco }}</mat-label>
        <mat-select [formControlName]="item.property.toString()">
          <mat-option *ngFor="let item of initArrayOptions(item)" [value]="item">
            {{item | transloco}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="item.filterSettings && item.filterSettings.type === 'number'">
        <mat-label>{{ item.label | transloco }}</mat-label>
        <input matInput type="number"
               placeholder="{{ item.label | transloco }}"
               [formControlName]="item.property.toString()">
      </mat-form-field>
      <mat-form-field *ngIf="item.filterSettings && item.filterSettings.type === 'multiple'"
                      appearance="outline">
        <mat-label>{{ item.label | transloco }}</mat-label>
        <mat-select #matSel [formControlName]="item.property.toString()"
                    [multiple]="true">
          <div (mouseleave)="matSel.close()">
            <mat-option *ngFor="let type of item.enum | keyvalue"
                        [value]="type.value">
              {{type.value.toString() | transloco}}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
      <div fxLayout.gt-xs="column"
           *ngIf="item.filterSettings && item.filterSettings.type === 'between'">
        <div class="date-property">
          <h3 class="date-title">{{ item.label | transloco }}</h3>
          <app-date-input [formControlName]="item.property.toString() + '_from'"
                          fxFlex
                          appearance="outline"
                          label="{{ 'from-date' | transloco }}">
          </app-date-input>
          <app-date-input [formControlName]="item.property.toString() + '_to'"
                          fxFlex
                          appearance="outline"
                          label="{{'to-date' | transloco}}">
          </app-date-input>
        </div>
      </div>
      <div fxLayout.gt-xs="column"
           *ngIf="item.filterSettings && item.filterSettings.type === 'chips'">
        <app-countries-chips [categories]='["countries", "territories", "protocols"]'
          [formControlName]="item.property.toString()"
          label="{{ item.label | transloco }}">
        </app-countries-chips>
      </div>
    </div>
    <div class="filter-relations"
         *ngIf="descriptors && descriptors.length">
      <!--<h2>Связи</h2>-->
      <ng-container
        *ngFor="let model of descriptors"
        class="filter-field">
        <app-links-chips
          [modelName]="model.key"
          [inFilter]="true"
          [label]="model.label"
          [placeholder]="model.label"
          [searcher]="model.searcher"
          [formControlName]="model.key"></app-links-chips>
      </ng-container>
    </div>

    <div class="filter-buttons">
      <button class="w-full"
              mat-raised-button
              [disabled]="form.pristine"
              color="primary">
        {{ 'apply' | transloco }}
      </button>
      <button mat-button
              class="reset-button"
              type="reset">
        {{'reset' | transloco}}
      </button>
    </div>
  </form>
</div>
