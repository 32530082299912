import { Reminder } from '../reminder/reminder.entity';
import { Chat } from '../chat/chat.entity';
import { Company } from '../company/company.entity';
import { Currency } from '../_shared/enums/currency.enum';
import { File } from '../file/file.entity';
import { OIP } from '../oip/oip.entity';
import { Task } from '../task/task.entity';
import { User } from '../user/user.entity';
import { ELanguage } from '../_shared/enums/language.enum';
import { PrecalculatedPriceResDto } from '../price/dto/precalculated-price-res.dto';
import { Price } from '../price/price.entity';

export type EmailConfirmationData = { emailConfirmationHash: string; lang: ELanguage; fromMarketplace: boolean };
export type NewRegistrationData = { user: User; company?: Company };
export type PasswordRecoveryData = { token: string };
export type TaskAcceptedData = { chat: Chat; task: Task };
export type TaskAcceptedManagerData = { task: Task };
export type ExpulseFromCompanyData = { companyName: string };
export type InviteUnregisteredUserData = { companyName: string; email: string; password: string };
export type InviteRegisteredUserData = { company: Company };
export type TaskConfirmedByCustomerData = { task: Task; invoiceOrUrl: File | string };
export type TaskConfirmedData = TaskAcceptedData;
export type AnotherProviderWasChosenData = { taskTitle: string; reason: string; customerCompanyName: string };
export type NewDocumentReceivedData = { company: Company; oip: OIP };
export type GotServiceRequestData = {
  providerCompanyName: string;
  providerCompanyId: number;
  task: Task;
  chatId: number;
};
export type TaskCompletedData = { task: Task; chat: Chat; resultFiles: File[]; again: boolean };
export type OfferWasDeclined = AnotherProviderWasChosenData;
export type TaskAutomaticallyAcceptedData = { task: Task; providerCompanyName: string };
export type TaskAutomaticallyAcceptedCustomerData = { task: Task; customerCompanyId: number };
export type NewTaskReceivedData = { task: Task; company: Company };
export type TaskUpdatedData = NewTaskReceivedData & { user: User };
export type PayoutData = { sum: number; providerCompanyName: string; taskTitle: string };
export type TaskSendToBeFixedData = { task: Task; chat: Chat; rejectedFiles: File[] };
export type TopUpCompanyBalanceData = { basePrice: number; baseCurrency: Currency; company: Company };
export type FixPublicProfileData = { messageToSend: string; companyId: number };
export type NewCompanyPublicInfoData = { companyName: string; companyId: number };
export type PersonalAssistanceData = { link: string };
export type TaskIsPaidData = TaskAcceptedData;
export type PublicProfileHasBeenPublishedData = InviteRegisteredUserData;
export type TaskWasDeletedByClientData = AnotherProviderWasChosenData;
export type NewFeedbackData = { taskTitle: string; user: User; feedbackText: string; companyId: number };
export type AgentAssignmentData = { oipName: string; oipId: number; companyId: number };
export type NewPayoutsInvoiceData = { invoiceName: string; invoiceUrl: string };

export type ServiceRequestRejectedData = {
  taskTitle: string;
  reason: string;
  customerCompanyId: number;
  providerCompanyName: string;
  taskId: number;
};

export type TaskExpiredData = { task: Task; isProvider: boolean };

export type NotifyCustomerData = {
  notLocalise: boolean;
  title: string;
  message: string;
  userProfileUrl: string;
  tasks: {
    buttonName?: string;
    attachments?: string;
    baseUrl: string;
    message: string;
  }[];
};

export type NotifyProviderData = {
  title: string;
  message: string;
  userProfileUrl: string;
  notLocalise: boolean;
  tasksToSuggestPrice: {
    buttonName: string;
    message: string;
    baseUrl: string;
  }[];
  tasksToComplete: {
    buttonName: string;
    message: string;
    baseUrl: string;
  }[];
  tasksToRevise: {
    buttonName: string;
    message: string;
    baseUrl: string;
  }[];
};

export type NewOffersData = {
  notLocalise: boolean;
  title: string;
  message: string;
  userProfileUrl: string;
  tasks: {
    buttonName: string;
    baseUrl: string;
    message: string;
  }[];
};

export type ChatDataType = {
  taskTitle: string;
  baseUrl: string;
  messages: {
    authorName: string;
    text?: string;
    attachments?: {
      fileName: string;
      fileUrl: string;
    }[];
  }[];
};

export type TaskRemindersData = NotifyCustomerData | NotifyProviderData | NewOffersData;
export type TaskAutomaticallyDeletedData = { task: Task };
export type UnreadMessagesData = { chats: ChatDataType[]; userProfileUrl: string; notLocalise: boolean };
export type ReminderDeadlineData = { reminder: Reminder };
export type OnboardingData = { companyName: string };
export type CompletePublicProfileData = { providerCompanyName: string; baseUrl: string };
export type PublicProfileHasBeenDeactivatedData = CompletePublicProfileData;
export type TaskAutoDeletedProvidersData = Omit<AnotherProviderWasChosenData, 'customerCompanyName'>;
export type PriceUpdatedManagerData = { providerCompanyId: number; providerCompanyName: string };
export type VerificationCodeData = { code: string };
export type NewPasswordData = { password: string };
export type InviteReferralData = { email: string; fullUserName: string; companyName: string; affiliateCode: string };

export type IpRightTrackingData = {
  agentCompanyName: string;
  oipName: string;
  oipId: number;
  customerCompanyId: number;
};

export type NewAutoOfferData = {
  providerCompanyName: string;
  providerCompanyId: number;
  task: Task;
  price?: Price;
  precalculatedPrice?: PrecalculatedPriceResDto;
};

export type OfferHasBeenResetData = {
  providerCompanyName: string;
  providerCompanyId: number;
  task: Task;
};

export type OipsHaveBeenUpdatedData = {
  oips: OIP[];
};

export type TrademarkSearchSubscriptionHasBeenPaidData = {
  companyName: string;
  stripeCustomerId: string;
};

export type TrademarkWatchReportData = { email: string; reportUrl: string; mark: string };

export type PaymentSucceededData = {
  taskTitle: string;
  customerCompanyName: string;
  customerCompanyId: number;
  chatId: number;
  taskId: number;
};

export type InviteeRewardData = { inviteeReward: number };

export type ForAllUsersData = {
  title: string;
  message: string;
  subject?: string;
  buttonName?: string;
  baseUrl?: string;
};

export type TaskDescriptionUpdatedData = {
  taskTitle: string;
  taskDescription: string;
  taskId: number;
  providerCompanyId: number;
};

export type PayoutErrorData = {
  taskId: number;
  taskTitle: string;
  providerCompanyName: string;
  errorType: 'no_transfer_error' | 'payout_error';
};

export type TransferErrorData = {
  taskId: number;
  taskTitle: string;
  providerCompanyName: string;
};

export type SendGridTemplateData = {
  [key: string]: string | number | boolean | [string] | [string, SendGridTemplateData] | SendGridTemplateData[];
};

export type SendGridTemplateDataToLocalise = {
  [key: string]: string | number | boolean | [string] | [string, SendGridTemplateDataToLocalise];
};

export type NotifierData =
  | EmailConfirmationData
  | NewRegistrationData
  | PasswordRecoveryData
  | TaskAcceptedData
  | TaskAcceptedManagerData
  | ExpulseFromCompanyData
  | InviteUnregisteredUserData
  | InviteRegisteredUserData
  | TaskConfirmedByCustomerData
  | TaskConfirmedData
  | AnotherProviderWasChosenData
  | NewDocumentReceivedData
  | GotServiceRequestData
  | TaskCompletedData
  | OfferWasDeclined
  | TaskAutomaticallyAcceptedData
  | TaskAutomaticallyAcceptedCustomerData
  | NewTaskReceivedData
  | TaskUpdatedData
  | PayoutData
  | TaskSendToBeFixedData
  | TopUpCompanyBalanceData
  | FixPublicProfileData
  | NewCompanyPublicInfoData
  | PersonalAssistanceData
  | TaskIsPaidData
  | PublicProfileHasBeenPublishedData
  | TaskWasDeletedByClientData
  | ServiceRequestRejectedData
  | TaskExpiredData
  | TaskRemindersData
  | TaskAutomaticallyDeletedData
  | UnreadMessagesData
  | ReminderDeadlineData
  | TaskAutoDeletedProvidersData
  | NewFeedbackData
  | AgentAssignmentData
  | NewPayoutsInvoiceData
  | IpRightTrackingData
  | PriceUpdatedManagerData
  | VerificationCodeData
  | NewPasswordData
  | InviteReferralData
  | NewAutoOfferData
  | OfferHasBeenResetData
  | OipsHaveBeenUpdatedData
  | TrademarkWatchReportData
  | TrademarkSearchSubscriptionHasBeenPaidData
  | PaymentSucceededData
  | InviteeRewardData
  | ForAllUsersData
  | TaskDescriptionUpdatedData
  | PayoutErrorData
  | TransferErrorData
  | void;
