import { Component, Input } from '@angular/core';
import { ICompanyEntity } from '@ipnote/interface';
import { isNull } from 'lodash';

@Component({
  selector: 'app-provider-completed-tasks',
  templateUrl: './provider-completed-tasks.component.html',
  styleUrls: ['./provider-completed-tasks.component.scss'],
})
export class ProviderCompletedTasksComponent {
  @Input() showToolTip = true;
  @Input() provider: ICompanyEntity;
  protected readonly isNull = isNull;
}
