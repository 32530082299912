<button
  mat-raised-button
  color="primary"
  class="w-full"
  [routerLink]="['../', task.id, 'info', task.stage === 'draft' ? 'general' : 'provider']"
  *ngIf="task.stage === enums.taskStages.DRAFT && !task?.incoming && !task.needHelpInvoice"
>
  {{ 'complete_the_task' | transloco }}
</button>

<a
  mat-raised-button
  color="primary"
  [href]="task.needHelpInvoice.url"
  target="_blank"
  class="w-full"
  *ngIf="
    task.stage === enums.taskStages.DRAFT &&
    !task?.incoming &&
    task.needHelpInvoice?.url &&
    task.needHelpInvoice?.paymentDate === null
  "
>
  {{ 'Pay invoice' | transloco }}
</a>

<div
  class="w-full flex items-center flex-nowrap gap-2"
  *ngIf="task.stage === 'confirm' && !task?.incoming && serviceRequest?.offer"
>
  <!--  <mat-icon (click)="rejectServiceRequest(serviceRequest)" style="color: #8997B1" [matTooltipPosition]="'above'"-->
  <!--            [matTooltip]="'Click to reject this offer'"-->
  <!--            class="mr-2 w-6 h-6 text-2xl cursor-pointer ">delete-->
  <!--  </mat-icon>-->
  <button
    class="flex-grow shrink-0"
    (click)="rejectServiceRequest(serviceRequest)"
    mat-stroked-button
    color="primary"
    type="button"
  >
    reject
  </button>
  <button
    mat-flat-button
    color="primary"
    [matTooltipPosition]="'above'"
    class="flex-grow shrink-0"
    [matTooltip]="'Click to confirm this offer and proceed with payment'"
    (click)="acceptServiceRequest(serviceRequest)"
  >
    <!--    {{ 'accept-request' | transloco }}-->
    {{ 'confirm_and_pay' | transloco }}
  </button>
</div>

<button
  mat-raised-button
  color="primary"
  class="w-full"
  *ngIf="task.stage === 'confirm' && task.serviceRequests?.length && task?.incoming && !serviceRequest.offer?.price"
  (click)="offerPrice()"
>
  {{ 'offer-price' | transloco }}
</button>

<button
  mat-raised-button
  color="primary"
  class="w-full"
  *ngIf="
    task.stage === 'confirm' &&
    task?.incoming &&
    serviceRequest?.offer &&
    (serviceRequest?.offer?.price || serviceRequest?.offer?.price === 0) &&
    (serviceRequest?.offer?.days || serviceRequest?.offer?.days === 0)
  "
  (click)="offerPrice()"
>
  {{ 'update_offer' | transloco }}
</button>

<!--  <app-paid-notification *ngIf="task.stage === 'running' && task?.incoming" (conversation)="showConversation(sr)"-->
<!--                         (close)="closeTask(sr)" [taskButton]="true" (innerWork)="innerWork($event)" [task]="task">-->
<!--  </app-paid-notification>-->

<button
  class="w-full"
  *ngIf="task.stage === 'running' && task?.incoming"
  mat-raised-button
  color="primary"
  (click)="initiateWork()"
>
  {{ 'button_initiate_work' | transloco }}
</button>

<button
  class="w-full"
  *ngIf="task.stage === enums.taskStages.IN_PROGRESS && task?.incoming"
  mat-raised-button
  color="primary"
  (click)="completeWork()"
>
  {{ 'complete-work' | transloco }}
</button>

<button
  class="w-full"
  mat-flat-button
  color="accent"
  *ngIf="task.stage === enums.taskStages.REOPEN && task?.incoming"
  (click)="completeWork()"
>
  {{ 'send-again' | transloco }}
</button>

<div
  *ngIf="task.stage !== enums.taskStages.REOPEN && !task.incoming && task.stage === enums.taskStages.CHECK"
  class="w-full flex items-center flex-nowrap gap-2"
>
  <button class="flex-grow shrink-0" (click)="reopen()" mat-stroked-button color="primary" type="button">
    <!--    <mat-icon (click)="reopen()" [matTooltipPosition]="'above'"-->
    <!--              [matTooltip]="'Click to request revision'"-->
    <!--              class="mr-2 w-6 h-6 text-2xl">restart_alt-->
    <!--    </mat-icon>-->
    Request revision
  </button>
  <button mat-raised-button color="primary" class="flex-grow shrink-0" (click)="acceptWork()">ACCEPT WORK</button>
</div>

<button
  class="w-full"
  color="primary"
  *ngIf="PayInvoiceButton"
  mat-raised-button
  [ngClass]="{ 'mat-accent': getRefillTransaction().stage !== enums.transactionStages.COMPLETED }"
  (click)="handleInvoice(task, $event)"
>
  {{ getRefillTransaction().stage === enums.transactionStages.COMPLETED ? 'Download receipt' : 'Pay invoice' }}
</button>

<button class="w-full" *ngIf="leaveFeedBackButton" mat-raised-button color="primary" (click)="leaveFeedback()">
  leave feedback
</button>
