import { Pipe, PipeTransform } from '@angular/core';
import { filter, sortBy } from 'lodash';

@Pipe({
  name: 'relations'
})
export class RelationsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    value = filter(value, (item) => {
      return item.fromModel !== 'Company' && item.toModel !== 'Company' && item.link !== 'Company' && item.toModel !== 'Transaction';
    });

    value = sortBy(value, (item) => {
      if (item.fromModel === 'Task') {
        item.link = item.toModel;
        item.company = item.to.company;
        item.id = item.to.id;
        return item.toModel;
      }
      item.link = item.fromModel;
      item.company = item.from.company;
      item.id = item.from.id;
      return item.fromModel;
    });
    return value;
  }
}
