import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Value } from 'app/dataset/Value';

@Component({
  selector: 'app-payer-chips',
  templateUrl: './payer-chips.component.html',
  styleUrls: ['./payer-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PayerChipsComponent),
      multi: true
    }
  ]
})
export class PayerChipsComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  private onChange: (value: any) => void;
  private onTouched: () => void;
  visible = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formControl = new UntypedFormControl();
  filtered: Observable<Value[]>;
  selectedPayers: Value[] = [];

  @ViewChild('payersInput') payersInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @Input() label: string;
  @Input() payers: Value[];

  constructor() {
    this.filtered = this.formControl.valueChanges.pipe(
      // tslint:disable-next-line:deprecation
      startWith(null),
      map((item: string | null | Value) => {
        if (item) {
          if (typeof item === 'string') {
            return this._filter(item);
          } else {
            return this._filter(item.label);
          }
        }
        return this.payers.slice();
      })
    );
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;

      if (input) {
        input.value = '';
      }
    }
  }

  remove(payer: Value): void {
    const index = this.selectedPayers.indexOf(payer);
    if (index >= 0) {
      this.selectedPayers.splice(index, 1);
      if (this.onChange) {
        this.onChange(this.selectedPayers);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedPayers.push(event.option.value);
    this.payersInput.nativeElement.value = '';
    this.formControl.setValue(null);
    if (this.onChange) {
      this.onChange(this.selectedPayers);
    }
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    return this.payers.filter((c) => c.label.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.payers && this.payers.length > 1) {
        this.selectedPayers.push(this.payers[0]);
        if (this.onChange) {
          this.onChange(this.selectedPayers);
        }
      }
    }, 0);
  }

  writeValue(obj: any): void {
    if (Array.isArray(obj)) {
      this.payers = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  alreadyIn(item: Value): boolean {
    return !!this.selectedPayers.find((p) => p.value === item.value);
  }
}
