import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ENTER } from '@angular/cdk/keycodes';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { TranslocoService } from '@ngneat/transloco';
import { CountriesSortService } from '../../../../@vex/services/countries-sort.service';
import { intersection } from 'lodash';

@Component({
  selector: 'app-countries-chips',
  templateUrl: './countries-chips.component.html',
  styleUrls: ['./countries-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountriesChipsComponent),
      multi: true
    }
  ]
})
export class CountriesChipsComponent implements OnInit, ControlValueAccessor {
  private onChange: (value: any) => void;
  private onTouched: () => void;
  visible = true;
  disabled = false;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER];
  formControl = new UntypedFormControl();
  filtered: Observable<string[]>;
  selectedCountries: string[] = [];
  countries: string[] = [];
  allItems = {
    countries: Object.values(this.enums.countries),
    protocols: Object.values(this.enums.protocols),
    territories: Object.values(this.enums.territories)
  };

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @Input() label: string;
  @Input() requireState = false;
  @Input() categories: string[];

  constructor(private enums: EnumsService, private transloco: TranslocoService, public countriesSortService: CountriesSortService) {
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      if (this.countries.findIndex((c) => c === value) !== -1) {
        if ((value || '').trim()) {
          this.selectedCountries.push(value.trim());
          if (this.onChange) {
            this.onChange(this.selectedCountries);
          }
        }
      }
      if (input) {
        input.value = '';
      }

      this.formControl.setValue(null);
    }
  }

  remove(country: string): void {
    const index = this.selectedCountries.indexOf(country);
    if (index >= 0) {
      this.selectedCountries.splice(index, 1);
      if (this.onChange) {
        this.onChange(this.selectedCountries);
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.selectedCountries.includes(event.option.value)) {
      this.selectedCountries.push(event.option.value);
    }

    this.countryInput.nativeElement.value = '';
    this.formControl.setValue(null);
    if (this.onChange) {
      this.onChange(this.selectedCountries);
    }
  }

  ngOnInit(): void {
    if (this.categories && this.categories.length)
      this.categories.forEach((category) => (this.countries = this.countries.concat(this.allItems[category])));
    else this.countries = this.allItems.countries;
    this.countries = this.countriesSortService.sort(this.countries);
    this.filtered = this.formControl.valueChanges.pipe(
      startWith(null),
      map((token) =>
        token ? this.countries.filter((item) => this.transloco.translate(item).toLowerCase().includes(token.toLowerCase())) : this.countries
      )
    );
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.selectedCountries = [];
    } else if (Array.isArray(obj)) {
      this.selectedCountries = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formControl.disable();
      this.disabled = disabled;
    } else {
      this.formControl.enable();
      this.disabled = disabled;
    }
  }

  getCategoryCountries(category, countries) {
    return intersection(this.allItems[category], countries);
  }
}
