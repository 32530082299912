<header class="header"
        [ngClass]="{ 'fixed': headerFixed}">
  <div class="container">
    <div class="header__inner animate">
      <div class="header__logo">
        <div class="logo">
          <a class="logo__link animate" href="https://ipnote.pro/">
            <img class="logo__image" src="https://ipnote.pro/wp-content/themes/ipnote/assets/images/logo.svg"
                 width="150" height="42" loading="lazy">
          </a>
        </div>
      </div>
      <div class="header__menu animate" [ngClass]="animatedMenuOpened? 'opened': ''">
        <div class="header__navigation navigation">
          <nav class="navigation">
            <ul class="navigation__list">
              <li class="navigation__item"><a class="navigation__link animate" href="https://ipnote.pro/">Home</a></li>
              <li class="navigation__item dropdown">
                <a style="pointer-events: none" class="navigation__link animate">Products</a>
                <ul class="submenu__list dropdown-menu">
                  <li class="submenu__link"><a href="/ai">AI Assistant</a></li>
                  <li class="submenu__link"><a href="https://www.ipnote.app">AI Trademark Search</a></li>
                  <li class="submenu__link"><a href="https://www.ipnote.ai">AI Patent Search</a></li>
                  <li class="submenu__link"><a href="/public-page/company-public-info">Attorney’s Marketplace</a></li>
                  <li class="submenu__link"><a href="https://ipnote.pro/for-tech-companies/">For Tech Companies</a></li>
                  <li class="submenu__link"><a href="https://ipnote.softr.app/">For Attorneys</a></li>
                  <li class="submenu__link"><a href="https://ipnote-wl.softr.app/">IPMS for Law Firms</a></li>
                </ul>
              </li>
              <li class="navigation__item dropdown">
                <a class="navigation__link animate" href="https://ipnote.pro/services">Services</a>
                <ul class="submenu__list dropdown-menu">
                  <li class="submenu__link"><a href="https://ipnote.pro/services/trademark-registration/">Trademark Registration</a></li>
                  <li class="submenu__link"><a href="https://ipnote.pro/services/patent-registration/">Patent Registration</a></li>
                  <li class="submenu__link"><a href="https://ipnote.pro/services/design-registration/">Design Registration</a></li>
                </ul>
              </li>
              <li class="navigation__item"><a class="navigation__link animate"
                                              href="https://ipnote.pro/en/blog/">Blog</a></li>
              <li class="navigation__item"><a class="navigation__link animate" target="_blank" href="https://ipnote.pro/en/blog/category/help-center/">Help</a></li>
            </ul>
          </nav>
        </div>
        <div class="flex items-center flex-shrink-0" *ngIf="userState?.isAuthenticated; else elseBlock">
          <div class="company-selector flex items-center mr-4 whitespace-nowrap">
            <div class="company-selector__name mr-2 font-semibold" *ngIf="selectedCompany?.id"
                 [matTooltip]="selectedCompany?.name">
              {{ selectedCompany.name }}
            </div>
            <button class="flex items-center" (click)="changeCompany(selectedCompany)" mat-icon-button
                    matTooltip="{{ 'switch-current-company' | transloco }}"
                    type="button">
              <mat-icon>swap_vert</mat-icon>
            </button>
          </div>
          <a *ngIf="userState?.isAuthenticated" class="animate mr-4"
             routerLink="/" target="_blank">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.75 21.25L17 16L11.75 10.75" stroke="#2B5DA2" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path d="M2.99634 16H16.9963" stroke="#2B5DA2" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
              <path
                d="M15 5H24C24.2652 5 24.5196 5.10536 24.7071 5.29289C24.8946 5.48043 25 5.73478 25 6V26C25 26.2652 24.8946 26.5196 24.7071 26.7071C24.5196 26.8946 24.2652 27 24 27H15"
                stroke="#2B5DA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
          </a>
          <app-user-button [marketPlace]=true [companyId]="selectedCompany?.id"></app-user-button>
        </div>
        <ng-template #elseBlock>
          <div class="header__actions">
            <a class="header__sign-in animate" routerLink="/">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.75 21.25L17 16L11.75 10.75" stroke="#2B5DA2" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                <path d="M2.99634 16H16.9963" stroke="#2B5DA2" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"></path>
                <path
                  d="M15 5H24C24.2652 5 24.5196 5.10536 24.7071 5.29289C24.8946 5.48043 25 5.73478 25 6V26C25 26.2652 24.8946 26.5196 24.7071 26.7071C24.5196 26.8946 24.2652 27 24 27H15"
                  stroke="#2B5DA2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
            </a>
            <a class="btn animate sign-up" (click)="openSideNav(steps.PERSONAL_FIELD)" target="_blank">Sign up for
              free</a>
          </div>
        </ng-template>
      </div>
      <button (click)="openAnimatedMenu()" class="header__burger-button burger-button" aria-label="Main Menu"
              [ngClass]="animatedMenuOpened? 'opened': ''">
        <svg width="100" height="100" viewBox="0 0 100 100">
          <path class="burger-button__line burger-button__line--1"
                d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"></path>
          <path class="burger-button__line burger-button__line--2" d="M 20,50 H 80"></path>
          <path class="burger-button__line burger-button__line--3"
                d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"></path>
        </svg>
      </button>
    </div>
  </div>
</header>
