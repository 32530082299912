import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CollaboratorsService } from 'app/page-modules/desk/services/collaborators/collaborators.service';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { ICompanyEntity, IUserEntity } from '@ipnote/interface';
import { Observable } from 'rxjs';
import { selectStateSelectedCompany } from '#selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../../../services/dialogs/dialog.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { differenceBy } from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-collaborators-list',
  templateUrl: './collaborators-list.component.html',
  styleUrls: ['./collaborators-list.component.scss']
})
export class CollaboratorsListComponent implements OnInit {
  dataLoading = true;
  collaborators: IUserEntity[];
  searchControl: UntypedFormControl = new UntypedFormControl();
  selectedCompany: ICompanyEntity;
  selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUserEntity[],
    private collaboratorsService: CollaboratorsService,
    private store: Store<AppState>, private dialogs: DialogService,
    private dialogRef: MatDialogRef<CollaboratorsListComponent>) {
  }

  ngOnInit(): void {
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe(company => this.selectedCompany = company);
    this.collaboratorsService.get(this.selectedCompany.id).pipe(untilDestroyed(this)).subscribe(
      result => {
        this.collaborators = [...result.collaborators, ...result.readers];
        this.collaborators.push(result.owner);
        this.collaborators = differenceBy(this.collaborators, this.data, 'id');
        this.dataLoading = false;
      },
      error => {
        this.dialogs.error(error);
        this.dataLoading = false;
      });
  }

  close(): void {
    this.dialogRef.close(null);
  }

  select(collaborator: IUserEntity) {
    this.dialogRef.close(collaborator);
  }
}
