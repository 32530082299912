import { signInReducerDefault, StateSignIn } from './user-sign-in.reducers';
import { signUpReducerDefault, StateSignUp } from './user-sign-up.reducers';
import { forgotPasswordReducerDefault, StateForgotPassword, StateServerRes } from './user-forgot-passowrd.reducers';
import { confirmEmailReducerDefault, StateConfirmEmail } from './user-confirm-email.reducers';
import { StateUser, userReducerDefault } from './user.reducers';
import { StateUserProfile, userProfileReducerDefault } from './user-profile.reducers';

/***
 * State user
 */
export {
  StateUser,
  userReducerDefault
};

/***
 * State user signIn
 */
export {
  StateSignIn,
  signInReducerDefault
};

/***
 * State user signUp
 */
export {
  StateSignUp,
  signUpReducerDefault
};

/***
 * State user confirm email
 */
export {
  StateConfirmEmail,
  confirmEmailReducerDefault
};

/***
 * State user forgot password
 */
export {
  StateServerRes,
  StateForgotPassword,
  forgotPasswordReducerDefault
};

/***
 * State user profile
 */
export {
  StateUserProfile,
  userProfileReducerDefault
};
