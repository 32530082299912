<div class="files__top">
  <app-files-search (tokenChange)="filesSearch($event)"></app-files-search>
  <app-files-order
    [ordersSelect]="fileOrder"
    (changeOrder)="changeSortFiles($event)"
  ></app-files-order>
</div>

<div class="files__tags-filter">

</div>

<small *ngIf="readOnly" class="muted">{{'readers' | transloco}}</small>

<div class="files__upload">
  <ng-container *ngIf="privateFiles?.group">
    <app-upload-to-folder
      [privateFiles]="privateFiles"
      (newFile)="handleFile($event)"
    ></app-upload-to-folder>
  </ng-container>
  <ng-container *ngIf="!privateFiles?.group">
    <app-upload
      *ngIf="!readOnly"
      (newFile)="handleFile($event)"
      [privateFiles]="privateFiles"
      [fileTypes]="fileTypes"></app-upload>
  </ng-container>
</div>

<div class="files__list">
  <div *ngFor="let f of ($filesTpl | async); let i = index"
       (click)="showFile(f)"
       [ngClass]="{'files__list__item__folder': (f | isFolderPipe)}"
       class="flex-col">
    <div class="files__list__item  flex">
      <span class="files__list__item__icon">
        <ng-container *ngIf="f | isFolderPipe">
          <mat-icon
            class="file-icon text-gray-400"
            [inline]="true"
            [icIcon]="icFolder">
          </mat-icon>
        </ng-container>
        <ng-container *ngIf="!(f | isFolderPipe)">
          <mat-icon
            class="file-icon text-gray-400"
            [inline]="true"
            [icIcon]="icFile">
          </mat-icon>
        </ng-container>
    </span>
      <span class="files__list__item__date">
      {{f.createdAt | date: dateFormatService.getFormat() + ' HH:mm' : null : dateFormatService.getLocale()}}
    </span>
      <span class="files__list__item__name truncate">
      {{f.name}}
    </span>
      <ng-container *ngIf="_fileTypes">
        <div class="files__list__item__tags overflow-hidden">
          <app-file-tags [fileId]="f.id" [fileTags]="f.tags" [isFolder]="f | isFolderPipe"
                         [readOnly]="readOnly"></app-file-tags>
        </div>
      </ng-container>
      <ng-container *ngIf="f.comment">
        <div class="files__list__item__comment"
             (click)="showComment($event, f.comment)">
          <span>{{getFileComment(f.comment)}}</span>
          <ic-icon *ngIf="!readOnly" [icon]="icResize" size="16px"></ic-icon>
        </div>
      </ng-container>
      <mat-spinner class="ml-4" *ngIf="fileLoading(f) && !(f | isFolderPipe)" [diameter]="20"></mat-spinner>
      <div class="files__list__item__btn">
        <button *ngIf="!readOnly"
                mat-icon-button
                (click)="$event.stopPropagation()"
                [matMenuTriggerData]="{ file: f, isFolder: f | isFolderPipe }"
                [matMenuTriggerFor]="fileActionsMenu"
                matTooltip="{{ 'file-actions' | transloco }}">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
    <ng-container *ngIf="f.files">
      <div class="files__list files__list__sub">
        <div *ngFor="let subF of f.files"
             (click)="showFile(subF)"
             class="files__list__item">
          <span class="files__list__item__icon">
                <mat-icon
                  class="file-icon text-gray-400"
                  [inline]="true"
                  [icIcon]="icFile">
                </mat-icon>
          </span>
          <span class="files__list__item__date">
            {{subF.createdAt | date: dateFormatService.getFormat() + ' HH:mm' : null : dateFormatService.getLocale()}}
          </span>
          <span class="files__list__item__name truncate">
            {{subF.name}}
          </span>
          <mat-spinner class="ml-4" *ngIf="fileLoading(subF)" [diameter]="20"></mat-spinner>
          <div class="files__list__item__btn">
            <button *ngIf="!readOnly"
                    mat-icon-button
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerData]="{ file: subF, isFolder: false, folderId: f.id }"
                    [matMenuTriggerFor]="fileActionsMenu"
                    matTooltip="{{ 'file-actions' | transloco }}">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<mat-menu #fileActionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-file="file" let-isFolder="isFolder" let-folderId="folderId" matMenuContent>
    <button (click)="renameFile(file, isFolder)" mat-menu-item [disabled]="readOnly">
      <mat-icon [icIcon]="icEdit"></mat-icon>
      <span>{{ 'rename' | transloco }}</span>
    </button>
    <button (click)="removeFile(file, isFolder, folderId)" mat-menu-item [disabled]="readOnly">
      <mat-icon [icIcon]="icDelete"></mat-icon>
      <span>{{ 'delete' | transloco }}</span>
    </button>
    <button *ngIf="!isFolder" (click)="downloadFile(file)" mat-menu-item [disabled]="readOnly">
      <mat-icon [icIcon]="icDelete"></mat-icon>
      <span>{{ 'Download' | transloco }}</span>
    </button>

  </ng-template>
</mat-menu>

<input type="file"
       #folder_file
       (change)="processFileToFolder($event)"
       style="display: none"
       multiple>
