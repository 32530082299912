import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { parser } from 'html-metadata-parser';

const API = 'https://ogp-api.appspot.com/?url=';

export interface Ogp {
  meta: Meta;
  og: Og;
  images: Image[];
}

export interface Meta {
  title: string;
  url: string;
  description: string;
}

export interface Og {
  type: string;
  title: string;
  description: string;
  url: string;
  site_name: string;
  image: string;
}

export interface Image {
  src: string;
}

@Component({
  selector: 'app-opg-site-preview',
  templateUrl: './opg-site-preview.component.html',
  styleUrls: ['./opg-site-preview.component.scss']
})
export class OpgSitePreviewComponent implements OnInit {
  isLoading = true;
  @Input() url;
  ogp: Ogp;

  constructor(private http: HttpClient) {

  }

  ngOnInit(): void {
    console.log(this.url)
    parser(this.url).then((result: any) => {
      this.ogp = result as Ogp;
      console.log(result)
      this.isLoading = false;
    });
  }

  openLink() {
    window.open(this.url, '_blank');
  }
}
