import { FileManagerService } from '../../../../../app-common/services/file-manager/file-manager.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { INormalizedIpRight, IOIPEntity, ITaskEntity } from '@ipnote/interface';
import { EnumsService } from '../../../services/enums/enums.service';
import { filter } from 'rxjs/operators';
import { FolderTypes, InputFolderTypes, OutputFolderTypes } from '@ipnote/type';
import { AnalyticsEventEnum } from '@ipnote/enum';
import { AnalyticsService } from '../../../../../app-common/services/analytics/analytics.service';
import { TasksService } from '../../../services/tasks/tasks.service';
import { lastValueFrom } from 'rxjs';
import { DialogService } from '../../../../../app-common/services/dialogs/dialog.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-complete-work',
  templateUrl: './complete-work.component.html',
  styleUrls: ['./complete-work.component.scss'],
})
export class CompleteWorkComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;
  public files: File[] = [];
  gettingATask = true;
  task: ITaskEntity;
  loading = false;
  formSaveInProcess = false;
  type = FolderTypes;
  subType: typeof InputFolderTypes | typeof OutputFolderTypes = InputFolderTypes;
  tagsInstalled = false;

  oip: IOIPEntity;
  /** *
   * https://ipnote.atlassian.net/browse/IPNOTE-3469
   * To display additional fields with the date and number that will fly into the OIS
   * */
  thisIsAnApplication = false;

  showAddComment = true;

  form = new FormGroup({
    description: new FormControl(null),
    type: new FormControl(null),
    subType: new FormControl(null),
    comment: new FormControl(''),
    folderFileName: new FormControl({ value: 'New folder', disabled: true }),
    oipName: new FormControl(null),
    addFileToOIP: new FormControl(false),
    requestNumber: new FormControl(null),
    requestDate: new FormControl(null),
    priorityNumber: new FormControl(null),
    priorityDate: new FormControl(null),
  });

  constructor(
    public enums: EnumsService,
    @Inject(MAT_DIALOG_DATA) public data: { task: ITaskEntity },
    private uploadService: FileManagerService,
    private dialogRef: MatDialogRef<CompleteWorkComponent>,
    private tasksService: TasksService,
    private analyticsService: AnalyticsService,
    private dialogs: DialogService,
    private translocoService: TranslocoService,
  ) {
    this.form.controls.type.valueChanges.pipe(filter((r) => this.form.controls.type.valid)).subscribe((res) => {
      this.form.controls.subType.reset();
      this.subType = res === FolderTypes.INPUT ? InputFolderTypes : OutputFolderTypes;
    });
    this.form.controls.requestDate.valueChanges.pipe().subscribe((result) => {
      this.form.controls.priorityDate.setValue(result);
    });
  }

  async ngOnInit() {
    this.form.controls.addFileToOIP.valueChanges.subscribe((val) => {
      if (val === false) {
        this.disableAdditionOIPFields();
      } else {
        this.tagsInstalled = this.setDefaultTags();
      }
    });

    this.form.controls.subType.valueChanges.subscribe((subValue) => {
      if (subValue === InputFolderTypes.ADMISSION_NOTICE && this.oip) {
        this.enableAdditionOipFields(this.oip);
      } else {
        this.disableAdditionOIPFields();
      }
    });

    this.task = await lastValueFrom(this.tasksService.get(this.data.task.id));
    if (this.task?.oips?.length > 0) {
      this.oip = this.task?.oips?.[0];
      this.form.controls.addFileToOIP.setValue(true);
    }
    this.gettingATask = false;

    this.form.markAllAsTouched();
  }
  private enableAdditionOipFields(oip: IOIPEntity) {
    this.thisIsAnApplication = true;

    this.form.controls.requestNumber.addValidators(Validators.required);
    this.form.controls.requestDate.addValidators(Validators.required);

    this.form.controls.oipName.enable();
    this.form.controls.oipName.addValidators(Validators.required);

    this.form.controls.requestNumber.enable();
    this.form.controls.requestDate.enable();
    this.form.controls.priorityNumber.enable();
    this.form.controls.priorityDate.enable();

    this.form.controls.oipName.setValue(oip.name);
    this.form.controls.requestNumber.setValue(oip.requestNumber);
    this.form.controls.requestDate.setValue(oip.requestDate);
    this.form.controls.priorityNumber.setValue(oip.priorityNumber);
    this.form.controls.priorityDate.setValue(oip.priorityDate);
  }
  disableAdditionOIPFields() {
    this.thisIsAnApplication = false;

    this.form.controls.oipName.disable();
    this.form.controls.requestNumber.disable();
    this.form.controls.requestDate.disable();
    this.form.controls.priorityNumber.disable();
    this.form.controls.priorityDate.disable();
  }
  setDefaultTags(): boolean {
    switch (this.task.service.name) {
      // Filing an application -> Filing receipt (incoming)
      case 'filing_docketing_application': {
        this.form.controls.type.setValue(FolderTypes.INPUT);
        this.form.controls.type.disable();
        this.form.controls.subType.setValue(InputFolderTypes.ADMISSION_NOTICE);
        this.form.controls.subType.disable();
        return true;
      }
      // Request of examination -> Examination request (outgoing)
      case 'filing_examination_request': {
        this.form.controls.type.setValue(FolderTypes.OUTPUT);
        this.form.controls.type.disable();
        this.form.controls.subType.setValue(OutputFolderTypes.REQUEST_OF_EXAMINATION);
        this.form.controls.subType.disable();
        return true;
      }
      // Responding to office action -> Response to office action (outgoing)
      case 'filing_response_OA': {
        this.form.controls.type.setValue(FolderTypes.OUTPUT);
        this.form.controls.type.disable();
        this.form.controls.subType.setValue(OutputFolderTypes.SUBSTANTIVE_REQUEST_RESPONSE);
        this.form.controls.subType.disable();
        return true;
      }
      // Granting -> Issue fee receipt (outgoing)
      case 'granting': {
        this.form.controls.type.setValue(FolderTypes.OUTPUT);
        this.form.controls.type.disable();
        this.form.controls.subType.setValue(OutputFolderTypes.ISSUANCE_FEE);
        this.form.controls.subType.disable();
        return true;
      }
      // Maintenance/renewal -> Maintenance/renewal fee receipt (outgoing)
      case 'right_document_renewal': {
        this.form.controls.type.setValue(FolderTypes.OUTPUT);
        this.form.controls.type.disable();
        this.form.controls.subType.setValue(OutputFolderTypes.DUTY_PAYMENT);
        this.form.controls.subType.disable();
        return true;
      }
      default: {
        return false;
      }
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  async send(): Promise<any> {
    this.loading = true;
    this.formSaveInProcess = true;

    const {
      description,
      type,
      subType,
      comment,
      addFileToOIP,
      folderFileName,
      oipName,
      requestNumber,
      requestDate,
      priorityNumber,
      priorityDate,
    } = this.form.getRawValue();
    const oipId = addFileToOIP ? this.task.oips[0].id : null;
    this.form.disable();
    const res: ITaskEntity = await this.tasksService
      .done(
        this.task.id,
        [type, subType],
        description,
        this.files,
        folderFileName,
        comment,
        oipId,
        oipName,
        requestNumber,
        requestDate,
        priorityNumber,
        priorityDate,
      )
      .toPromise();
    this.form.reset();
    this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_COMPLETED, { prices: res.prices });
    this.loading = false;
    this.dialogRef.close(res);
  }

  addFile($event: Event): void {
    // tslint:disable-next-line:deprecation
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    this.files.push(...Array.from(target.files));
    this.fileLenghtControl();
  }

  fileLenghtControl() {
    if (this.files.length > 1 && this.task.oips.length) {
      this.form.controls.folderFileName.enable();
      this.form.controls.folderFileName.addValidators(Validators.required);
    } else {
      this.form.controls.folderFileName.clearValidators();
      this.form.controls.folderFileName.disable();
    }
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  selectFile(): void {
    this.fileInput.nativeElement.click();
  }

  closeCommentForm(): void {
    this.form.controls.comment.reset('');
    this.showAddComment = false;
  }
  onSelected($event: { data: INormalizedIpRight; index: number }) {
    const { name, requestNumber, requestDate, priorityNumber, priorityDate } = $event.data;

    this.form.controls.oipName.setValue(name);
    this.form.controls.requestNumber.setValue(requestNumber);
    this.form.controls.requestDate.setValue(requestDate);
    this.form.controls.priorityNumber.setValue(priorityNumber);
    this.form.controls.priorityDate.setValue(priorityDate);
  }
}
