<vex-config-panel-toggle (openConfig)="openConfigPanel()"></vex-config-panel-toggle>

<!-- CONFIGPANEL -->
<vex-sidebar #configpanel [invisibleBackdrop]="true" position="right">
  <vex-config-panel></vex-config-panel>
</vex-sidebar>
<!-- END CONFIGPANEL -->

<div [class.boxed]="isBoxed$ | async"
     [class.horizontal-layout]="(isLayoutVertical$ | async) === false"
     [class.is-mobile]="(desktopQuery$ | async) === null"
     [class.vertical-layout]="isLayoutVertical$ | async"
     [class.has-fixed-footer]="(isFooterFixed$ | async) && isFooterVisible$ | async"
     [class.has-footer]="isFooterVisible$ | async"
     [class.scroll-disabled]="scrollDisabled$ | async"
     [class.toolbar-fixed]="isToolbarFixed$ | async"
     [class.sidenav-collapsed]="sidenavCollapsed$ | async"
     [class.content-container]="containerEnabled$ | async"
     class="page-container">

  <vex-progress-bar></vex-progress-bar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav
                 [disableClose]="desktopQuery$ | async"
                 [fixedInViewport]="(desktopQuery$ | async) === null"
                 [mode]="(desktopQuery$ | async) === null || (isLayoutVertical$ | async) ? 'over' : 'side'"
                 [opened]="(desktopQuery$ | async) && (isLayoutVertical$ | async) === false"
                 class="sidenav">
      <vex-sidenav [collapsed]="sidenavCollapsed$ | async"></vex-sidenav>
    </mat-sidenav>

    <mat-sidenav #quickpanel
                 [fixedInViewport]="(desktopQuery$ | async) === null"
                 class="quickpanel"
                 mode="over"
                 position="end">
      <vex-quickpanel></vex-quickpanel>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <vex-toolbar [hasShadow]="toolbarShadowEnabled$ | async"
                   [mobileQuery]="(desktopQuery$ | async) === null"
                   class="toolbar"></vex-toolbar>

      <main class="content">
        <router-outlet></router-outlet>
      </main>

      <vex-footer *ngIf="isFooterVisible$ | async" class="footer"></vex-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

