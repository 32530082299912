import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-take-a-job-dialog',
  templateUrl: './take-a-job-dialog.component.html',
  styleUrls: ['./take-a-job-dialog.component.scss']
})
export class TakeAJobDialogComponent implements OnInit {
  @Input()
  public reference: HTMLElement;

  @ViewChild(CdkPortal)
  public contentTemplate: CdkPortal;

  protected overlayRef: OverlayRef;
  private showing: boolean;

  constructor(protected overlay: Overlay) {
  }

  ngOnInit() {
  }

  public show() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());

    this.overlayRef.attach(this.contentTemplate);
    this.overlayRef.backdropClick().subscribe(() => this.hide());
    this.showing = true;
  }

  public hide() {
    this.overlayRef.detach();
    this.showing = false;
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom',
          offsetY: 50
        }
      ]);

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
  }
}
