<div class="wrapper">
  <div class="title">
    <h2 mat-dialog-title>Share information about iPNOTE</h2>
  </div>
  <button class="button-close" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
  </button>
  <mat-dialog-content>Help us become the biggest online IP community by sharing our page on LinkedIn. We kindly urge you
    to follow us!
  </mat-dialog-content>
  <mat-dialog-actions class="pt-6">
    <div class="space"></div>
    <button mat-raised-button color="primary" (click)="sharePost()">
      <div class="content">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_57_22217)">
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
              fill="#145FAC" />
            <path
              d="M13.6328 13.6329H11.2621V9.92017C11.2621 9.03485 11.2463 7.89517 10.0291 7.89517C8.79431 7.89517 8.60538 8.85979 8.60538 9.85573V13.6326H6.23475V5.99785H8.51056V7.04123H8.54244C8.77019 6.65179 9.09931 6.33142 9.49474 6.11424C9.89017 5.89705 10.3371 5.79119 10.7879 5.80792C13.1908 5.80792 13.6338 7.38842 13.6338 9.44454L13.6328 13.6329ZM3.55975 4.95429C2.79994 4.95442 2.18388 4.33854 2.18375 3.57873C2.18363 2.81892 2.79944 2.20285 3.55925 2.20273C4.31906 2.20254 4.93513 2.81842 4.93525 3.57823C4.93532 3.94311 4.79044 4.29307 4.53248 4.55113C4.27453 4.80919 3.92463 4.95421 3.55975 4.95429ZM4.74513 13.6329H2.37194V5.99785H4.74506V13.6329L4.74513 13.6329ZM14.8146 0.00104242H1.18069C0.536312 -0.00620758 0.007875 0.50998 0 1.15435V14.8452C0.007625 15.4899 0.536 16.0065 1.18063 15.9998H14.8146C15.4606 16.0078 15.9911 15.4911 16 14.8452V1.15329C15.9908 0.507667 15.4603 -0.00845757 14.8146 -2.00771e-05"
              fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_57_22217">
              <path
                d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z"
                fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>share</span>
      </div>
    </button>
  </mat-dialog-actions>
</div>
