<button class="show-all-links" #ref (click)="showEntityChips($event)">{{ value }}</button>

<app-entity-chips [reference]="ref">
  <div class="list-all-chips">
    <ng-container *ngIf="mode === 'link'">
      <a *ngFor="let item of data" (click)="hideEntityChips($event)"
         [routerLink]="column.nestedLink(row.company?._id, item)">
        {{item[column.nestedField]}}
      </a>
    </ng-container>
    <ng-container *ngIf="mode === 'text'">
      <span *ngFor="let item of data">{{item[column.nestedField]}}</span>
    </ng-container>
    <ng-container *ngIf="mode === 'relation'">
      <a *ngFor="let rel of data | relations" [routerLink]="column.nestedLink(rel.company, rel)"
         (click)="$event.stopImmediatePropagation();" [innerHtml]="rel | relation"></a>
    </ng-container>
  </div>
</app-entity-chips>
