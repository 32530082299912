import { UserRoleSettings } from '../user-role-settings.entity';
import { UserNotificationType } from '../type/user-notification.type';
import { UserSettingsInterface } from './user-settings.interface';
import { ICompanyEntity } from '../../company/interface/company.entity.interface';
import { IPhone } from './phone.interface';

export class IUserMeRes {
  id?: number;

  name: string;

  secondName?: string;

  patronymic?: string;

  email: string;

  avatar?: string;

  roleSettings?: UserRoleSettings[];

  notifications?: Partial<UserNotificationType>;

  settings?: UserSettingsInterface;

  emailConfirmedAt?: Date;

  companyToPay?: ICompanyEntity;

  twoFASkippedTimes: number;

  skipTwoFAUntil: Date | null;

  phone: IPhone;
}
