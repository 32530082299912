import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { TableColumn } from '@vex/interfaces/table-column.interface';
import { RelationDescriptor } from '../links/dataset/RelationDescriptor';
import { RelationDescriptorClass } from '../links/dataset/RelationDescriptorClass';
import { TranslocoService } from '@ngneat/transloco';
import { ResetFilterService } from '../../services/reset-filter/reset-filter.service';
import { format } from 'date-fns';
import { DateFormatService } from '../../../../@vex/services/date-format.service';
import { ICompanyEntity } from '@ipnote/interface';
import { EnumsService } from '../../../page-modules/desk/services/enums/enums.service';
import { map } from 'rxjs/operators';
import { ServicesService } from '../../../page-modules/desk/services/services/services.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// test deploy
@UntilDestroy()
@Component({
  selector: 'app-filter-sidenav',
  templateUrl: './filter-sidenav.component.html',
  styleUrls: ['./filter-sidenav.component.scss'],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class FilterSidenavComponent implements OnInit {
  @Input() data: any;
  @Input() company: number | ICompanyEntity;
  @Input() isEssencesTasks = false;
  @Input() relationDescriptors: RelationDescriptorClass;
  @Output() save: EventEmitter<void> = new EventEmitter<void>();
  @Output() updateChips: EventEmitter<string[] | { filters: string[]; chips: string[] }> = new EventEmitter<
    | string[]
    | {
        filters: string[];
        chips: string[];
      }
  >();
  services: any[] = [];
  oipType: any[];
  taskCountries: any[];
  descriptors: RelationDescriptor[] = [];
  filterChips: string[] = [];
  taskStages: any[];
  taskServices: any[];
  form: UntypedFormGroup = this.fb.group({});
  filterFields: [];
  booleanValues: { value?: any; label: string }[] = [
    { value: null, label: 'clear-value' },
    { value: 'true', label: 'yes' },
    { value: 'false', label: 'no' },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private transloco: TranslocoService,
    private resetFilterService: ResetFilterService,
    private router: Router,
    private dateFormatService: DateFormatService,
    private enums: EnumsService,
    private servicesService: ServicesService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.getServices();
    this.oipType = Object.values(this.enums.oipType);
    this.taskCountries = Object.values(this.enums.countries);
    this.taskStages = Object.values(this.enums.taskStages).filter(
      (value) => value !== 'new' && value !== 'choice_service' && value !== 'canceled',
    );
    this.taskServices = Object.values(this.enums.serviceNames);
    const pathValues = {};
    const routeQueryParams = this.route.snapshot.queryParams;

    this.filterFields = this.data.filter((item) => Object.prototype.hasOwnProperty.call(item, 'filterSettings'));

    if (this.relationDescriptors) {
      this.relationDescriptors.get(this.company).then((d) => {
        this.descriptors = d;

        this.descriptors.map((item: RelationDescriptor) => {
          const { key } = item;
          this.form.addControl(key, new UntypedFormControl());
        });

        this.descriptors.map((item: RelationDescriptor) => {
          const { key } = item;
          if (routeQueryParams['_r_' + key]) {
            const searchItems = routeQueryParams['_r_' + key].split(',');
            item.searcher.getAll(searchItems).subscribe((items) => {
              this.form.get(key).patchValue({ selectedValues: items, deletedValues: [] });
            });
          }
        });
      });
    }

    this.filterFields.map((item: any) => {
      const { type } = item.filterSettings;
      const property = this.getFormControlName(item);
      switch (type) {
        case 'text':
          this.form.addControl(property, new UntypedFormControl());
          break;
        case 'select':
          this.form.addControl(property, new UntypedFormControl());
          break;
        case 'number':
          this.form.addControl(property, new UntypedFormControl());
          break;
        case 'between':
          this.form.addControl(property + '_from', new UntypedFormControl());
          this.form.addControl(property + '_to', new UntypedFormControl());
          break;
        case 'multiple':
          this.form.addControl(property, new UntypedFormControl());
          break;
        case 'chips':
          this.form.addControl(property, new UntypedFormControl());
          break;
      }
    });

    this.filterFields.map((item: TableColumn<any>) => {
      const property = this.getFormControlName(item);
      if (item.filterSettings.type === 'between') {
        if (routeQueryParams['_f_' + property + '_from']) {
          const date = new Date(routeQueryParams['_f_' + property + '_from']);
          pathValues[property + '_from'] = moment(date.setHours(date.getHours() + 1));
        }
        if (routeQueryParams['_f_' + property + '_to']) {
          pathValues[property + '_to'] = moment(routeQueryParams['_f_' + property + '_to']);
        }
      }
      if (item.filterSettings.type === 'multiple') {
        if (routeQueryParams['_f_' + property]) {
          pathValues[property] = routeQueryParams['_f_' + property].split(',');
        }
      }
      if (item.filterSettings.type === 'chips') {
        if (routeQueryParams['_incl_' + property]) {
          pathValues[property] = routeQueryParams['_incl_' + property].split(',');
        }
      }
      if (item.filterSettings.type === 'text') {
        if (routeQueryParams['_f_' + property]) {
          pathValues[property] = routeQueryParams['_f_' + property];
        }
      }
      if (item.filterSettings.type === 'select') {
        if (routeQueryParams['_f_' + property]) {
          pathValues[property] = routeQueryParams['_f_' + property];
        }
      }
      if (item.filterSettings.type === 'number') {
        if (routeQueryParams['_f_' + property]) {
          pathValues[property] = routeQueryParams['_f_' + property];
        }
      }
    });

    this.form.patchValue(pathValues);
  }

  getServices(): void {
    this.servicesService
      .getServices(1, 9999, ['category.sortIndex', 'sortIndex'], { isActive: true })
      .pipe(
        map((p) => p.data.map((item) => item.name)),
        untilDestroyed(this),
      )
      .subscribe((result) => (this.services = result));
  }

  close() {
    this.save.emit();
  }

  async saveFilter() {
    const queryParams = { pageIndex: '1' };

    if (this.descriptors) {
      this.descriptors.map((item: RelationDescriptor) => {
        const { key } = item;
        if (this.form.value[key]) {
          const names = [];
          this.form.value[key].selectedValues.map((p) => names.push(p.object._id));
          queryParams['_r_' + key] = names.join(',');
        }
      });
    }

    this.filterFields.map((item: any) => {
      const property = this.getFormControlName(item);
      const { type } = item.filterSettings;
      switch (type) {
        case 'text':
          queryParams['_f_' + property] = this.form.value[property];

          if (this.form.value[property] && this.form.value[property].trim() !== '') {
            if (item.type === 'check-mark') {
              this.filterChips.push(this.transloco.translate(item.label));
            } else {
              this.filterChips.push(this.form.value[property]);
            }
          }
          break;
        case 'number':
          queryParams['_f_' + property] = this.form.value[property];

          if (this.form.value[property] || this.form.value[property] === 0) {
            this.filterChips.push(this.form.value[property]);
          }
          break;
        case 'select':
          queryParams['_f_' + property] = this.form.value[property];

          if (this.form.value[property] || this.form.value[property] === 0) {
            this.filterChips.push(this.transloco.translate(this.form.value[property]));
          }
          break;
        case 'between':
          if (this.form.value[property + '_from']) {
            const date = this.form.value[property + '_from']._d;
            queryParams['_f_' + property + '_from'] = date.toISOString();
            this.filterChips.push(
              `${this.transloco.translate(item.label)} ${this.transloco.translate('from')} ${format(
                date.setHours(date.getHours() + 0),
                this.dateFormatService.getFormat(),
                { locale: this.dateFormatService.getFullLocale() },
              )}`,
            );
          }
          if (this.form.value[property + '_to']) {
            const date = this.form.value[property + '_to']._d;
            queryParams['_f_' + property + '_to'] = date.toISOString();
            this.filterChips.push(
              `${this.transloco.translate(item.label)} ${this.transloco.translate('to')} ${format(
                this.form.value[property + '_to']._d,
                this.dateFormatService.getFormat(),
                { locale: this.dateFormatService.getFullLocale() },
              )}`,
            );
          }
          break;
        case 'multiple':
          if (this.form.value[property]) {
            queryParams['_f_' + property] = this.form.value[property].join(',');
            this.filterChips.push(...this.transloco.translate(this.form.value[property]));
          }
          break;
        case 'chips':
          if (this.form.value[property]) {
            queryParams['_incl_' + property] = this.form.value[property].join(',');
            this.filterChips.push(...this.form.value[property]);
          }
          break;
      }
    });

    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });

    this.form.markAsPristine();
    if (this.isEssencesTasks) {
      this.updateChips.emit({ filters: this.form.value, chips: this.filterChips });
    } else {
      this.updateChips.emit(this.filterChips);
    }
    this.save.emit();
  }

  async reset() {
    this.resetFilterService.reset(this.route.snapshot.queryParams, this.route);
    this.updateChips.emit([]);

    this.form.markAsPristine();
    this.save.emit();
  }

  initArrayOptions(item: TableColumn<any>): any {
    switch (item.property) {
      case 'stage':
        return this.taskStages;
      case 'country':
        return this.taskCountries;
      case 'service.name':
        return this.services;
      case 'oipType':
        return this.oipType;
    }
  }

  getFormControlName(item: TableColumn<any>): string {
    return String(item.nestedField ? item.property.toString() + '.' + item.nestedField : item.property);
  }
}
