import {
  AccessAgentCompany,
  AccessAgentReaderCompany,
  CollaboratorsCompany,
  IPOwnerCompany,
  IPOwnerReaderCompany,
  ManagerCompany,
  OwnerCompany,
  ReadersCompany,
  UserRoleSettings,
} from '../../user/user-role-settings.entity';

export declare type PermissionType = typeof permission[number];

declare type PermissionEntity =
  | 'company'
  | 'task-result'
  | 'task'
  | 'oip'
  | 'company-service'
  | 'offer'
  | 'product'
  | 'ria'
  | 'service-request'
  | 'subscription'
  | 'transaction'
  | 'user'
  | 'chat'
  | 'message'
  | 'file'
  | 'invention';

export enum CrudTypeEnum {
  ITEM_CREATE = 'item-create',
  ITEM_UPDATE = 'item-update',
  ITEM_DELETE = 'item-delete',
  ITEM_READ = 'item-read',
  LIST_READ = 'list-read',
  CHANGE_ACCESS_AGENT = 'change-access-agent',
  CHANGE_IP_OWNER = 'change-ip-owner',
  CAN_INVITE = 'can-invite',
  CAN_JOIN = 'can-join',
}

declare type PermissionCrud =
  | CrudTypeEnum.ITEM_CREATE
  | CrudTypeEnum.ITEM_UPDATE
  | CrudTypeEnum.ITEM_DELETE
  | CrudTypeEnum.ITEM_READ
  | CrudTypeEnum.LIST_READ
  | CrudTypeEnum.CHANGE_ACCESS_AGENT
  | CrudTypeEnum.CHANGE_IP_OWNER
  | CrudTypeEnum.CAN_INVITE
  | CrudTypeEnum.CAN_JOIN;

/** *
 * Here we store the company ID
 * For admins access to all entities *
 */
declare type PermissionId = number | '*';

export enum OtherTypePermissionsEnum {
  NG_APP = 'ng_app',
  MANAGER = 'manager',
  CAN_EVERYTHING = 'can-everything',
  SCHEDULER = 'scheduler',
  REPORT = 'report',
}

declare type PermissionOther =
  | OtherTypePermissionsEnum.NG_APP
  | OtherTypePermissionsEnum.MANAGER
  | OtherTypePermissionsEnum.CAN_EVERYTHING
  | OtherTypePermissionsEnum.SCHEDULER
  | OtherTypePermissionsEnum.REPORT;

declare type PermissionTuplesEntity = [PermissionEntity, PermissionCrud, PermissionId];
declare type PermissionTuplesOther = [PermissionOther, string] | [PermissionOther];

export type TypeUserRoleSettings =
  | UserRoleSettings
  | CollaboratorsCompany
  | ReadersCompany
  | OwnerCompany
  | ManagerCompany
  | IPOwnerCompany
  | IPOwnerReaderCompany
  | AccessAgentCompany
  | AccessAgentReaderCompany;

export declare const permission: [
  // ----------- Company
  'company.item-create',
  'company.item-update',
  'company.item-delete',
  'company.item-read',
  'company.list-read',
  // ----------- Task result
  'task-result.item-create',
  'task-result.item-update',
  'task-result.item-delete',
  'task-result.item-read',
  'task-result.list-read',
  // ----------- Task
  'task.item-create',
  'task.item-update',
  'task.item-delete',
  'task.item-read',
  'task.list-read',
  // ----------- OIP
  'oip.item-create',
  'oip.item-update',
  'oip.item-delete',
  'oip.item-read',
  'oip.list-read',
  'oip.change-access-agent',
  'oip.change-ip-owner',
  // ----------- Company service
  'company-service.item-create',
  'company-service.item-update',
  'company-service.item-delete',
  'company-service.item-read',
  'company-service.list-read',
  // ----------- Offer
  'offer.item-create',
  'offer.item-update',
  'offer.item-delete',
  'offer.item-read',
  'offer.list-read',
  // ----------- Product
  'product.item-create',
  'product.item-update',
  'product.item-delete',
  'product.item-read',
  'product.list-read',
  // ----------- RIA
  'ria.item-create',
  'ria.item-update',
  'ria.item-delete',
  'ria.item-read',
  'ria.list-read',
  // ----------- Service request
  'service-request.item-create',
  'service-request.item-update',
  'service-request.item-delete',
  'service-request.item-read',
  'service-request.list-read',
  // ----------- Subscription
  'subscription.item-create',
  'subscription.item-update',
  'subscription.item-delete',
  'subscription.item-read',
  'subscription.list-read',
  // ----------- Transaction
  'transaction.item-create',
  'transaction.item-update',
  'transaction.item-delete',
  'transaction.item-read',
  'transaction.list-read',
  // ----------- User
  'user.item-read',
  'user.item-update',
  'user.item-create',
  'user.item-delete',
  'user.list-read',
  // ----------- Chat
  'chat.item-create',
  'chat.item-update',
  'chat.item-delete',
  'chat.item-read',
  'chat.list-read',
  'chat.can-invite',
  'chat.can-join',
  // ----------- Message
  'message.item-create',
  'message.item-update',
  'message.item-delete',
  'message.item-read',
  'message.list-read',
  // ----------- File
  'file.item-create',
  'file.item-update',
  'file.item-delete',
  'file.item-read',
  'file.list-read',
  // ----------- Arm
  'arm.use',
  // ----------- Invention
  'invention.item-create',
  'invention.item-update',
  'invention.item-delete',
  'invention.item-read',
  'invention.list-read',
  // ----------- Counterparty
  'counterparty.item-create',
  'counterparty.item-update',
  'counterparty.item-delete',
  'counterparty.item-read',
  'counterparty.list-read',
  // ----------- Service category
  'service-category.item-create',
  'service-category.item-update',
  'service-category.item-delete',
  'service-category.item-read',
  'service-category.list-read',
  // ----------- Service
  'service.item-create',
  'service.item-update',
  'service.item-delete',
  'service.item-read',
  'service.list-read',
  // ----------- Subscription price
  'subscription-price.item-create',
  'subscription-price.item-update',
  'subscription-price.item-delete',
  'subscription-price.item-read',
  'subscription-price.list-read',
  // ----------- Subscription type
  'subscription-type.item-create',
  'subscription-type.item-update',
  'subscription-type.item-delete',
  'subscription-type.item-read',
  'subscription-type.list-read',
  // ----------- NG app permissions
  'ng-app.btn-withdraw-balance',
  'ng-app.test-admin',
  'ng-app.checkbox-hide-task-from-russia',
  'ng-app.confirm-receipt',
  'ng-app.is-active-company',
  'ng-app.block-trusted-companies',
  'ng-app.menu-admin',
  'ng-app.block-balance',
  // ----------- Manager
  'manager.change-status-company-public',
  'manager.delete-message',
  'manager.show-company-public-info',
  'manager.approval-company-public-info',
  // ----------- Others
  'add-manager-company',
  'get-list-manager',
  // ----------- Admin
  'can-everything',
  // ----------- Scheduler use
  'scheduler.use',
  // ----------- Report
  'report.use',
  // ----------- Reminder
  'reminder.item-create',
  'reminder.item-update',
  'reminder.item-delete',
  'reminder.item-read',
  'reminder.list-read',
  // ----------- Post
  'post.item-create',
  'post.item-update',
  'post.item-delete',
  'post.item-read',
  'post.list-read',
  // ----------- Price
  'price.item-create',
  'price.item-update',
  'price.item-delete',
  'price.item-read',
  'price.list-read',
];
