<input type="file"
       #fileInput
       (change)="processFile($event)"
       style="display: none"
       [multiple]=multiple>
<button mat-button
        type="button"
        *ngIf="fileTypes && fileTypes.length"
        [color]="error ? 'warn':'primary'"
        (click)="uploadClick($event)"
        [matMenuTriggerFor]="fileTypeMenu"
        [disabled]="uploading">
  <mat-icon *ngIf="!uploading && !error">add</mat-icon>
  <mat-icon *ngIf="error">warning</mat-icon>
  <span *ngIf="!uploading && !error">{{ 'add_files' | transloco }}</span>
  <span *ngIf="uploading" class="animate-pulse">{{ 'uploading' | transloco }}</span>
  <span *ngIf="error">{{error.message}}</span>
</button>
<mat-menu #fileTypeMenu="matMenu" yPosition="below" xPosition="before">
  <ng-container *ngFor="let type of fileTypes">
    <button [matMenuTriggerFor]="sub_menu" mat-menu-item>{{type.name|transloco}}</button>
    <ng-container>
      <mat-menu #sub_menu="matMenu">
        <button *ngFor="let subType of type.subTypes"
                (click)="selectFileWithType(type.value, subType, $event)"
                mat-menu-item>
          {{subType |transloco}}
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>
</mat-menu>
<button mat-button
        type="button"
        *ngIf="!fileTypes || !fileTypes.length"
        [color]="error ? 'warn':'primary'"
        (click)="selectFile($event)"
        [disabled]="uploading || disabled">
  <mat-icon *ngIf="!uploading && !error && !shortLink">add</mat-icon>
  <mat-icon *ngIf="!uploading && !error && shortLink">attach_file</mat-icon>
  <mat-icon *ngIf="error">warning</mat-icon>
  <span *ngIf="!uploading && !error && !shortLink">
    {{ multiple ? ('add_files' | transloco) : ('add-logo' | transloco) }}
  </span>
  <span *ngIf="uploading">{{ 'uploading' | transloco }}</span>
  <span *ngIf="error">{{error.message}}</span>
</button>
