<button class="card-action" #ref (click)="showDropdown($event)" [disabled]="disable" [class.white]="color === 'white'">
  {{nameKey | transloco}}
</button>

<app-take-a-job-dialog [reference]="ref">
  <div class="dropdown-panel" vexScrollbar>
    <div class="confirm-message">{{'accept-notify' | transloco}}
    </div>
    <div class="take-a-job-actions">
      <button class="accept" (click)="acceptHandler($event)">{{'accept' | transloco}}</button>
      <button class="reopen" (click)="reopenHandler($event)">{{'send-rework' | transloco}}</button>
    </div>
  </div>
</app-take-a-job-dialog>
