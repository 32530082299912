<div #ref (click)="showDropdown($event)">
  <ng-content>
  </ng-content>
</div>

<app-choice-company-dialog [reference]="ref">
  <div class="dropdown-panel" vexScrollbar>
    <div class="search-container">
      <div class="bg-card px-4"
           fxFlex
           fxFlex.lt-md="auto"
           fxLayout="row"
           fxLayoutAlign="start center">
        <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
        <input class="border-0 outline-none w-full bg-card" placeholder="{{ 'search' | transloco }}" type="text"
               [formControl]="searchCtrl">
      </div>
    </div>
    <ng-container>
      <mat-radio-group color="primary"  [(ngModel)]="activeCompany">
        <mat-radio-button *ngFor="let company of companies" [value]="company" class="company-item">
          {{company.name}}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <button class="choice-button" (click)="selectCompany()">
      {{'choice' | transloco}}
    </button>
  </div>
</app-choice-company-dialog>
