import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Icon } from '@visurel/iconify-angular';
import icConfirmed from '@iconify/icons-ic/baseline-build';
import icPlanned from '@iconify/icons-ic/baseline-hourglass-full';
import icHold from '@iconify/icons-ic/baseline-https';
import icCompleted from '@iconify/icons-ic/baseline-verified-user';
import { DropdownDialogComponent } from '../dropdown-dialog/dropdown-dialog.component';
import { DateFormatService } from '../../../../@vex/services/date-format.service';

@Component({
  selector: 'app-transaction-stage-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent {
  @Input() history: any[];
  @Input() stage: string;
  icConfirmed: Icon = icConfirmed;
  icPlanned: Icon = icPlanned;
  icHold: Icon = icHold;
  icCompleted: Icon = icCompleted;

  constructor(public dateFormatService: DateFormatService) {
  }

  @ViewChild(DropdownDialogComponent, { static: true }) dropdown: DropdownDialogComponent;
  @ViewChild('ref') ref: ElementRef<HTMLElement>;

  public showDropdown($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.dropdown.show();
  }
}
