<div #ref (click)="showDropdown($event)" class="stage-button">
  <span [ngClass]="stage + '_color'">
      <mat-icon *ngIf="stage === 'confirmed'" [icIcon]="icConfirmed"></mat-icon>
    <mat-icon *ngIf="stage === 'hold'" [icIcon]="icHold"></mat-icon>
      <mat-icon *ngIf="stage === 'planned'" [icIcon]="icPlanned"></mat-icon>
      <mat-icon *ngIf="stage === 'completed'" [icIcon]="icCompleted"></mat-icon>
    </span>
  <span class="transaction-stage" [ngClass]="stage + '_color'">{{stage | transloco}}</span>
</div>

<app-dropdown-dialog [reference]="ref">
  <div class="dropdown-panel">
    <div class="history-title">{{ 'transaction_stages' | transloco}}</div>
    <ng-container *ngFor="let item of history">
      <div class="history-item">
        <div fxFlex="8" style="font-size: 10px; padding-top: 2px">&#9679;</div>
        <div fxFlex="92">
          <div>
            {{item.date | date: dateFormatService.getFormat() + ' hh:mm' : null : dateFormatService.getLocale()}}
          </div>
          <div>
            {{item.stage | transloco}}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!history || !history.length">
      <div class="history-item">No result</div>
    </ng-container>
  </div>
</app-dropdown-dialog>
