import { Currency } from '../../_shared/enums/currency.enum';
import { DeliveryTimeUnit } from '../../_shared/enums/time-unit.enum';
import { DeliveryTimeType } from '../enums/delivery-time-type.enum';
import { PriceDetailsType } from '../enums/price-details-type.enum';

interface IBase {
  type: PriceDetailsType;
  additionalClassPrice: number;
  additionalClassCurrency: Currency;
}

export interface IBasePriceDetails extends IBase {
  firstClassPrice: number;
  firstClassCurrency: Currency;
}

export interface IPriceDetailsWithSecondClass extends IBasePriceDetails {
  secondClassPrice: number;
  secondClassCurrency: Currency;
}

export interface IPriceDetailsWithThirdClass extends IPriceDetailsWithSecondClass {
  thirdClassPrice: number;
  thirdClassCurrency: Currency;
}

export interface IPriceDetailsWithFromSecondToFifthClasses extends IBasePriceDetails {
  fromSecondToFifthClassesPrice: number;
  fromSecondToFifthClassesCurrency: Currency;
}

export interface IPriceDetailsWithFirstTwoClasses extends IBase {
  firstTwoClassesPrice: number;
  firstTwoClassesCurrency: Currency;
}

export interface IPriceDetailsWithFirstThreeClasses extends IBase {
  firstThreeClassesPrice: number;
  firstThreeClassesCurrency: Currency;
}

export interface IPriceDetailsWithFirstFiveClasses extends IBase {
  firstFiveClassesPrice: number;
  firstFiveClassesCurrency: Currency;
}

export interface IPriceDetailsWithFromUpTo {
  type: PriceDetailsType;
  fromPrice: number;
  upToPrice: number;
  fromUpToCurrency: Currency;
}

export interface IPriceDetailsEmpty {
  type: PriceDetailsType.EMPTY;
}

export type PriceDetails =
  | IBasePriceDetails
  | IPriceDetailsWithSecondClass
  | IPriceDetailsWithThirdClass
  | IPriceDetailsWithFirstTwoClasses
  | IPriceDetailsWithFirstThreeClasses
  | IPriceDetailsWithFirstFiveClasses
  | IPriceDetailsWithFromSecondToFifthClasses
  | IPriceDetailsWithFromUpTo
  | IPriceDetailsEmpty;

export interface IBaseDeliveryTimeDetails {
  type: DeliveryTimeType;
  firstClassTimeAmount: number;
  firstClassTimeUnit: DeliveryTimeUnit;
  additionalClassTimeAmount: number;
  additionalClassTimeUnit: DeliveryTimeUnit;
}

export type IDTDetailsWithoutAdditionalClass = Omit<
  IBaseDeliveryTimeDetails,
  'additionalClassTimeAmount' | 'additionalClassTimeUnit'
>;

export interface IEmptyDeliveryTimeDetails {
  type: DeliveryTimeType;
}

export type DeliveryTimeDetails =
  | IBaseDeliveryTimeDetails
  | IDTDetailsWithoutAdditionalClass
  | IEmptyDeliveryTimeDetails;
