<input type="file" #input (change)="file($event)" style="display: none;" />
<div class="box" [class.image-in-circle]="imageInCircle">
  <img *ngIf="fileUrl" [src]="fileUrl" (click)="!disabled && input.click()" />
</div>
<button (click)="input.click()" mat-button *ngIf="!fileUrl" i18n type="button" [disabled]="disabled"
        class="default-button">
  {{ label }}
  <mat-icon [icIcon]="defaultIcon" color="#145FAC" size="24px" *ngIf="defaultIcon"></mat-icon>
</button>
<button *ngIf="fileUrl" mat-icon-button class="delete" (click)="remove()" type="button">
  <mat-icon>delete</mat-icon>
</button>
<button *ngIf="fileUrl" mat-icon-button class="upload" (click)="input.click()" type="button">
  <mat-icon>edit</mat-icon>
</button>

