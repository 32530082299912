import { Component, Input } from '@angular/core';
import { ICompanyEntity } from '@ipnote/interface';
import { isNull } from 'lodash';

@Component({
  selector: 'app-customer-completed-tasks',
  templateUrl: './customer-completed-tasks.component.html',
  styleUrls: ['./customer-completed-tasks.component.scss'],
})
export class CustomerCompletedTasksComponent {
  @Input() showToolTip = true;
  @Input() customer: ICompanyEntity;

  protected readonly isNull = isNull;
}
