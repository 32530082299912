import { Pipe, PipeTransform } from '@angular/core';
import { IFileEntity, IFolderFileEntity } from '@ipnote/interface';
import { FileTplType } from '@ipnote/type';

@Pipe({
  name: 'isFolderPipe',
  pure: false
})
export class IsFolderPipe implements PipeTransform {
  /**
   * Constructor
   */
  constructor() {
  }

  transform(file: FileTplType): boolean {
    return this.isFolder(file);
  }

  isFolder(file: IFolderFileEntity | IFileEntity): file is IFolderFileEntity {
    return !('url' in file);
  }
}
