import { Component, Input } from '@angular/core';
import { ICompanyEntity } from '@ipnote/interface';
import { isNull } from 'lodash';

@Component({
  selector: 'app-provider-reviews',
  templateUrl: './provider-reviews.component.html',
  styleUrls: ['./provider-reviews.component.scss'],
})
export class ProviderReviewsComponent {
  @Input() provider: ICompanyEntity
  protected readonly isNull = isNull;
}
