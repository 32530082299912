<div #ref (click)="showDropdown($event)">
  <ng-content>
  </ng-content>
</div>

<app-choice-company-dialog [reference]="ref">
  <div class="dropdown-panel" vexScrollbar>
    <ng-container>
      <mat-radio-group color="primary" [(ngModel)]="activeUser">
        <mat-radio-button *ngFor="let user of users" [value]="user" class="company-item">
          {{user.name}} {{user.secondName}}
        </mat-radio-button>
      </mat-radio-group>
    </ng-container>
    <button class="choice-button" (click)="selectUser()">
      {{'choice' | transloco}}
    </button>
  </div>
</app-choice-company-dialog>
