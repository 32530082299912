export enum OpenAiOptionsNameEnum {
  MODEL = 'model',
  MODEL_REPORT = 'model_report',
  KEY = 'key',
  TEMPERATURE = 'temperature',
  MAX_TOKENS = 'max_tokens',
  TOP_P = 'top_p',
  FREQUENCY_PENALTY = 'frequency_penalty',
  PRESENCE_PENALTY = 'presence_penalty',
  WELCOME_MESSAGE = 'welcome_message',
  WELCOME_MESSAGE_REGISTRATION = 'welcome_message_registration',
  DEFAULT_PROMPT = 'default_prompt',
  REGISTRATION_PROMPT = 'registration_prompt',
}
