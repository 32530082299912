import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';
import { Collaborator, CollaboratorsCompany } from '../../../../dataset/Collaborator';
import { finalize, flatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { flatten } from 'lodash';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';

@Injectable({
  providedIn: 'root'
})
export class CollaboratorsService {
  constructor(private http: HttpClient, private baseUrl: BaseUrlService, private spinnerService: SpinnerViewService) {
  }

  convertCollaborators = (company: CollaboratorsCompany): Collaborator[] =>
    flatten([ 'collaborators', 'managers'/*, 'owner'*/ ].map((type) => company[type].map((user) => ({ user, type }))));

  get(companyId: number): Observable<CollaboratorsCompany> {
    return this.baseUrl.getBaseUrl(`companies/${companyId}/collaborators`).pipe(flatMap((url) => this.http.get<CollaboratorsCompany>(url)));
  }

  invite(companyId: number, body: any): Observable<CollaboratorsCompany> {
    this.spinnerService.start()
    return this.baseUrl
      .getBaseUrl(`companies/${companyId}/collaborators`)
      .pipe(
        mergeMap((url) => this.http.post<CollaboratorsCompany>(url, body)),
        finalize(() => this.spinnerService.stop())
      );
  }

  expel(companyId: number, userId: any): Observable<CollaboratorsCompany> {
    this.spinnerService.start()
    return this.baseUrl
      .getBaseUrl(`companies/${companyId}/collaborators/${userId}`)
      .pipe(
        mergeMap((url) => this.http.delete<CollaboratorsCompany>(url)),
        finalize(() => this.spinnerService.stop())
      );
  }

  update(companyId: number, userId: any, body: any): Observable<CollaboratorsCompany> {
    this.spinnerService.start()
    return this.baseUrl
      .getBaseUrl(`companies/${companyId}/collaborators/${userId}`)
      .pipe(
        mergeMap((url) => this.http.patch<CollaboratorsCompany>(url, body)),
        finalize(() => this.spinnerService.stop())
      );
  }
}
