import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { RequestQueryBuilderService } from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';
import { concatLatestFrom } from '@ngrx/effects';
import { finalize, flatMap, map, mergeMap } from 'rxjs/operators';
import { Paged } from '../../../../dataset/Paged';
import {
  IGetManyDefaultResponse,
  ITaskEntity,
  RequestCreatingTaskFromFileInterface,
  ResponseCreatingTaskFromFileInterface,
} from '@ipnote/interface';
import { Observable } from 'rxjs';
import { SearchInterface } from '../../../../app-common/services/search.interface';
import { ExportDealsReqDto } from '@ipnote/dto';

export class TaskSearcher implements SearchInterface {
  constructor(private apiService: TasksService, private additionalOptions: any) {}

  search(token: string): Observable<ITaskEntity[]> {
    return this.apiService
      .getAll(undefined, undefined, undefined, token, undefined, undefined, undefined, this.additionalOptions)
      .pipe(map((res) => res.data));
  }

  getAll($in: string[]): Observable<any[]> {
    return undefined;
  }
}
@Injectable({
  providedIn: 'root',
})
export class TasksService {
  constructor(
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    private queryBuilder: RequestQueryBuilderService,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerViewService,
  ) {}

  getAll(
    pageIndex: number = 1,
    pageSize: number = 20,
    sort: string = '-createdAt',
    searchToken?: string,
    searchFields: string[] = ['title'],
    filter?: object,
    relations?: string[],
    additionalOptions?: any,
  ): Observable<IGetManyDefaultResponse<ITaskEntity>> {
    return this.baseUrl.getBaseUrl('tasks').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          pageIndex,
          pageSize,
          sort,
          searchToken,
          filter,
          relations,
          additionalOptions,
          searchFields,
        }),
      ]),
      mergeMap(([url, params]) => this.http.get<IGetManyDefaultResponse<ITaskEntity>>(url, { params: { ...params } })),
    );
  }
  create(body: any, companyId?: number): Observable<ITaskEntity> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl('tasks').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({ companyId })]),
      mergeMap(([url, params]) => this.http.post<ITaskEntity>(url, body, { params })),
      finalize(() => this.spinnerService.stop()),
    );
  }
  isIncomingTaskExist(): Observable<{ status: boolean }> {
    return this.baseUrl.getBaseUrl('tasks/are-there-any-tasks').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) => this.http.get<{ status: boolean }>(url, { params: { ...params, incoming: 'true' } })),
    );
  }

  update(id: number, body: any, companyId?: number): Observable<ITaskEntity> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`tasks/${id}`).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({ companyId })]),
      mergeMap(([url, params]) => this.http.patch<ITaskEntity>(url, body, { params })),
      finalize(() => this.spinnerService.stop()),
    );
  }
  reject(id: number, tags?: string[], rejectedDescription?: string, files?: File[]): Observable<ITaskEntity> {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    if (rejectedDescription) {
      formData.append('rejectedDescription', rejectedDescription);
    }
    if (tags) {
      formData.append('tags', JSON.stringify(tags));
    }
    return this.baseUrl.getBaseUrl(`tasks/${id}/reject`).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) => this.http.post<ITaskEntity>(url, formData, { params })),
    );
  }
  accept(id: number, body?: any): Observable<ITaskEntity> {
    return this.baseUrl.getBaseUrl(`tasks/${id}/accept`).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) => this.http.post<ITaskEntity>(url, body, { params })),
    );
  }
  done(
    id: number,
    tags?: string[],
    rejectedDescription?: string,
    files?: File[],
    folderFileName?: string,
    comment?: string,
    oipId?: number | null,
    oipName?: string,
    requestNumber?: string,
    requestDate?: string,
    priorityNumber?: string,
    priorityDate?: string,
  ): Observable<ITaskEntity> {
    this.spinnerService.start();
    const formData = new FormData();
    if (files.length > 0) {
      files.forEach((file) => {
        formData.append('files', file, file?.name);
      });
    }
    if (folderFileName) formData.append('folderFileName', folderFileName);
    if (rejectedDescription) formData.append('resultDescription', rejectedDescription);
    if (tags) formData.append('tags', JSON.stringify(tags));
    if (comment) formData.append('comment', comment);
    if (oipId) formData.append('oipId', String(oipId));

    /** Filing application */
    if (oipName) formData.append('oipName', oipName);
    if (requestNumber) formData.append('requestNumber', requestNumber);
    if (requestDate) formData.append('requestDate', requestDate);
    if (priorityNumber) formData.append('priorityNumber', priorityNumber);
    if (priorityDate) formData.append('priorityDate', priorityDate);

    return this.baseUrl.getBaseUrl(`tasks/${id}/done`).pipe(
      mergeMap((url) => this.http.post<ITaskEntity>(url, formData)),
      finalize(() => this.spinnerService.stop()),
    );
  }
  innerWork(id: number, force?: boolean): Observable<ITaskEntity> {
    return this.baseUrl.getBaseUrl(`tasks/${id}/start`).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) => this.http.post<ITaskEntity>(url, {}, { params })),
    );
  }

  isOutgoingTaskExist(): Observable<{ status: boolean }> {
    return this.baseUrl.getBaseUrl('tasks/are-there-any-tasks').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) =>
        this.http.get<{ status: boolean }>(url, {
          params: {
            ...params,
            incoming: 'false',
          },
        }),
      ),
    );
  }

  makeCopy(id: number): Observable<ITaskEntity> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`tasks/${id}/copy`).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      mergeMap(([url, params]) => this.http.post<ITaskEntity>(url, {})),
      finalize(() => {
        this.spinnerService.stop();
        this.snackBar.open('Task has been copied', 'OK', { duration: 2000 });
      }),
    );
  }

  get(id: number, params?: any): Observable<ITaskEntity> {
    return this.baseUrl.getBaseUrl(`tasks/${id}`).pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          additionalOptions: params,
        }),
      ]),
      mergeMap(([url, params]) => this.http.get<ITaskEntity>(url, { params })),
    );
  }

  delete(id: number, body: any) {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`tasks/${id}`).pipe(
      mergeMap((url) => this.http.request('delete', url, { body })),
      finalize(() => this.spinnerService.stop()),
    );
  }
  getAllAdminsTasks(
    pageIndex: number = 1,
    pageSize: number = 10,
    additionalOptions: object,
    searchToken?: string,
    searchFields?: string[],
    filter?: any,
    sort?: string,
  ): Observable<Paged<ITaskEntity>> {
    return this.baseUrl.getBaseUrl('tasks/all').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          pageIndex,
          pageSize,
          additionalOptions,
          filter,
          searchToken,
          searchFields,
          sort,
        }),
      ]),
      mergeMap(([url, params]) => this.http.get<Paged<ITaskEntity>>(url, { params: { ...params } })),
    );
  }
  getProviderClientsInvoices(
    pageIndex: number = 1,
    pageSize: number = 10,
    additionalOptions: object,
    searchToken?: string,
    searchFields?: string[],
    filter?: any,
  ): Observable<Paged<ITaskEntity>> {
    return this.baseUrl.getBaseUrl('tasks/unpaid-tasks-from-provider-clients').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          pageIndex,
          pageSize,
          additionalOptions,
          filter,
          searchToken,
          searchFields,
        }),
      ]),
      mergeMap(([url, params]) => this.http.get<Paged<ITaskEntity>>(url, { params: { ...params } })),
    );
  }
  pay(id: number, account?: string) {
    return this.baseUrl.getBaseUrl(`tasks/${id}/pay`).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) => this.http.put<any>(url, { account }, { params })),
    );
  }
  generatePayoutsInvoice(id: number): Observable<any> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`tasks/${id}/generate-payouts-invoice`).pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      flatMap(([url, params]) => this.http.post<any>(url, {})),
      finalize(() => this.spinnerService.stop()),
    );
  }
  creatingATaskFromAFile(
    body: RequestCreatingTaskFromFileInterface,
  ): Observable<ResponseCreatingTaskFromFileInterface> {
    return this.baseUrl
      .getBaseUrl('tasks/creation-from-file')
      .pipe(flatMap((url) => this.http.post<ResponseCreatingTaskFromFileInterface>(url, body)));
  }

  searcher(filter: any = null, additionalOptions: any): SearchInterface {
    return new TaskSearcher(this, additionalOptions);
  }

  exportDeals(body: ExportDealsReqDto) {
    return this.baseUrl.getBaseUrl('tasks/export-deals').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      mergeMap(([url, params]) => this.http.post(url, body, { observe: 'response', responseType: 'blob' })),
    );
  }
}
