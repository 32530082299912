import { OIPType } from '../../oip/enums/oip-type.enum';
import { Country } from '../../_shared/enums/country.enum';
import { Protocols } from '../../_shared/enums/protocol.enum';
import { Territory } from '../../_shared/enums/territory.enum';

export type ConditionParams = {
  serviceId: number;
  country: Country | Territory | Protocols;
  oipType: OIPType;
};

export type UsedConditionParamsStructure = { [key: string]: { [key2: string]: { [key3: string]: string[] } } };
