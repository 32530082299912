export enum Jurisdiction {
  AL = 'AL', // "Albania"
  AZ = 'AZ', // "Azerbaijan"
  BH = 'BH', // "Bahrain"
  BW = 'BW', // "Botswana"
  CU = 'CU', // "Cuba"
  EC = 'EC', // "Ecuador"
  EP = 'EP', // "European Patent Organisation"
  ES = 'ES', // "Spain"
  FI = 'FI', // "Finland"
  IR = 'IR', // "Iran (Islamic Republic of)"
  IS = 'IS', // "Iceland"
  JM = 'JM', // "Jamaica"
  JP = 'JP', // "Japan"
  KH = 'KH', // "Cambodia"
  KN = 'KN', // "Saint Kitts and Nevis"
  KR = 'KR', // "Republic of Korea"
  KW = 'KW', // "Kuwait"
  LS = 'LS', // "Lesotho"
  MX = 'MX', // "Mexico"
  RO = 'RO', // "Romania"
  SK = 'SK', // "Slovakia"
  UZ = 'UZ', // "Uzbekistan"
  AE = 'AE', // "United Arab Emirates"
  BY = 'BY', // "Belarus"
  CN = 'CN', // "China"
  CR = 'CR', // "Costa Rica"
  CZ = 'CZ', // "Czechia"
  DJ = 'DJ', // "Djibouti"
  DZ = 'DZ', // "Algeria"
  EG = 'EG', // "Egypt"
  HN = 'HN', // "Honduras"
  LR = 'LR', // "Liberia"
  LU = 'LU', // "Luxembourg"
  MG = 'MG', // "Madagascar"
  MN = 'MN', // "Mongolia"
  MW = 'MW', // "Malawi"
  NG = 'NG', // "Nigeria"
  NO = 'NO', // "Norway"
  PG = 'PG', // "Papua New Guinea"
  PL = 'PL', //  "Poland"
  PT = 'PT', // "Portugal"
  QA = 'QA', // "Qatar"
  SC = 'SC', // "Seychelles"
  SD = 'SD', // "Sudan"
  SE = 'SE', // "Sweden"
  SG = 'SG', // "Singapore"
  SY = 'SY', // "Syrian Arab Republic"
  TM = 'TM', // "Turkmenistan"
  TT = 'TT', //  "Trinidad and Tobago"
  UG = 'UG', // "Uganda"
  AG = 'AG', // "Antigua and Barbuda"
  AT = 'AT', // "Austria"
  BA = 'BA', // "Bosnia and Herzegovina"
  CA = 'CA', // "Canada"
  CO = 'CO', // "Colombia"
  DE = 'DE', // "Germany"
  GM = 'GM', // "Gambia"
  GT = 'GT', // "Guatemala"
  IN = 'IN', // "India"
  IQ = 'IQ', // "Iraq"
  KG = 'KG', // "Kyrgyzstan"
  KZ = 'KZ', // "Kazakhstan"
  MA = 'MA', // "Morocco"
  MD = 'MD', // "Republic of Moldova"
  MK = 'MK', // "North Macedonia"
  NA = 'NA', // "Namibia"
  NZ = 'NZ', // "New Zealand"
  SA = 'SA', // "Saudi Arabia"
  SV = 'SV', // "El Salvador"
  TZ = 'TZ', // "United Republic of Tanzania"
  US = 'US', // "United States of America"
  WS = 'WS', // "Samoa"
  ZA = 'ZA', // "South Africa"
  ZW = 'ZW', // "Zimbabwe"
  AM = 'AM', // "Armenia"
  AO = 'AO', // "Angola"
  AP = 'AP', // "African Regional Intellectual Property Organization"
  AU = 'AU', // "Australia"
  BB = 'BB', // "Barbados"
  BG = 'BG', // "Bulgaria"
  BN = 'BN', // "Brunei Darussalam"
  BR = 'BR', // "Brazil"
  BZ = 'BZ', // "Belize"
  CH = 'CH', // "Switzerland"
  DK = 'DK', // "Denmark"
  DO = 'DO', // "Dominican Republic"
  EA = 'EA', // "Eurasian Patent Organization"
  EE = 'EE', // "Estonia"
  GB = 'GB', // "United Kingdom"
  GE = 'GE', // "Georgia"
  GH = 'GH', // "Ghana"
  HR = 'HR', // "Croatia"
  HU = 'HU', // "Hungary"
  ID = 'ID', // "Indonesia"
  IL = 'IL', // "Israel"
  IT = 'IT', // "Italy"
  JO = 'JO', // "Jordan"
  KE = 'KE', // "Kenya"
  KP = 'KP', // "Democratic People’s Republic of Korea"
  LA = 'LA', // "Lao People’s Democratic Republic"
  LK = 'LK', // "Sri Lanka"
  MZ = 'MZ', // "Mozambique"
  NI = 'NI', // "Nicaragua"
  OA = 'OA', // "African Intellectual Property Organization"
  OM = 'OM', // "Oman"
  PA = 'PA', // "Panama"
  PE = 'PE', // "Peru"
  PH = 'PH', // "Philippines"
  RS = 'RS', // "Serbia"
  RU = 'RU', // "Russian Federation"
  TH = 'TH', // "Thailand"
  TJ = 'TJ', // "Tajikistan"
  TN = 'TN', // "Tunisia"
  TR = 'TR', // "Türkiye"
  UA = 'UA', // "Ukraine"
  VC = 'VC', // "Saint Vincent and the Grenadines"
  VN = 'VN', // "Viet Nam"
  ZM = 'ZM', // "Zambia"
}
