import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NeedHelpInvoice } from '@ipnote/type';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NeedHelpComponent),
      multi: true
    }
  ]
})
export class NeedHelpComponent implements ControlValueAccessor {
  value = null;
  private onChange: (value: any) => void;
  private onTouched: () => void;
  @Output() emitValue = new EventEmitter<NeedHelpInvoice>();
  constructor() {
  }

  writeValue(input: NeedHelpInvoice): void {
    this.value = input;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  updateValue(insideValue: NeedHelpInvoice) {
    this.value = insideValue; // html
    this.emitValue.emit(this.value)
    this.onChange(insideValue); // уведомить Forms API
    this.onTouched();
  }
}
