import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.scss'],
})
export class TrustpilotComponent implements OnInit{
  ngOnInit() {
    const trustboxRef = document.getElementById('trustbox');
    // @ts-ignore
    window.Trustpilot.loadFromElement(trustboxRef);
  }
}
