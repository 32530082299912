import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ICompanyEntity } from '@ipnote/interface';
import { selectStateSelectedCompany } from '#selectors';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss'],
})
export class CancelSubscriptionComponent implements OnInit {
  company$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  company: ICompanyEntity;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<CancelSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.company$.pipe(untilDestroyed(this)).subscribe((company) => {
      this.company = company;
    });
  }

  decline() {
    this.dialogRef.close(false);
  }

  accept() {
    this.dialogRef.close(true);
  }
}
