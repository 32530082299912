import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FileManagerService } from '../../services/file-manager/file-manager.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadImageComponent),
      multi: true
    }
  ]
})
export class UploadImageComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() defaultIcon: any;
  @Input() imageInCircle: boolean;
  private onChange: (value: any) => void;
  private onTouched: () => void;
  disabled = false;
  fileUrl: SafeResourceUrl;
  image: File;

  constructor(private uploadService: FileManagerService, private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  remove() {
    this.fileUrl = null;
    this.onChange(null);
  }

  async file($event: Event) {
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    if (file) {
      const url = await this.uploadService.previewUrl(file);
      this.image = file;
      this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this.onChange(file);
      this.cd.markForCheck();
    }
  }

  writeValue(obj: string): void {
    this.fileUrl = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
