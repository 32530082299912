import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { EntityChipsComponent } from '../entity-chips/entity-chips.component';

@Component({
  selector: 'app-entity-chips-container',
  templateUrl: './entity-chips-container.component.html',
  styleUrls: ['./entity-chips-container.component.scss']
})
export class EntityChipsContainerComponent implements OnInit {
  @Input() value: number;
  @Input() mode: string;

  @Input() data: string[];
  @Input() row: any;
  @Input() column: any;

  @ViewChild(EntityChipsComponent) entityChips: EntityChipsComponent;

  constructor() {
  }

  ngOnInit() {
  }

  showEntityChips($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.entityChips.show();
  }

  hideEntityChips($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.entityChips.hide();
  }
}
