<mat-dialog-content>
  <div class="title text-center mb-4">{{ 'Choose a member' | transloco }}</div>
  <div class="search-field flex flex-nowrap items-center">
    <mat-icon>search</mat-icon>
    <input class="ml-2" matInput [formControl]="searchControl" placeholder="{{ 'find' | transloco }}"
           autocomplete="off">
  </div>
  <div class="flex items-center justify-center" *ngIf="dataLoading">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
  <mat-nav-list *ngIf="!dataLoading">
    <a mat-list-item *ngFor="let collaborator of collaborators" (click)="select(collaborator)">
      <app-avatar-photo class="avatar" [user]="collaborator"></app-avatar-photo>
      <span class="collaborator-name ml-2">{{collaborator?.name}} {{collaborator?.secondName}}</span>
    </a>
  </mat-nav-list>
</mat-dialog-content>
<!--<mat-dialog-actions>-->
<!--  <button mat-raised-button color="primary" (click)="selectAll({id:'*'})" fxFlex="50"-->
<!--          *ngIf="!data.disableSelectAll">{{ ('select-all' | transloco).toUpperCase() }}</button>-->
<!--  <app-paginator *ngIf="dataSet" [pageSize]="dataSet.count" [pageIndex]="dataSet.page" fxFlex="50"-->
<!--                 [length]="dataSet.total" (changePageIndex)="pageChanged($event)">-->
<!--  </app-paginator>-->
<!--</mat-dialog-actions>-->
