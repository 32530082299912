import { FileManagerService } from '../../../../../app-common/services/file-manager/file-manager.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ServiceRequestsService } from 'app/page-modules/desk/services/service-requests/service-requests.service';
import { EnumsService } from '../../../services/enums/enums.service';
import { ErrorService } from '../../../../../app-common/services/error-service/error-service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ICompanyEntity, IOfferEntity, ITaskEntity } from '@ipnote/interface';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { map, startWith } from 'rxjs/operators';
import { selectStateSelectedCompany } from '#selectors';
import { OfferService } from '../../../services/offer/offer.service';
import { DialogService } from '../../../../../app-common/services/dialogs/dialog.service';
import { AnalyticsEventEnum } from '@ipnote/enum';
import { AnalyticsService } from '../../../../../app-common/services/analytics/analytics.service';

@UntilDestroy()
@Component({
  selector: 'app-offer-price',
  templateUrl: './offer-price.component.html',
  styleUrls: ['./offer-price.component.scss'],
})
export class OfferPriceComponent implements OnInit, OnDestroy {
  file: File;
  currencies = Object.values(this.enums.currencies);
  formSaveInProcess = false;
  filteredCurrency: Observable<string[]>;
  currencySearch = new UntypedFormControl();
  compensationCurrencySearch = new UntypedFormControl();

  filteredCompensationCurrency: Observable<string[]>;
  selectedCompany: ICompanyEntity;
  public selectedCompany$ = this.store.select(selectStateSelectedCompany);

  form = new FormGroup({
    price: new FormControl(null, Validators.required),
    currency: new FormControl(null, Validators.required),
    compensationPrice: new FormControl(null),
    compensationCurrency: new FormControl(null),
    description: new FormControl(null),
    days: new FormControl(null, [Validators.required, Validators.minLength(1)]),
  });

  constructor(
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { task: ITaskEntity; adminEditOffer: boolean },
    private uploadService: FileManagerService,
    private dialogRef: MatDialogRef<OfferPriceComponent>,
    private enums: EnumsService,
    private serviceRequestService: ServiceRequestsService,
    private errorService: ErrorService,
    private offerService: OfferService,
    private dialogService: DialogService,
    private analyticsService: AnalyticsService,
  ) {
    this.selectedCompany$.subscribe((company) => (this.selectedCompany = company));

    if (this.data.task && this.data.task.serviceRequests[0].offer) {
      if (this.data.task.serviceRequests[0].offer.compensationPrice === 0)
        delete this.data.task.serviceRequests[0].offer.compensationPrice;
      this.form.patchValue(this.data.task.serviceRequests[0].offer);
    }
  }

  get ipnoteFee(): number {
    if (!this.selectedCompany?.additionalFields?.incomingFeeSize) return null;
    return parseFloat(
      ((this.form.value.price / 100) * this.selectedCompany?.additionalFields?.incomingFeeSize).toFixed(2),
    );
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    if (!this.data.task.serviceRequests[0].offer) {
      this.serviceRequestService
        .getPrecalculatedPrice(this.data.task.serviceRequests[0].id)
        .pipe()
        .subscribe({
          next: (res) => {
            this.form.controls.price.setValue(res.price);
            this.form.controls.currency.setValue(res.currency);
            this.form.controls.compensationPrice.setValue(res.compensationPrice);
            this.form.controls.compensationCurrency.setValue(res.compensationCurrency);
            this.form.controls.days.setValue(res.days);
          },
          error: (err) => this.dialogService.error(err),
        });
    }
    this.form.get('compensationPrice').valueChanges.subscribe((value) => {
      const compensationCurrency = this.form.get('compensationCurrency');
      if (value) {
        compensationCurrency.setValidators(Validators.required);
        compensationCurrency.updateValueAndValidity({ emitEvent: false });
        compensationCurrency.markAsTouched();
      } else {
        compensationCurrency.setValidators(null);
        compensationCurrency.updateValueAndValidity({ emitEvent: false });
        compensationCurrency.markAsPristine();
      }
    });
    this.filteredCurrency = this.currencySearch.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val)),
    );
    this.filteredCompensationCurrency = this.compensationCurrencySearch.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val)),
    );
    if (this.data.adminEditOffer) {
      this.form.controls.currency.disable();
      this.form.controls.compensationCurrency.disable();
      this.form.controls.days.disable();
    }
  }

  filter(val: string): string[] {
    return this.currencies.filter((option) => option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  handleFile(event: any): void {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.file = file;
    }
    event.target.value = '';
  }

  deleteFile(): void {
    this.file = null;
  }

  async send(): Promise<any> {
    this.formSaveInProcess = true;
    let files = [];
    if (this.file) {
      const url = await this.uploadService.upload(this.file).toPromise();
      files = [
        {
          url,
          fileItemType: 'file',
          name: this.file.name,
          type: this.file.type,
          size: this.file.size,
          createdAt: new Date(),
        },
      ];
    }
    const { price, days, currency, description, compensationPrice, compensationCurrency } = this.form.value;

    const body = {
      files,
      price: Number(price),
      maxRangePrice: 0,
      days,
      currency,
      description: description || '',
      compensationPrice: Number(compensationPrice),
      compensationCurrency,
      taskId: this.data.task.id,
      companyId: this.data.task.serviceRequests[0].executorCompany.id,
    };

    let offer: IOfferEntity | void;
    if (this.data.adminEditOffer) {
      offer = await this.offerService
        .adminEditOffer(this.data.task.serviceRequests[0].offer.id, {
          price: Number(price),
          compensationPrice: Number(compensationPrice),
        })
        .toPromise()
        .catch((err) => {
          this.errorService.handleError(err);
        });
    } else if (this.data.task.serviceRequests[0].offer?.price && !this.data.adminEditOffer) {
      offer = await this.offerService
        .updateOffer(this.data.task.serviceRequests[0].offer.id, body)
        .toPromise()
        .catch((err) => {
          this.errorService.handleError(err);
        });
    } else if (!this.data.adminEditOffer) {
      offer = await this.offerService
        .offer(this.data.task.serviceRequests[0].id, body)
        .toPromise()
        .catch((err) => {
          this.errorService.handleError(err);
        });
    }
    if (offer) {
      this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_OFFER_COMPLETED, { taskId: this.data.task.id });
    }
    this.dialogRef.close(offer);
  }

  changeCompensationCurrency(e): void {
    if (e) this.form.get('compensationCurrency').setValidators([Validators.required]);
    else this.form.get('compensationCurrency').setValidators([]);
  }

  close(): void {
    this.dialogRef.close(null);
  }
}
