import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RelationsService } from '../../services/links/relations.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RelationDescriptor } from './dataset/RelationDescriptor';

@UntilDestroy()
@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit, OnDestroy {
  @Input() readOnly: boolean;
  @Input() company: number;
  @Input() target: any;
  @Input() currentEntity: string;
  @Input() currentModel: string;
  @Input() relationDescriptors: RelationDescriptor[];
  @Output() saveChanges: EventEmitter<any> = new EventEmitter<any>();

  @Input() set commonView(value: boolean) {
    this._commonView = value;
  }

  get commonView(): boolean {
    return this._commonView;
  }

  // tslint:disable-next-line:variable-name
  private _commonView = false;
  form: UntypedFormGroup;

  constructor(private relationsService: RelationsService, private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group(
      this.relationDescriptors.reduceRight((prev, curr) => {
        prev[curr.key] = [];
        return prev;
      }, {})
    );
    const values = this.relationDescriptors.reduceRight((prev, curr) => {
      prev[curr.key] = this.target[curr.key];
      return prev;
    }, {});
    this.form.patchValue(values);
  }

  save(): void {
    const value = this.form.value;
    this.form.markAsPristine();
    this.saveChanges.emit(value);
  }

  ngOnDestroy(): void {
  }

  openModel($event: any): void {
  }

  generateExclude(key: string): number[] {
    const data = this.form.value[key];
    if (!data) {
      return [this.target];
    }
    return [this.target, data.map((o) => o.id)];
  }

  getRelations(key: string): RelationDescriptor[] {
    if (key === 'self') {
      return this.relationDescriptors.filter((relation) => relation.model === this.currentModel);
    }
    if (key === 'other') {
      return this.relationDescriptors.filter((relation) => relation.model !== this.currentModel);
    }
  }
}
