import { Pipe, PipeTransform } from '@angular/core';
import { TableColumn } from '../../../../@vex/interfaces/table-column.interface';

@Pipe({
  name: 'filterFields',
})
export class FilterFieldsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): TableColumn<any>[] {
    // eslint-disable-next-line no-prototype-builtins
    return value.filter((item) => item.hasOwnProperty('filterSettings'));
  }
}
