import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService } from '../../services/companies/companies.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormControl } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { CreateCompanyDialogComponent } from '../create-company-dialog/create-company-dialog.component';
import { ICompanyEntity, IGetManyDefaultResponse } from '@ipnote/interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.states';
import { CompanySelect } from '../../../../store/actions/company.actions';
import { selectStateSelectedCompany } from '#selectors';
import { Observable } from 'rxjs';
import { EnumsService } from '../../services/enums/enums.service';
import { CompanyTypeEnum } from '@ipnote/enum';

interface CompaniesModalData {
  selected: string;
  disableSelectAll: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-companies-modal',
  templateUrl: './companies-modal.component.html',
  styleUrls: ['./companies-modal.component.scss']
})
export class CompaniesModalComponent {
  dataSet: IGetManyDefaultResponse<ICompanyEntity>;
  searchControl: UntypedFormControl = new UntypedFormControl();
  pageSize = 6;
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompaniesModalData,
    private dialogRef: MatDialogRef<CompaniesModalComponent>,
    private matDialog: MatDialog,
    private companiesService: CompaniesService,
    private store: Store<AppState>,
    public enums: EnumsService
  ) {
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe(company => {
      this.selectedCompany = company;
    });
    this.companiesService.getAll(1, 6, null, ['name'], 'name').subscribe((res) => (this.dataSet = res));
    this.searchControl.valueChanges
      .pipe(
        switchMap((t) => this.companiesService.getAll(1, 6, t, ['name'], 'name')),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        (this.dataSet = res);
      });
  }

  pageChanged($event): void {
    this.companiesService
      .getAll($event.pageIndex + 1, this.pageSize, this.searchControl.value, ['name'], 'name')
      .pipe(untilDestroyed(this))
      .subscribe((res) => (this.dataSet = res));
  }

  select(company: ICompanyEntity): void {
    this.store.dispatch(CompanySelect({ company, thisIsNew: false, reloadPage: true }));
    this.dialogRef.close(company);
  }

  selectAll(company: Partial<ICompanyEntity> | { id: '*' }): void {
    this.dialogRef.close(company);
  }


  async createCompany(): Promise<void> {
    const company = await this.matDialog.open(CreateCompanyDialogComponent, {
      data: { companyType: this.selectedCompany.type }
    }).afterClosed().toPromise();
    this.dialogRef.close(company);
  }
}
