<div class="need-help p-4 flex mb-6">
  <div class="flex-auto">
    <div class="flex items-center">
      <div class="need-help__title font-bold text-designSystem-dark-spell">Get expert assistance</div>
      <div
        class="flex-shrink-0 cursor-pointer hover:bg-primary-500 text-xs ml-1 w-4 h-4 need-help__icon rounded-full flex items-center justify-center"
        [matTooltip]="
          'Formulating a task accurately can increase response rates by 56% and reduce deal closing time by 3 times.'
        "
      >
        <div class="text-white">?</div>
      </div>
    </div>
    <div class="text-sm mt-1 need-help__text">
      Our manager will handle your task for just <span class="font-bold">$99</span>
    </div>
  </div>
  <div>
    <button
      mat-button
      class="text-gray-600"
      *ngIf="!value"
      (click)="updateValue({ stripeInvoiceId: null, url: null, paymentDate: null })"
    >
      <mat-icon class="need-help__toggle">toggle_off</mat-icon>
    </button>
    <button mat-button stopPrevent class="text-primary-500" *ngIf="value" (click)="updateValue(null)">
      <mat-icon class="need-help__toggle">toggle_on</mat-icon>
    </button>
  </div>
</div>
