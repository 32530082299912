import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboarding-video',
  templateUrl: './onboarding-video.component.html',
  styleUrls: ['./onboarding-video.component.scss']
})
export class OnboardingVideoComponent implements OnInit {
  @Input()
  set id(id: string) {
    if (this._id) {
      this._id = null;
      this.cd.detectChanges();
    }
    this._id = id;
  }

  @Input()
  title: string;
  _id: string;

  constructor(public cd: ChangeDetectorRef) {
  }

  ngOnInit() {
  }
}
