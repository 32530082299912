import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-icon',
  templateUrl: './file-icon.component.svg',
  styleUrls: ['./file-icon.component.scss']
})
export class FileIconComponent {
  @Input() fill: string;

}
