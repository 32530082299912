import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'relation'
})
export class RelationPipe implements PipeTransform {
  constructor(public transloco: TranslocoService) {
  }

  transform(value: any, ...args: any[]): any {
    const entity = value.fromModel === 'Task' ? value.toModel : value.fromModel;
    const obj = value.fromModel === 'Task' ? value.to : value.from;

    return `${this.transloco.translate(entity)}: ${obj.name}`;
  }
}
