import { AppState } from '#appState';
import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectStateSelectedCompany, selectStateUserInfo } from '#selectors';
import { ICompanyEntity, IUserSignInRes } from '@ipnote/interface';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserProfileUpdate } from '../../../store/actions/user.actions';

@UntilDestroy()
@Component({
  selector: 'app-onboarding-preview-mobile',
  templateUrl: './onboarding-preview-mobile.component.html',
  styleUrls: [ './onboarding-preview-mobile.component.scss' ],
})
export class OnboardingPreviewMobileComponent implements OnInit {
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;
  user$: Observable<IUserSignInRes> = this.store.select(selectStateUserInfo);
  user: IUserSignInRes;
  innerWidth
  paddingBody = 27
  @Output() closeMobilePreviewHandler = new EventEmitter<boolean>()

  @HostListener('window:resize', [ '$event' ])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  constructor(private store: Store<AppState>) {
    this.user$.pipe(untilDestroyed(this)).subscribe(user => {
      this.user = user
    })
    this.selectedCompany$.subscribe(company => {
      this.selectedCompany = company;
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  close() {
    this.store.dispatch(UserProfileUpdate({
      payload: {
        settings: {
          ...this.user.settings,
          showedMobilePreview: true,
        }
      }
    }));
    this.closeMobilePreviewHandler.emit(false)
  }
}
