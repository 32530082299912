import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../services/base-url/base-url.service';
import { finalize, flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SpinnerViewService } from '../../services/spinner/spinner-view.service';
import { FileUpdateDto, FileUploadResDto } from '@ipnote/dto';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  filePatch = 'file-manager';
  folderFilesPatch = 'folder-files';

  constructor(
    public http: HttpClient,
    public baseUrl: BaseUrlService,
    private spinnerService: SpinnerViewService
  ) {
  }

  updateFolderFiles(id: number, body: FileUpdateDto): Observable<FileUploadResDto> {
    return this.baseUrl.getBaseUrl(`${this.folderFilesPatch}/${id}`).pipe(flatMap((url) => this.http.patch<FileUploadResDto>(url, body)));
  }

  deleteFolderFiles(id: number) {
    return this.baseUrl.getBaseUrl(`${this.folderFilesPatch}/${id}`).pipe(flatMap((url) => this.http.delete(url)));
  }

  updateFile(id: number, body: FileUpdateDto): Observable<FileUploadResDto> {
    this.spinnerService.start();
    return this.baseUrl.getBaseUrl(`${this.filePatch}/${id}`).pipe(
      flatMap((url) => this.http.patch<FileUploadResDto>(url, body)),
      finalize(() => this.spinnerService.stop())
    );
  }

  deleteFile(id: number) {
    return this.baseUrl.getBaseUrl(`${this.filePatch}/${id}`).pipe(flatMap((url) => this.http.delete(url)));
  }

}
