import { IpFeesCurrency } from '../enums/ip-fees-currency.enum';
import { Jurisdiction } from '../enums/jurisdiction.enum';

export interface ICalculateIpFeesBody {
  jurisdictions: Jurisdiction | Jurisdiction[];
  targetCurrency: IpFeesCurrency;
  parameters: ICalculateBodyParameterItem[];
}

export interface ICalculateBodyParameterItem {
  type: 'String' | 'Number' | 'Boolean';
  name: string;
  value: string;
}
