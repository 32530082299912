import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../base-url/base-url.service';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { INormalizedIpRight } from '@ipnote/interface';

@Injectable({
  providedIn: 'root',
})
export class SearchByPatentService {
  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {}
  searchByNumber(number: string): Observable<INormalizedIpRight[]> {
    return this.baseUrl.getBaseUrl('patseer/normalized-patents').pipe(
      map((url) => `${url}/${number}`),
      mergeMap((url) => this.http.get<INormalizedIpRight[]>(url)),
      map((res) => res),
    );
  }
}
