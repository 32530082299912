import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gravatar'
})
export class GravatarPipe implements PipeTransform {
  transform(value: string): string {
    return `https://www.gravatar.com/avatar/${value}`;
  }
}
