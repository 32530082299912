import { Component, HostListener, Inject, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppState } from '#appState';
import { selectStateSelectedCompany } from '#selectors';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateUser } from '../../../store/reducers/user';
import { selectStateUser } from '../../../store/selectors/user.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CompaniesModalComponent } from '../../../page-modules/desk/components/companies-modal/companies-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PublicPageComponent } from '../../../page-modules/public-page/public-page.component';
import { ICompanyEntity } from '@ipnote/interface';
import { PublicPageSteps } from '@ipnote/enum';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  headerFixed = false;
  animatedMenuOpened = false;
  public userState$: Observable<StateUser> = this.store.select(selectStateUser);
  userState: StateUser;
  public selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  selectedCompany: ICompanyEntity;
  steps = PublicPageSteps;

  constructor(@Inject(DOCUMENT) private document: Document,
              private store: Store<AppState>,
              private matDialog: MatDialog,
              @Optional() private publicPageComponent: PublicPageComponent) {
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe(company => {
      this.selectedCompany = company;
    });
    this.userState$.pipe(untilDestroyed(this)).subscribe(us => this.userState = us);
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.headerFixed = document.body.scrollTop > 30 || document.documentElement.scrollTop > 30;
  }

  openAnimatedMenu(): void {
    this.animatedMenuOpened = !this.animatedMenuOpened;
  }

  async changeCompany(currentCompany: ICompanyEntity): Promise<void> {
    const newCompany: ICompanyEntity = await this.matDialog
      .open(CompaniesModalComponent, {
        data: { selected: currentCompany.id, disableSelectAll: false }
      })
      .afterClosed()
      .toPromise();
  }

  openSideNav(step: PublicPageSteps) {
    this.publicPageComponent.openSideNav(step);
  }
}
