import { Component, Input, OnInit } from '@angular/core';
import { groupBy } from 'lodash';
import { Diff } from '../../dataset/Diff';

@Component({
  selector: 'app-diffs',
  templateUrl: './diffs.component.html',
  styleUrls: ['./diffs.component.scss']
})
export class DiffsComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  private _diffs: Diff[];
  @Input() set diffs(diffs: Diff[]) {
    this._diffs = diffs;
    this.diffsMap = groupBy(diffs, (d) => d.type);
  }

  get diffs(): Diff[] {
    return this._diffs;
  }

  diffsMap: {
    update?: Diff[];
    add?: Diff[];
    delete?: Diff[];
    [action: string]: Diff[];
  };

  constructor() {
  }

  ngOnInit() {
  }
}
