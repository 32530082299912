<img
  *ngIf="(userStore$ | async)"
  matTooltip="{{ 'profile' | transloco }}"
  class="h-10 w-10 object-cover rounded-full"
  (click)="menuTrigger.openMenu()"
  src="{{(userStore$ | async).avatar ? (userStore$ | async).avatar : 'assets/img/avatar-empty.svg'}}" />

<button
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="actionsMenu"
  matTooltip="{{ 'profile-actions' | transloco }}" color="primary">
</button>

<mat-menu #actionsMenu="matMenu" xPosition="before">
  <a routerLink="{{'/desk/' + companyId + '/profile/info'}}" target="{{marketPlace ? '_blank' : '_self'}}"
     mat-menu-item>
    <mat-icon [icIcon]="icons.icAccount"></mat-icon>
    {{ "my-profile" | transloco }}</a>
  <a (click)="logout()" mat-menu-item>
    <mat-icon [icIcon]="icons.icLogout"></mat-icon>
    {{ "logout" | transloco }}</a>
</mat-menu>
