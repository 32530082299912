export const PDF_DISCLAMER = `
This search relies exclusively on publicly accessible data provided by patent offices up to the date of the search. We base our results on the assumption that all such records are correctly recorded and indexed. We do not accept responsibility for:
<br>
(i) Undisclosed records,
<br>
(ii) Misindexed or misrecorded records,
<br>
(iii) Records released after the search date.
<br>
While we ensure the highest diligence in conducting our searches and services, we cannot guarantee the completeness or accuracy of our results. We disclaim all warranties, both express and implied, beyond this. Any liability from our search services will only extend to a refund of the fee paid.
`
