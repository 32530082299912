import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService } from '../../../../page-modules/desk/services/companies/companies.service';
import { CompanySelect } from '../../../../store/actions/company.actions';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { ICompanyEntity } from '@ipnote/interface';
import { selectStateSelectedCompany } from '#selectors';
import { DialogService } from '../../../services/dialogs/dialog.service';
import { Observable } from 'rxjs';
import { expandProviderOnboardingPanel } from '../../../../store/actions/onboarding-provider-panel.actions';
import { isAllOnboardingStepsDone } from '../provider-onboarding-panel/provider-onboarding-panel.component';
import { AnalyticsService } from '../../../services/analytics/analytics.service';
import { AnalyticsEventEnum } from '@ipnote/enum';

@Component({
  selector: 'app-share-link-dialog',
  templateUrl: './share-link-dialog.component.html',
  styleUrls: ['./share-link-dialog.component.scss']
})
export class ShareLinkDialogComponent implements OnInit {

  company$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  company: ICompanyEntity;

  constructor(public dialogRef: MatDialogRef<ShareLinkDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private companiesService: CompaniesService,
              private store: Store<AppState>,
              private dialogs: DialogService,
              private analyticsService: AnalyticsService) {
  }

  ngOnInit() {
    this.store.select(selectStateSelectedCompany).subscribe(company => {
      this.company = company;

    });
  }

  sharePost() {
    window.open('https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fwww.linkedin.com%2Fposts%2Fipnote_ipnote-the-largest-ip-marketplace-in-the-activity-6957386075547824129-xirN%2F%3Futm_source%3Dlinkedin_share%26utm_medium%3Dmember_desktop_web');
    this.dialogRef.close(true);
    this.companiesService.update(this.company.id, {
      onboardingSteps: {
        id: this.company.onboardingSteps?.id,
        isInformationShared: true
      }
    }).pipe(
      filter(p => !!p)
    ).subscribe(
      (company) => {
        const copiedCompany = JSON.parse(JSON.stringify(this.company));
        copiedCompany.onboardingSteps.isInformationShared = true;
        if (isAllOnboardingStepsDone && !this.company.onboardingSteps.isInformationShared) {
          this.analyticsService.sendEvent(AnalyticsEventEnum.ONBOARDING_COMPLETED, {});
        }
        this.store.dispatch(CompanySelect({ company: copiedCompany }));
        this.store.dispatch(expandProviderOnboardingPanel());

      },
      (error) => this.dialogs.error(error));
  }
}
