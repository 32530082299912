<table [dataSource]="tasks" mat-table *ngIf="tasks && tasks.length; else elseBlock">
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>{{ 'task' | transloco }}</th>
    <td mat-cell *matCellDef="let el"> {{el.title}}</td>
  </ng-container>
  <ng-container matColumnDef="responsible">
    <th mat-header-cell *matHeaderCellDef>{{ 'responsible' | transloco }}</th>
    <td mat-cell *matCellDef="let el"> {{el.responsible}}</td>
  </ng-container>
  <ng-container matColumnDef="dateEnd">
    <th mat-header-cell *matHeaderCellDef>{{ 'deadline' | transloco }}</th>
    <td mat-cell
        *matCellDef="let el"> {{el.dateEnd | date: dateFormatService.getFormat() : null : dateFormatService.getLocale()}}</td>
  </ng-container>
  <ng-container matColumnDef="internalExecutor">
    <th mat-header-cell *matHeaderCellDef>{{ 'service-provider' | transloco }}</th>
    <td mat-cell *matCellDef="let el"> {{el.internalExecutor}}</td>
  </ng-container>
  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>{{ 'cost' | transloco }}</th>
    <td mat-cell *matCellDef="let el"> {{el.amount}}</td>
  </ng-container>
  <ng-container matColumnDef="stage">
    <th mat-header-cell *matHeaderCellDef>{{ 'status' | transloco }}</th>
    <td mat-cell *matCellDef="let el" [ngClass]="el.stage"> {{ el.stage | transloco }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<ng-template #elseBlock>{{ 'no-tasks' | transloco }}</ng-template>
