import { Inject, Pipe, PipeTransform } from '@angular/core';
import { COUNTRIES_DATA } from '../../../../countries/countries';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {
  constructor(@Inject(COUNTRIES_DATA) private countries: { [locale: string]: string }) {
  }

  transform(value: string): string {
    return this.countries[value];
  }
}
