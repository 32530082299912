import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileManagerService } from '../../../../../app-common/services/file-manager/file-manager.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { TaskResultFolderTypes, TaskResultTagsFilesTypes } from '@ipnote/type';
import { TasksService } from '../../../services/tasks/tasks.service';

@Component({
  selector: 'app-reopen-task',
  templateUrl: './reopen-task.component.html',
  styleUrls: ['./reopen-task.component.scss']
})
export class ReopenTaskComponent implements OnInit {
  formSaveInProcess = false;
  files: File[] = [];
  type = TaskResultFolderTypes.OTHER;
  subType = TaskResultTagsFilesTypes.REJECTED;
  form = this.fb.group({
    description: [null]
  });

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { taskId: number },
    private uploadService: FileManagerService,
    private dialogRef: MatDialogRef<ReopenTaskComponent>,
    private tasksService: TasksService
  ) {
  }

  ngOnInit() {
  }

  handleFile(event: Event): void {
    // if (event.target.files && event.target.files.length) {
    //   this.files.push(...event.target.files);
    // }
    // event.target.value = '';

    const target = (event.target || event.srcElement) as HTMLInputElement;
    this.files = Array.from(target.files);
    target.value = '';
  }

  deleteFile(index: number): void {
    this.files.splice(index, 1);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  async send(): Promise<any> {
    this.formSaveInProcess = true;
    if (this.files.length) {
      // await Promise.all(
      //   this.file.map(async (f: File) => {
      //     const url = await this.uploadService.upload(f).toPromise();
      //
      //     files.push({
      //       url,
      //       fileItemType: 'file',
      //       name: f.name,
      //       type: f.type,
      //       size: f.size,
      //       createdAt: new Date(),
      //     });
      //   })
      // );
    }
    const { description } = this.form.value || ' ';
    const res = await this.tasksService
      .reject(this.data.taskId, [this.type, this.subType], description, this.files)
      .toPromise();
    this.formSaveInProcess = true;
    this.dialogRef.close(res);
  }
}
