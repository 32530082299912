<div class="title">
  <h2 mat-dialog-title>{{'send-rework' | transloco}}</h2>
  <button class="button-close" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field>
      <mat-label>{{'description' | transloco}}</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </form>
  <div class="offer-price-files" *ngIf="files && files.length">
    <div class="file" *ngFor="let f of files; let i = index">
      <span>{{f?.name}}</span>
      <button (click)="deleteFile(i)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="offer-price-file-actions">
    <button type="button" class="file-button" (click)="fileInput.click()">
      {{'attach-file' | transloco}}
    </button>
    <div class="offer-file-info">{{'max-file' | transloco}}</div>
  </div>
  <input [style.display]="'none'" #fileInput type="file" multiple (change)="handleFile($event)">
</mat-dialog-content>
<mat-dialog-actions class="pt-6">
  <div class="space"></div>
  <button mat-raised-button color="primary" class="send-button" (click)="send()"
          [disabled]="form.invalid || formSaveInProcess">{{'send-rework'|transloco}}
  </button>
</mat-dialog-actions>
