import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileManagerService } from '../../services/file-manager/file-manager.service';
import { finalize, map } from 'rxjs/operators';
import { FileType } from '../../dataset/FileType';
import { combineLatest } from 'rxjs';
import { IFileEntity } from '@ipnote/interface';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;

  @Input() set multiple(multiple: boolean) {
    this._multiple = multiple;
  }

  get multiple(): boolean {
    return this._multiple;
  }

  private $fileTypes: FileType[];
  // tslint:disable-next-line:variable-name
  private _multiple = true;

  @Input() set fileTypes(fileTypes: FileType[]) {
    this.$fileTypes = fileTypes;
  }

  get fileTypes(): FileType[] {
    return this.$fileTypes;
  }

  @Input() privateFiles = null;
  @Input() disabled: boolean;
  @Input() shortLink = false;

  type: string;
  subType: string;
  file: File;
  uploading = false;
  error: Error;

  @Output() newFile = new EventEmitter<any[] | IFileEntity[]>();

  constructor(private uploadService: FileManagerService) {
  }

  ngOnInit(): void {
  }

  selectFileWithType(type: string, subType: string, $event: MouseEvent): void {
    this.type = type;
    this.subType = subType;
    this.fileInput.nativeElement.click();
  }

  selectFile($event: MouseEvent): void {
    if (this.error) {
      this.error = null;
    }
    this.fileInput.nativeElement.click();
  }

  processFile($event: Event): void {
    // tslint:disable-next-line:deprecation
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const files = Array.from(target.files);

    target.value = '';
    this.uploading = true;
    const { type, subType } = this;

    const uploads = files.map((f) => {
      if (this.privateFiles) {
        // @ts-ignore
        return this.uploadService.uploadPrivate(this.privateFiles.model, this.privateFiles.modelId, f, [type, subType]).pipe();
      } else {
        return this.uploadService.upload(f).pipe(
          map((url) => ({
            url,
            fileItemType: 'file',
            name: f.name,
            type: f.type,
            size: f.size,
            createdAt: new Date()
          }))
        );
      }
    });

    combineLatest(uploads)
      .pipe(finalize(() => (this.uploading = false)))
      .subscribe(
        (f) => {
          this.newFile.emit(f);
          this.reset();
        },
        (err) => {
          this.error = err;
          this.reset();
        }
      );
  }

  reset(): void {
    this.file = null;
    this.type = null;
    this.subType = null;
  }

  uploadClick($event: MouseEvent): void {
    if (this.error) {
      this.error = null;
    }
  }
}
