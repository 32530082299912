export interface PatseerSemanticSearchResponse {
  SearchOutputCollection: SearchOutputItem[];
  TotalResults: number;
}

export interface SearchOutputItem {
  PN: string;
  KC: string;
  PNKC: string;
  PBC: string;
  T: string;
  A: string;
  PBD: string;
  APD: string;
  APN: string;
  ASNO: string[];
  ASNN: string[];
  ICR: string[];
  INV: string[];
  CPC?: string[];
  ATN?: string[];
  Score: number;
  PAT_LINK: string;
}

export type SemanticSearchResponse = Pick<
  SearchOutputItem,
  'PN' | 'PBC' | 'APD' | 'T' | 'A' | 'Score' | 'ASNO' | 'PAT_LINK'
>[];

export interface PatseerGetPatentResponse {
  OutputItem: PatseerPatent;
}

export interface PatseerPatent {
  PN?: string;
  KC?: string;
  T?: string;
  A?: string;
  PBD?: string;
  APD?: string;
  APN?: string;
  ASNO?: string[];
  ASNN?: string[];
  ICR?: string[];
  INV?: string[];
  PRN?: string[];
  PRD?: string[];
  CPC?: string[];
  EPD?: string;
  C?: string;
  FPD?: string;
  FAMID?: string;
  NDR?: number;
  EPRD?: string;
  LANG?: string;
  PAT_LINK?: string;
  NPG?: number;
  ATN?: string | string[];
  LSC?: string;
  EED?: string;
}

export interface PatseerSearchResultResponse {
  SearchOutputCollection?: SearchOutputCollectionItem[];
  TotalResults?: number;
}

export interface SearchOutputCollectionItem {
  PN: string;
  PBD: string;
  APD: string;
  T: string;
  KC?: string;
  PBC?: string;
  A?: string;
  CPC?: string[];
  UC?: string[];
  ASNO?: string[];
  ASNN?: string[];
  ICR?: string[];
  PRN?: string[];
  PRD?: string[];
  INV?: string[];
  LST?: string;
  LS?: string;
}
