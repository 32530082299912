<div class="chips-bar" *ngIf="filterChips?.length">
  <mat-chip-list class="chips" *ngFor="let chip of visibleChips">
    <mat-chip>{{chip}}</mat-chip>
  </mat-chip-list>
  <ng-container *ngIf="filterChips.length > 3">
    <span>...</span>
    <button mat-chip (click)="toggleAllChips()" #toggleButton>{{allChips.length}}</button>
    <div class="all-chips" #menu [class.visible]="allChipsState">
      <div class="all-chips-container">
        <div class="chip" *ngFor="let chip of allChips">
          <mat-chip>{{chip}}</mat-chip>
        </div>
      </div>
    </div>
  </ng-container>
  <button (click)="resetFilter()" mat-button class="reset-button">{{'reset' | transloco}}</button>
</div>
