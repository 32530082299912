import { TaskFlowDialogComponent } from '../task-flow-dialog/task-flow-dialog.component';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ICompanyEntity, IUserEntity } from '@ipnote/interface';

@Component({
  selector: 'app-choice-executor-button',
  templateUrl: './choice-executor-button.component.html',
  styleUrls: ['./choice-executor-button.component.scss']
})
export class ChoiceExecutorButtonComponent implements OnInit {
  @ViewChild(TaskFlowDialogComponent, { static: true }) dropdown: TaskFlowDialogComponent;
  @ViewChild('ref') ref: ElementRef<HTMLElement>;

  @Input() executors: ICompanyEntity[];
  // @Input() collaborators: IUserEntity[];
  @Input() buttonStyle = 'card-action';
  @Input() color = 'blue';
  @Input() disabled = false;
  @Input() executorPlaceholder: string;
  @Input() nameKey = 'choice-author';

  // @Output() saveInternal = new EventEmitter<number>();
  @Output() savePotential = new EventEmitter<any[]>();

  activeTab = 'potential';
  internalExecutor: IUserEntity = null;
  selectedExecutors: ICompanyEntity[] = [];

  constructor() {
  }

  ngOnInit(): void {
    if (!this.executorPlaceholder) this.executorPlaceholder = 'service-provider';
  }

  switchTab(type: string): void {
    if (this.activeTab === type) return;
    this.activeTab = type;
  }

  checkboxSelect(executor: ICompanyEntity): void {
    const index = this.selectedExecutors.findIndex((e) => e.id === executor.id);

    index >= 0 ? this.selectedExecutors.splice(index, 1) : this.selectedExecutors.push(executor);
  }

  alreadyIn(executor: ICompanyEntity): boolean {
    return !!this.selectedExecutors.find((e) => e.id === executor.id);
  }

  handleSavePotential(executors: ICompanyEntity[]): void {
    this.internalExecutor = null;
    this.savePotential.emit(executors);
    this.dropdown.hide();
  }

  // handleSaveInternal(id: number): void {
  //   this.selectedExecutors = [];
  //   this.saveInternal.emit(id);
  //   this.dropdown.hide();
  // }
  //
  public showDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.dropdown.show();
  }
}
