import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[stopPrevent]'
})
export class StopPreventDirective {
  @HostListener('click', ['$event'])
  public onClick($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
  }
}
