<button #ref (click)="showDropdown($event)" [ngClass]="[color, buttonStyle]" [disabled]="disabled"
        style="position: relative">
  {{buttonStyle === 'select-style' ? (executorPlaceholder | transloco) : (nameKey | transloco)}} <span
  class="mat-select-arrow arrow-position"></span>
</button>

<app-task-flow-dialog [reference]="ref">
  <div class="dropdown-panel" vexScrollbar>
    <div class="author-title">{{'choice-author' | transloco}}</div>
    <div class="author-tabs">
      <button (click)="switchTab('potential')" [class.active]="activeTab === 'potential'">
        {{'applicants' | transloco}}
      </button>
    </div>
    <ng-container *ngIf="activeTab === 'potential'">
      <div class="potential-executors">
        <div class="potential-executor__item" *ngFor="let executor of executors">
          <mat-checkbox color="primary"
                        (change)="checkboxSelect(executor)"
                        [checked]="alreadyIn(executor)">
            <span class="internal_executor_content">{{executor.name}}</span>
          </mat-checkbox>
          <!--<div class="potential-item__label">
            <mat-icon>watch_later</mat-icon>
            {{'recently-ordered' | transloco}}
          </div>-->
        </div>
        <button class="potential-button" (click)="handleSavePotential(selectedExecutors)"
                [disabled]="!selectedExecutors.length">
          {{'request-a-quote' | transloco}}
        </button>
      </div>
    </ng-container>
  </div>
</app-task-flow-dialog>
