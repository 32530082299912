<div class="bg-card px-4"
     fxFlex
     fxFlex.lt-md="auto"
     fxLayout="row"
     fxLayoutAlign="start center">
  <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
  <input [formControl]="searchCtrl"
         (focusin)="isFocus = true"
         (focusout)="isFocus = false"
         class="border-0 outline-none w-full bg-card"
         placeholder="{{ 'search_files' | transloco }}"
         type="text">
  <mat-icon class="reset" (click)="reset($event)" *ngIf="searchCtrl.value">close</mat-icon>
</div>
