import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import {
  RequestQueryBuilderService
} from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { Observable } from 'rxjs';
import {
  IGetManyDefaultResponse,
  IReminderEntity,
  RequestCreatingReminderFromFileInterface,
  ResponseCreatingReminderFromFileInterface
} from '@ipnote/interface';
import { concatLatestFrom } from '@ngrx/effects';
import { flatMap, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RemindersService {

  constructor(private http: HttpClient, private baseUrl: BaseUrlService, private queryBuilder: RequestQueryBuilderService) {
  }

  getAll(
    pageIndex: number = 1,
    pageSize: number = 10,
    searchToken?: string,
    searchFields?: string[],
    sort?: string,
    filter?: any,
    additionalOptions?: object,
    relations?: string[]
  ): Observable<IGetManyDefaultResponse<IReminderEntity>> {
    return this.baseUrl.getBaseUrl('reminders').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
            pageIndex,
            pageSize,
            searchToken,
            searchFields,
            sort,
            filter,
            additionalOptions,
            relations
          }
        )
      ]),
      mergeMap(([url, params]) => this.http.get<IGetManyDefaultResponse<IReminderEntity>>(url, { params: { ...params } }))
    );
  }

  create(body: any): Observable<IReminderEntity> {
    return this.baseUrl.getBaseUrl('reminders').pipe(mergeMap((url) => this.http.post<IReminderEntity>(url, body))
    );
  }

  get(id: any): Observable<IReminderEntity> {
    return this.baseUrl.getBaseUrl(`reminders/${id}`).pipe(
      mergeMap((url) => this.http.get<IReminderEntity>(url))
    );
  }

  update(body: any, id: any): Observable<IReminderEntity> {
    return this.baseUrl.getBaseUrl(`reminders/${id}`).pipe(mergeMap((url) => this.http.patch<IReminderEntity>(url, body))
    );
  }

  openReminder(id: number) {
    return this.baseUrl.getBaseUrl(`reminders/${id}/open`).pipe(mergeMap((url) => this.http.patch<IReminderEntity>(url, {}))
    );
  }

  close(
    id: number,
    tags?: string[],
    rejectedDescription?: string,
    files?: File[],
    folderFileName?: any,
    comment?: string,
    oipId?: number | null,
    oipName?: string,
    requestNumber?: string,
    requestDate?: string,
    priorityNumber?: string,
    priorityDate?: string
  ): Observable<IReminderEntity> {
    const formData = new FormData();
    if (files.length > 0) {
      files.forEach(file => {
        formData.append('files', file, file?.name);
      });
    }
    if (rejectedDescription) formData.append('resultDescription', rejectedDescription);
    if (tags) formData.append('tags', JSON.stringify(tags));
    if (comment) formData.append('comment', comment);
    if (oipId) formData.append('oipId', String(oipId));

    /** Filing application */
    if (oipName) formData.append('oipName', oipName);
    if (requestNumber) formData.append('requestNumber', requestNumber);
    if (requestDate) formData.append('requestDate', requestDate);
    if (priorityNumber) formData.append('priorityNumber', priorityNumber);
    if (priorityDate) formData.append('priorityDate', priorityDate);
    if (folderFileName) formData.append('folderFileName', folderFileName);

    return this.baseUrl.getBaseUrl(`reminders/${id}/close`).pipe(
      flatMap((url) => this.http.patch<IReminderEntity>(url, formData))
    );
  }

  delete(id: number) {
    return this.baseUrl.getBaseUrl(`reminders/${id}`).pipe(mergeMap((url) => this.http.delete<IReminderEntity>(url)));
  }

  creatingReminderFromFile(body: RequestCreatingReminderFromFileInterface): Observable<ResponseCreatingReminderFromFileInterface> {
    return this.baseUrl.getBaseUrl('reminders/creation-from-file').pipe(
      flatMap((url) => this.http.post<ResponseCreatingReminderFromFileInterface>(url, body))
    );
  }
}
