<mat-dialog-content class="!min-w-0 sm:!min-w-[500px]">
  <button class="button-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div
    class="offer-price-title">{{'user_rating_title' | transloco: { name: data.task?.serviceRequests[0]?.executorCompany?.name } }}</div>
  <div class="stars">
    <span *ngFor="let rating of userRatingArr">
      <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"
           (click)="setUserRating(rating)">
        <path [ngClass]="{'selected-color': userRating &&  rating <= userRating }" fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.52971 22C5.20681 22.0006 4.8918 21.9027 4.62866 21.7199C4.12112 21.3669 3.89226 20.7428 4.05499 20.1555L5.80002 13.9342L0.567944 9.88447C0.0769957 9.50839 -0.120469 8.87257 0.0738701 8.2936C0.273479 7.7128 0.818155 7.31147 1.44496 7.28337L8.17128 6.94906L10.5741 0.937487C10.8079 0.371009 11.3707 0 11.9962 0C12.6218 0 13.1846 0.371009 13.4184 0.937487L15.8302 6.94906L22.555 7.28337C23.1816 7.31188 23.7261 7.71305 23.9261 8.2936C24.1203 8.87227 23.9235 9.50776 23.4335 9.88447L18.2104 13.9284L19.957 20.1496C20.1198 20.7372 19.8903 21.3617 19.3818 21.7141C18.8693 22.0698 18.1871 22.0872 17.6563 21.7581L12.0067 18.2391L6.35717 21.7581C6.11079 21.9145 5.82357 21.9984 5.52971 22Z"
              fill="#DAE2EE" />
      </svg>
    </span>
  </div>
  <div class="sub-stars">
    <div>{{'user_rating_negative' | transloco}}</div>
    <div>{{'user_rating_positive' | transloco}}</div>
  </div>
  <form [formGroup]="form">
    <mat-form-field class="no-auto-margin-bottom">
      <mat-label>{{'user_rating_description' | transloco}}</mat-label>
      <textarea matInput formControlName="text" rows="4"
                [placeholder]="('user_rating_placeholder') | transloco"></textarea>
    </mat-form-field>
  </form>
  <button mat-raised-button color="primary" class="send-button" (click)="send()"
          [disabled]="form.invalid || !userRating">{{'send'|transloco}}</button>
</mat-dialog-content>

