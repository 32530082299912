import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ResetFilterService {
  constructor(private router: Router) {
  }

  async reset(params: Params, route: ActivatedRoute) {
    const keys = Object.keys(params).filter((k) => k.startsWith('_f_') || k.startsWith('_r_') || k.includes('$gte') || k.includes('$lte') || k.includes('$in') || k.includes('$contL'));
    const queryParams = omit(params, keys);
    await this.router.navigate([], {
      replaceUrl: true,
      relativeTo: route,
      queryParams
    });
  }
}
