<button class="card-action" #ref (click)="showDropdown($event)" [class.white]="color === 'white'">
  {{'choice-service' | transloco}}
</button>

<app-task-flow-dialog [reference]="ref" [position]="position">
  <div class="dropdown-panel" vexScrollbar>
    <div class="service-title">{{ 'choice-service' | transloco}}</div>
    <div *ngFor="let category of getCategories()">
      <div class="category">{{category.name | transloco}}</div>
      <ng-container *ngFor="let service of getCategoryServices(category)">
        <div class="service-item"
             (click)="choiceService(service)"
             [class.active]="activeService === service.id">
          {{service.name | transloco}}
        </div>
      </ng-container>
    </div>
  </div>
</app-task-flow-dialog>
