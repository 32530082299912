<div class="wrapper" [ngClass]="{'cancel-padding': !panelOpenState}">
  <mat-accordion>
    <mat-expansion-panel class="expension-panel"
                         (opened)="expandPanel()"
                         (closed)="collapsePanel()"
                         [expanded]="panelOpenState === true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title" [innerHTML]="getTitle()"></div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="line1">
        <div class="title-text" *ngIf="!isAllOnboardingStepsDone && !isAnyOnboardingStepsDone">Complete these signup
          tasks to start using your new tools and<b> gain a 30% discount on PRIME for the first 3 months.</b>
          <p>To activate the discount, simply complete each step below.</p></div>
        <div class="title-text" *ngIf="isAnyOnboardingStepsDone && !isAllOnboardingStepsDone">Let's continue discovering
          iPNOTE! Click on each step and follow the instructions.<p>Upon completion, the form will be reviewed and you
            will be rewarded a <b>30% Prime discount for 3 months.</b></p></div>
        <div class="title-text" *ngIf="isAllOnboardingStepsDone">Your account manager will review the details and guide
          you through the activation of your 30% PRIME discount for 3 months.
        </div>

      </div>
      <section class="checkbox-section">
        <div class="check-box">
          <mat-checkbox (click)="cancelCheckBox($event)"
                        [checked]="(company$ | async).onboardingSteps?.isCompanyPublicInfoCompleted">
            <span [ngClass]="{strike: (company$ | async).onboardingSteps?.isCompanyPublicInfoCompleted}">Complete your company profile</span>
          </mat-checkbox>
          <button (click)="runPublicProfileTour()" mat-raised-button
                  color="green">{{(company$ | async).onboardingSteps?.isCompanyPublicInfoCompleted ? 'DONE' : 'LET\'S GO' }}</button>
        </div>
        <div class="check-box">
          <mat-checkbox (click)="cancelCheckBox($event)"
                        [checked]="(company$ | async).onboardingSteps?.areServicesAdded">
            <span [ngClass]="{strike: (company$ | async).onboardingSteps?.areServicesAdded}">Add services to your profile</span>
          </mat-checkbox>
          <button (click)="runServicesTour()" mat-raised-button
                  color="green">{{(company$ | async).onboardingSteps?.areServicesAdded ? 'DONE' : 'LET\'S GO' }}</button>
        </div>
        <div class="check-box">
          <mat-checkbox (click)="cancelCheckBox($event)"
                        [checked]="(company$ | async).onboardingSteps?.isOutgoingTaskCreated">
            <span [ngClass]="{strike: (company$ | async).onboardingSteps?.isOutgoingTaskCreated}">Create your first outgoing task</span>
          </mat-checkbox>
          <button (click)="showNewTaskButton()" mat-raised-button
                  color="green">{{(company$ | async).onboardingSteps?.isOutgoingTaskCreated ? 'DONE' : 'LET\'S GO' }}</button>
        </div>
        <div class="check-box">
          <mat-checkbox (click)="cancelCheckBox($event)"
                        [checked]="(company$ | async).onboardingSteps?.isInformationShared">
            <span [ngClass]="{strike: (company$ | async).onboardingSteps?.isInformationShared}">Share information about iPNOTE</span>
          </mat-checkbox>
          <button (click)="showSharedLink()" mat-raised-button
                  color="green">{{((company$ | async).onboardingSteps?.isInformationShared) ? 'DONE' : 'LET\'S GO'}}</button>
        </div>

      </section>

    </mat-expansion-panel>
  </mat-accordion>
</div>


