import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateFormatService } from '../../../../@vex/services/date-format.service';
import { MatFormFieldAppearance } from '@angular/material/form-field';

const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY'
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@UntilDestroy()
@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (dateFormatService: DateFormatService) => dateFormatService.getLocale(),
      deps: [DateFormatService]
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
  ]
})
export class DateInputComponent implements OnInit, ControlValueAccessor, OnDestroy {
  private onChange: (value: any) => void;
  private onTouched: () => void;
  @Input() label: string;
  @Input() required: boolean;
  @Input() appearance: MatFormFieldAppearance;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() disabled: boolean;
  formControl: UntypedFormControl = new UntypedFormControl();

  constructor() {
  }

  ngOnInit() {
    this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (this.onChange) {
        this.onChange(value);
      }
    });
  }

  writeValue(obj: any): void {
    this.formControl.patchValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  ngOnDestroy(): void {
  }
}
