<div class="subs" *ngIf="currentSubs">
  <div fxFlex="30">
    <div fxLayout="100">
      <h3> {{subscriptionType?.title | transloco}} </h3>
      <div class="subs__row__label">
        <span>{{currentSubs?.dateEnd ? ('subscription_expiration_date' | transloco) + (currentSubs?.dateEnd | date: dateFormatService.getFormat() : null : dateFormatService.getLocale()) : 'Unlimited'}}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="subscriptionType" fxFlex="28" class="subs__row__desc">
    <div>
      <div class="green-dot"></div>
      <div class="text">
        {{subscriptionType?.allowedOipCount ?
        ('ois_inuse_subscription' | transloco: {
          count: currentSubs.oipsCount,
          allowedCount: subscriptionType.allowedOipCount
        }) :
        ('unlimited_quantity_ois' | transloco)
        }}
      </div>
    </div>
    <div>
      <div class="green-dot"></div>
      <div class="text">
        {{subscriptionType?.allowedCompanyCount ?
        ('company_inuse_subscription' | transloco: {
          count: currentSubs.companiesCount,
          allowedCount: subscriptionType.allowedCompanyCount
        }) :
        ('unlimited_companies' | transloco)
        }}
      </div>
    </div>
  </div>
  <div fxFlex="28" class="subs__row__desc">
    <div *ngFor="let description of subscriptionType?.description">
      <div class="green-dot"></div>
      <div class="text">{{ description | transloco: {
        fee: subscriptionType?.feeSize,
        minFeeSize: subscriptionType?.feeMinSize,
        minFeeCurrency: subscriptionType?.feeMinCurrency,
        incomingFee: subscriptionType?.incomingFeeSize
      } }}</div>
    </div>
    <div *ngIf="subscriptionType?.integration">
      <div class="green-dot"></div>
      <div class="text">{{'fips_integration' | transloco}}</div>
    </div>
    <div *ngIf='subscriptionType?.support && subscriptionType?.support !== "none"'>
      <div class="green-dot"></div>
      <div class="text">{{subscriptionType?.support | transloco}}</div>
    </div>
  </div>

  <div fxFlex="14" style="padding-left: 10px;">
    <div class="subs__row__price"> {{ price }} {{currency.toLowerCase() | transloco}} </div>
    <div class="subs__row__price__substr">{{'per_month' | transloco}}</div>
  </div>

  <!--<div *ngIf="!commonView" class="subs__row__openSelector" fxFlex="20" fxLayoutAlign="end">
    <button
      type="button"
      mat-button
      (click)="showDropdown($event)">
      <span class="subs__row__openSelector">Изменить тариф</span>
      <mat-icon *ngIf="!selectorIsOpen">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="selectorIsOpen">keyboard_arrow_up</mat-icon>
    </button>
  </div>-->
</div>
<!--<div class="subs__popperBody" *ngIf="selectorIsOpen">
  <div *ngFor="let sub of subscriptionsList"
       [ngClass]="sub.id == current.id ? 'subs__row__active subs__row': 'subs__row'"
  >
    <div fxFlex="25">
      <h3 fxLayout="100"> {{sub.title | transloco}} </h3>
      <div> {{ allowedCountStr(sub) }} </div>
    </div>

    <div fxFlex="45" class="subs__row__desc">
      <div *ngFor="let description of sub.description">
        {{ description | transloco }}
      </div>
    </div>

    <div fxFlex="12">
      <div class="subs__row__price">{{ sub.price | number: '1.0-2':"ru-RU" }} {{sub.currency?.toLowerCase() | transloco}} </div>
      <div class="subs__row__price__substr">в месяц</div>
    </div>

    <div fxFlex="20" fxLayoutAlign="end">
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="!sub.available"
        (click)="selectSubscription(sub)">
        <span class="subs__row__select">Выбрать тариф</span>
      </button>
    </div>
  </div>
</div>
<div class="divider"></div>-->
<!--<app-multi-select-dropdown [reference]="ref">
  <div class="subs__popperBody">
    <div *ngFor="let sub of subscriptionsList"
         [ngClass]="sub.id == current.id ? 'subs__row__active subs__row': 'subs__row'"
    >
      <div fxFlex="23">
        <h3 fxLayout="100"> {{sub.title}} </h3>
        <div> {{ allowedCountStr(sub) }} </div>
      </div>

      <div fxFlex="40" class="subs__row__desc">
        {{ sub.description[0] }}
      </div>

      <div fxFlex="10">
        <div class="subs__row__price"> {{sub.price }} &#8381; </div>
        <div class="subs__row__price__substr">в месяц</div>
      </div>

      <div fxFlex="15">
        <button
          type="button"
          mat-raised-button
          (click)="selectSubscription(sub)">
          <span class="subs__row__openSelector">Выбрать тариф</span>
        </button>
      </div>
    </div>
  </div>
</app-multi-select-dropdown>-->
