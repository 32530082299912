import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyTypeEnum } from '@ipnote/enum';
import { EnumsService } from '../../services/enums/enums.service';

@Component({
  selector: 'app-create-company-dialog',
  templateUrl: './create-company-dialog.component.html',
  styleUrls: ['./create-company-dialog.component.scss']
})
export class CreateCompanyDialogComponent {
  companyType: CompanyTypeEnum;

  constructor(public dialogRef: MatDialogRef<CreateCompanyDialogComponent>, public matDialogRef: MatDialogRef<CreateCompanyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public enums: EnumsService) {
    if (this.data?.companyType) {
      this.companyType = data.companyType;
    }
  }

  close() {
    if (!this.matDialogRef.disableClose) {
      this.matDialogRef.close();
    }
    this.dialogRef.close(true);
  }
}
