<button class="btn-sort mat-button-base mat-primary"
        stopPrevent
        [matMenuTriggerFor]="actionsMenu"
        type="button">
  <mat-icon [icIcon]="icSort"></mat-icon>
  <span>
    {{ordersSelect.nameTranslocoKey | transloco}}
  </span>
  <mat-icon [icIcon]="ordersSelect.order === 'ASC' ? icArrowUp : icArrowDown"
            stopPrevent
            (click)="changeDirectionSort(ordersSelect, $event)"
            [matTooltip]="'sort_direction_change' | transloco"
            class="direction-sort"
  ></mat-icon>
</button>

<mat-menu #actionsMenu="matMenu" xPosition="after" yPosition="below">
  <ng-template let-model="model" matMenuContent>
    <button *ngFor="let order of orders; let i = index"
            class="icon-btn"
            mat-menu-item
            (click)="setOrderSelect(order, i)"
            [ngClass]="{
              'active': order.nameTranslocoKey === ordersSelect.nameTranslocoKey
            }"
    >
      <mat-icon *ngIf="order.nameTranslocoKey === ordersSelect.nameTranslocoKey"
                [icIcon]="icDone"
                class="check"
      ></mat-icon>
      <span>{{order.nameTranslocoKey | transloco}}</span>
      <mat-icon [icIcon]="order.order === 'ASC' ? icArrowUp : icArrowDown"
                class="direction-sort"
      ></mat-icon>
    </button>
  </ng-template>
</mat-menu>
