import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalUploadToFolderComponent } from './modal-upload-to-folder/modal-upload-to-folder.component';
import { IPrivateFiles } from '../files/file.interface';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IFileEntity } from '@ipnote/interface';

@UntilDestroy()
@Component({
  selector: 'app-upload-to-folder',
  templateUrl: './upload-to-folder.component.html',
  styleUrls: ['./upload-to-folder.component.scss']
})
export class UploadToFolderComponent implements OnInit {
  @Input() privateFiles: IPrivateFiles = null;
  @Output() newFile = new EventEmitter<any[] | IFileEntity[]>();

  constructor(
    private matDialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  /**
   * Open the dialog
   */
  openDialog(): void {
    this.matDialog.open(ModalUploadToFolderComponent, {
      autoFocus: false,
      data: { privateFiles: this.privateFiles }
    }).afterClosed().pipe(
      filter(p => !!p),
      untilDestroyed(this)
    ).subscribe(result => {
      this.newFile.emit(result);
    });
  }
}
