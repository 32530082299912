<mat-form-field (click)="datepicker.open()" [appearance]="appearance">
  <mat-label>{{label}}</mat-label>
  <input matInput
         [readonly]=true
         [matDatepicker]="datepicker"
         [formControl]="formControl"
         [required]="required"
  >
  <mat-datepicker-toggle
    matSuffix
    [for]="datepicker">
  </mat-datepicker-toggle>
  <mat-datepicker #datepicker touchUi></mat-datepicker>
  <mat-icon *ngIf="icon && tooltip" class="icon-tooltip" matTooltip="{{tooltip | transloco}}" matTooltipPosition="above"
            matSuffix>{{icon}}</mat-icon>
</mat-form-field>
