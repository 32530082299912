<mat-form-field class="example-chip-list">
  <mat-label>{{ label || ('payers' | transloco) }}</mat-label>
  <mat-chip-list
    #payersList
    aria-label="Payer selection">
    <mat-chip
      *ngFor="let payer of selectedPayers; let index = index"
      [selectable]="true"
      [removable]="index !== 0"
      (removed)="remove(payer)">
      <span>{{payer.label}}</span>
      <mat-icon *ngIf="index !== 0" matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="{{ 'add-payer' | transloco }}"
      #payersInput
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="payersList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)"
                    [classList]="'mat-select-arrow arrow-position'">
    <ng-container *ngFor="let item of filtered | async">
      <mat-option *ngIf="!alreadyIn(item)" [value]="item">
        <div fxLayout="row" fxLayoutGap="7px" fxLayoutAlign="start center">
          <span>{{item.label}}</span>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
