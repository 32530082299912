<h2 mat-dialog-title>
  <mat-icon color="primary">insert_drive_file</mat-icon>
  <span class="name">{{ data.file.name }}</span>
  <span fxFlex></span>
  <button mat-icon-button tabindex="-1" mat-dialog-close matTooltip="{{ 'close-file-viewer' | transloco }}">
    <mat-icon>close</mat-icon>
  </button>
</h2>
<mat-dialog-content fxFlex>
  <iframe [src]="url"></iframe>
</mat-dialog-content>
