<app-header></app-header>
<div [ngStyle]="{padding: paddingBody+'px'}" class="p-[27px] w-full h-full flex flex-col items-center justify-center">
  <div class="mt-[50px] text-lg font-medium">Welcome {{user.name}}!</div>
  <div
    class="my-8 text-center text-xs font-medium">{{selectedCompany.isServiceProvider ? 'We highly recommend watching this video to understand how to start getting new orders and work with clients in iPNOTE' : 'We highly recommend watching this video to gain a comprehensive understanding of the iPNOTE workflow and learn how to get started with our AI assistant'}}
  </div>
  <app-youtube-player [width]="innerWidth-paddingBody*2" [height]="(innerWidth-paddingBody*2)/1.77"
                      [videoId]="!selectedCompany.isServiceProvider ? 'D0thXeTbtKY': 'kUbi7KPpaWM'"></app-youtube-player>
  <a (click)="close()" *ngIf="selectedCompany.isServiceProvider" [routerLink]="['/desk', selectedCompany?.id, 'company', 'public'] "style="background-color: #F0A93B !important" class="mt-10" mat-raised-button
          color="primary">{{selectedCompany.isServiceProvider ? 'complete profile' : 'start AI CHAT'}}
  </a>
  <a (click)="close()" *ngIf="!selectedCompany.isServiceProvider" [routerLink]="['/ai']" style="background-color: #F0A93B !important" class="mt-10" mat-raised-button
     color="primary">{{selectedCompany.isServiceProvider ? 'complete profile' : 'start AI CHAT'}}
  </a>
  <div (click)="close()" class="mt-[70px] text-xs font-medium cursor-pointer hover:bg-hover opacity-50">Switch to the full website version</div>
</div>
