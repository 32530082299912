export enum MessageType {
  NEW_SERVICE_REQUEST = 'chat_new_service_request',
  NEW_SERVICE_REQUEST_FILING_TRADEMARK = 'chat_new_service_request_filing_trademark',
  NEW_OFFER = 'chat_new_offer',
  OFFER_UPDATED = 'chat_offer_updated',
  OFFER_HAS_BEEN_RESET = 'chat_offer_has_been_reset',
  TASK_CONFIRMED = 'chat_task_confirmed',
  TASK_PAID = 'chat_task_paid',
  TASK_ACCEPTED_BY_PROVIDER = 'chat_task_accepted_by_provider',
  TASK_COMPLETED = 'chat_task_completed',
  TASK_SEND_TO_BE_FIXED = 'chat_task_send_to_be_fixed',
  TASK_COMPLETED_AGAIN = 'chat_task_completed_again',
  TASK_ACCEPTED_BY_CUSTOMER = 'chat_task_accepted_by_customer',
  TASK_DESCRIPTION_WAS_UPDATED = 'chat_task_description_was_updated',
  TASK_AUTOMATICALLY_ACCEPTED = 'chat_task_automatically_accepted',
  NEW_PERSONAL_CHAT_MESSAGE = 'new_personal_chat_message',
  TASK_HAS_BEEN_AUTO_DELETED_PROVIDERS = 'chat_task_has_been_auto_deleted_providers',
  TASK_ADDITIONAL_INFO_WAS_UPDATED = 'chat_task_additional_info_was_updated',
}
