import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { Observable } from 'rxjs';
import { Paged } from '../../../../dataset/Paged';
import { flatMap, map, mergeMap } from 'rxjs/operators';
import { SaldoPaged } from 'app/dataset/SaldoPaged';
import { ITransactionEntity } from '@ipnote/interface';
import { RequestQueryBuilderService } from '../../../../app-common/services/request-query-builder/request-query-builder.service';
import { TransactionTypes } from '@ipnote/enum';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService extends ApiService<ITransactionEntity> {
  newTransaction = new EventEmitter();

  constructor(
    http: HttpClient,
    baseUrl: BaseUrlService,
    queryBuilder: RequestQueryBuilderService,
    spinnerService: SpinnerViewService,
  ) {
    super(http, baseUrl, queryBuilder, 'transactions', 'transactions', ['name', 'innerId'], spinnerService);
  }

  forTask(id: number, pageIndex: number, pageSize: number): Observable<Paged<ITransactionEntity>> {
    return this.baseUrl.getBaseUrl(`task/${id}/transactions`).pipe(
      flatMap((url) =>
        this.http.get<Paged<ITransactionEntity>>(url, {
          params: {
            pageSize: String(pageSize),
            pageIndex: String(pageIndex),
            sort: '-createdAt',
          },
        }),
      ),
    );
  }

  saldo(
    pageIndex: number,
    pageSize: number,
    company: number,
    sort: string,
    searchToken: string,
    from: string,
    to: string,
    incomeStages: string,
    expenseStages: string,
  ): Observable<SaldoPaged> {
    let params = new HttpParams().set('pageSize', pageSize.toString()).set('pageIndex', pageIndex.toString());
    // if (sort) {
    //   params = params.set('sort', sort);
    // } else {
    //   params = params.set('sort', '-date');
    // }
    if (searchToken) {
      params = params.set('searchToken', searchToken);
      params = params.set('searchFields', this.searchFields.join(','));
    }
    let filter: any = null;
    if (from) {
      filter = {};
      filter.$and = [{ date: { $gt: from } }];
    }
    if (to) {
      filter = filter || {};
      if (!filter.$and) {
        filter.$and = [{ date: { $lt: to } }];
      } else {
        filter.$and.push({ date: { $lt: to } });
      }
    }
    let $or: any[] = null;
    if (incomeStages) {
      $or = [];
      $or.push({
        transactionType: TransactionTypes.INCOME,
        stage: {
          $in: incomeStages.split(','),
        },
      });
    }

    if (expenseStages) {
      $or = $or || [];
      $or.push({
        transactionType: TransactionTypes.EXPENSE,
        stage: {
          $in: expenseStages.split(','),
        },
      });
    }

    if ($or) {
      if (!filter) {
        filter = { $or };
      } else {
        filter.$and.push({ $or });
      }
    }

    if (filter) {
      params = params.set('filter', JSON.stringify(filter));
    }

    return this.baseUrl
      .getBaseUrl(`companies/${company}/saldo`)
      .pipe(flatMap((url) => this.http.get<Paged<ITransactionEntity>>(url, { params })));
  }

  pay(id: string) {
    return this.baseUrl.getBaseUrl(`transaction/${id}/pay`).pipe(flatMap((url) => this.http.put<any>(url, {})));
  }

  completeRefillTransaction(id: number): Observable<ITransactionEntity> {
    return this.baseUrl
      .getBaseUrl(`transactions/${id}/complete-refill-transaction`)
      .pipe(flatMap((url) => this.http.patch<ITransactionEntity>(url, {})));
  }

  completeWithdrawalTransaction(id: number): Observable<ITransactionEntity> {
    return this.baseUrl
      .getBaseUrl(`transactions/${id}/complete-withdrawal-transaction`)
      .pipe(flatMap((url) => this.http.patch<ITransactionEntity>(url, {})));
  }

  getTotalAmountOfPayoutsWithoutTransfer(): Observable<number> {
    return this.baseUrl.getBaseUrl('transactions/amount-of-payouts-without-transfer').pipe(
      mergeMap((url) => this.http.get<{ totalAmount: number }>(url)),
      map((response) => Number(response.totalAmount ?? 0)),
    );
  }
}
