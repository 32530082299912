import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { mergeMap, Observable } from 'rxjs';
import { IFeedbackEntity, IGetManyDefaultResponse, ITaskEntity, IUpdateFeedbackVerifiedDto } from '@ipnote/interface';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient,
              private baseUrl: BaseUrlService) {
  }

  postFeedback(body: IFeedbackEntity): Observable<ITaskEntity> {
    const newBody = {
      text: body.text,
      task: { id: body.task.id },
      rating: body.rating,
      executorCompany: { id: body.executorCompany.id },
      clientCompany: { id: body.clientCompany.id }
    };
    return this.baseUrl.getBaseUrl('feedback').pipe(
      mergeMap((url) => this.http.post<ITaskEntity>(url, newBody))
    );
  }
  postAdminFeedback(body: IFeedbackEntity): Observable<ITaskEntity> {
    return this.baseUrl.getBaseUrl('feedback/from-admin').pipe(
      mergeMap((url) => this.http.post<ITaskEntity>(url, body))
    );
  }
  getFeedback(): Observable<IGetManyDefaultResponse<IFeedbackEntity[]>> {
    return this.baseUrl.getBaseUrl('feedback').pipe(
      mergeMap((url) => this.http.get<IGetManyDefaultResponse<IFeedbackEntity[]>>(url))
    );
  }

  verifyPublishReview(id: number, body: IUpdateFeedbackVerifiedDto) {
    return this.baseUrl.getBaseUrl(`feedback/${id}/verify`).pipe(
      mergeMap((url) => this.http.patch(url, body))
    );
  }
  updateFeedback(id: number, body: IFeedbackEntity) {
    return this.baseUrl.getBaseUrl(`feedback/${id}`).pipe(
      mergeMap((url) => this.http.patch(url, body))
    );
  }

  removeReview(id: number) {
    return this.baseUrl.getBaseUrl(`feedback/${id}`).pipe(
      mergeMap((url) => this.http.delete(url))
    );
  }
}
