import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IOIPEntity } from '@ipnote/interface';

@Injectable({
  providedIn: 'root'
})
export class ChangeOipService {
  subject$: BehaviorSubject<IOIPEntity> = new BehaviorSubject(null);

  constructor() {
  }
}
