import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  title: string;
  message: string;
  primaryButton: string;
  slaveButton: string;
  inputData: string;
}

@Component({
  selector: 'app-common-input-dialog',
  templateUrl: './common-input-dialog.component.html',
  styleUrls: ['./common-input-dialog.component.scss']
})
export class CommonInputDialogComponent implements OnInit {
  inputData: string;

  constructor(public dialogRef: MatDialogRef<CommonInputDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.inputData = data.inputData;
  }

  ngOnInit() {
  }

  decline() {
    this.dialogRef.close(false);
  }

  accept() {
    this.dialogRef.close(this.inputData ? this.inputData : null);
  }
}
