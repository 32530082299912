<div class="tutorial-sidenav">
  <div class="title">
    <div>Help & getting started</div>
    <button mat-icon-button type="button" (click)="close()">
      <mat-icon class="close-icon">clear</mat-icon>
    </button>
  </div>
  <div class="block1">
    <div class="flex flex-nowrap items-center row1 whats-new">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#145EAB" />
        <path
          d="M9.98512 5.79167C10.0099 5.70744 10.0613 5.6335 10.1316 5.58092C10.2019 5.52834 10.2873 5.49995 10.3751 5.5H13.6249C13.6892 5.50005 13.7526 5.51538 13.8099 5.54472C13.8671 5.57407 13.9166 5.61659 13.9542 5.66879C13.9918 5.72099 14.0165 5.78137 14.0262 5.84497C14.0359 5.90856 14.0303 5.97356 14.01 6.03459L12.5638 10.3747H15.656C15.7321 10.3747 15.8067 10.396 15.8712 10.4362C15.9358 10.4765 15.9877 10.534 16.0212 10.6023C16.0546 10.6707 16.0682 10.747 16.0604 10.8227C16.0526 10.8984 16.0237 10.9703 15.9769 11.0304L10.2898 18.3424C10.2315 18.4177 10.1487 18.4701 10.0557 18.4905C9.96275 18.511 9.86557 18.4981 9.78112 18.4542C9.69668 18.4103 9.63033 18.3381 9.59367 18.2502C9.55701 18.1624 9.55235 18.0645 9.58052 17.9736L11.0437 13.2183H8.34396C8.28081 13.2183 8.21851 13.2036 8.16203 13.1754C8.10554 13.1471 8.05642 13.1061 8.01856 13.0556C7.98071 13.005 7.95516 12.9463 7.94395 12.8842C7.93274 12.822 7.93618 12.7581 7.95399 12.6975L9.98512 5.79167Z"
          fill="white" />
      </svg>
      <div class="pl">What’s new in iPNOTE</div>
    </div>
    <div class="flex flex-nowrap items-center row2">
      <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.4 14.4V2.4C20.4 1.08 19.32 0 18 0H2.4C1.08 0 0 1.08 0 2.4V14.4C0 15.72 1.08 16.8 2.4 16.8H18C19.32 16.8 20.4 15.72 20.4 14.4ZM7.2 13.2V3.6L14.4 8.4L7.2 13.2Z"
          fill="#145EAB" />
      </svg>
      <div class="pl">iPNOTE how to series:</div>
    </div>
  </div>

  <ng-container *ngIf="!(company$ | async)?.isServiceProvider; else serviceProviderBlock">
    <div class="block2">
      <div class="row" (click)="openYoutube($event, 'https://youtu.be/ya0CCbndJQg')">
        <div>Create a new task</div>
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="row" (click)="openYoutube($event, 'https://youtu.be/goUOWQx1pNw')">
        <div>Review & confirm an offer</div>
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="row" (click)="openYoutube($event, 'https://youtu.be/SbwfAlTYEDA')">
        <div>Approve task delivery</div>
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
    </div>
  </ng-container>

  <ng-template #serviceProviderBlock>
    <div class="block2">
      <div class="title-row">RECIEVE TASKS</div>
      <div class="row">
        <div>Build your public profile</div>
        <svg (click)="openYoutube($event, 'https://youtu.be/ym1C8T8FGH4')" width="14" height="13" viewBox="0 0 14 13"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="row">
        <div>Enlist services</div>
        <svg (click)="openYoutube($event, 'https://youtu.be/hGQnb0URISM')" width="14" height="13" viewBox="0 0 14 13"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="row">
        <div>Suggest a project price</div>
        <svg (click)="openYoutube($event, 'https://youtu.be/z55etVgTjU4')" width="14" height="13" viewBox="0 0 14 13"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="row">
        <div>Execute a task</div>
        <svg (click)="openYoutube($event, 'https://youtu.be/Blo85Q15gl4')" width="14" height="13" viewBox="0 0 14 13"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="title-row">CREATE TASKS</div>
      <div class="row">
        <div>Create a new task</div>
        <svg (click)="openYoutube($event, 'https://youtu.be/ya0CCbndJQg')" width="14" height="13" viewBox="0 0 14 13"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="row">
        <div>Review & confirm an offer</div>
        <svg (click)="openYoutube($event, 'https://youtu.be/goUOWQx1pNw')" width="14" height="13" viewBox="0 0 14 13"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
      <div class="row">
        <div>Approve task delivery</div>
        <svg (click)="openYoutube($event, 'https://youtu.be/SbwfAlTYEDA')" width="14" height="13" viewBox="0 0 14 13"
             fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.7036 6.16773V11.375C10.7036 11.9963 10.2062 12.5 9.59251 12.5H1.44436C0.830706 12.5 0.333252 11.9963 0.333252 11.375V3.125C0.333252 2.50367 0.830706 2 1.44436 2H9.31418C9.56166 2 9.68559 2.30295 9.51059 2.48012L8.95504 3.04262C8.90294 3.09536 8.83229 3.12499 8.75862 3.125H1.58325C1.54642 3.125 1.51109 3.13982 1.48504 3.16619C1.459 3.19256 1.44436 3.22833 1.44436 3.26562V11.2344C1.44436 11.2717 1.459 11.3074 1.48504 11.3338C1.51109 11.3602 1.54642 11.375 1.58325 11.375H9.45362C9.49046 11.375 9.52579 11.3602 9.55183 11.3338C9.57788 11.3074 9.59251 11.2717 9.59251 11.2344V6.73023C9.59251 6.65562 9.62177 6.58409 9.67388 6.53136L10.2294 5.96886C10.4044 5.79167 10.7036 5.91716 10.7036 6.16773ZM13.3888 0.5H10.241C9.99429 0.5 9.86973 0.803047 10.0446 0.980117L11.16 2.10983L4.85906 8.48956C4.75059 8.59939 4.75059 8.77747 4.85906 8.88732L5.38284 9.41764C5.49133 9.52747 5.66721 9.52747 5.77568 9.41764L12.0766 3.03793L13.1924 4.16743C13.3663 4.34352 13.6666 4.21981 13.6666 3.96856V0.78125C13.6666 0.62593 13.5422 0.5 13.3888 0.5Z"
            fill="#AFBDD1" />
        </svg>
      </div>
    </div>
  </ng-template>
</div>

