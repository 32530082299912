import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { ICompanyEntity } from '@ipnote/interface';
import { selectStateSelectedCompany } from '#selectors';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-tutorial-sidenav',
  templateUrl: './tutorial-sidenav.component.html',
  styleUrls: ['./tutorial-sidenav.component.scss']
})
export class TutorialSidenavComponent implements OnInit {
  @Output() closeEvent = new EventEmitter<void>();
  public company$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);

  constructor(private store: Store<AppState>, private matDialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  close() {
    this.closeEvent.emit();
  }

  openYoutube($event: any, link: string) {
    $event.preventDefault();
    $event.stopPropagation();
    window.open(link);
    return null;
  }
}
