import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompaniesService } from '../../../page-modules/desk/services/companies/companies.service';

@Component({
  selector: 'app-request-edit-public-card',
  templateUrl: './request-edit-public-card.component.html',
  styleUrls: ['./request-edit-public-card.component.scss']
})
export class RequestEditPublicCardComponent implements OnInit {
  form = this.fb.group({
    description: [null]
  });
  serverRequesting: boolean;

  constructor(private snackBar: MatSnackBar, private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<RequestEditPublicCardComponent>, @Inject(MAT_DIALOG_DATA) public companyId: number, private companiesService: CompaniesService) {
  }

  ngOnInit(): void {
  }

  send() {
    this.serverRequesting = true;
    this.companiesService.requestToEdit(
      this.companyId,
      { message: this.form.get('description').value }
    ).subscribe(result => {
      this.serverRequesting = false;
      this.dialogRef.close();
      this.snackBar.open('edit request sent', 'OK', { duration: 3000 });
    });
  }
}
