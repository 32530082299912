import { filter, finalize, mergeMap, switchMap } from 'rxjs/operators';
import { ServiceRequestsService } from '../service-requests/service-requests.service';
import { combineLatest, lastValueFrom, Observable } from 'rxjs';
import { ICompanyEntity, IOIPEntity, IServiceRequestEntity, ITaskEntity } from '@ipnote/interface';
import { SpinnerViewService } from '../../../../app-common/services/spinner/spinner-view.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsEventEnum, CompanyTypeEnum } from '@ipnote/enum';
import { AnalyticsService } from '../../../../app-common/services/analytics/analytics.service';
import { DialogService } from '../../../../app-common/services/dialogs/dialog.service';
import { TranslocoService } from '@ngneat/transloco';
import { OIPService } from '../oip/oip.service';
import { selectStateSelectedCompany } from '#selectors';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { ErrorService } from '../../../../app-common/services/error-service/error-service';
import { DownloadService } from '../../../../app-common/services/download/download.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TransactionsService } from '../transactions/transactions.service';
import { ServicesService } from '../services/services.service';
import { EnumsService } from '../enums/enums.service';
import { RelationsService } from '../../../../app-common/services/links/relations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompaniesService } from '../companies/companies.service';
import { FeedbackService } from '../feedback/feedback.service';
import { Injectable } from '@angular/core';
import { OfferPriceComponent } from '../../modules/tasks/offer-price/offer-price.component';
import { CompleteWorkComponent } from '../../modules/tasks/complete-work/complete-work.component';
import { ReopenTaskComponent } from '../../modules/tasks/reopen-task/reopen-task.component';
import { AcceptTaskComponent } from '../../modules/tasks/accept-task/accept-task.component';
import { TasksService } from '../tasks/tasks.service';
import { PermissionService } from '../../../../app-common/guards/permission.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TaskFlowService {
  stripeUrlBlank;
  selectedCompany: ICompanyEntity;
  selectedCompany$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  isAdmin: Promise<boolean>;
  constructor(
    private downloadService: DownloadService,
    private tasksService: TasksService,
    private matDialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private transactionsService: TransactionsService,
    private errorService: ErrorService,
    private serviceRequestsService: ServiceRequestsService,
    private dialogs: DialogService,
    private servicesService: ServicesService,
    private translocoService: TranslocoService,
    public enums: EnumsService,
    private relationsService: RelationsService,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private companiesService: CompaniesService,
    private spinnerService: SpinnerViewService,
    private analyticsService: AnalyticsService,
    private oipService: OIPService,
    private feedbackService: FeedbackService,
    private permissionService: PermissionService,
  ) {
    this.selectedCompany$.pipe(untilDestroyed(this)).subscribe((company) => (this.selectedCompany = company));
    this.isAdmin = new Promise((resolve) => {
      this.permissionService.checkRole(['can-everything']).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveInternal(taskId: number, internalExecutorId: string): Observable<ITaskEntity> {
    return this.tasksService.update(taskId, { internalExecutor: internalExecutorId, stage: 'running' });
  }

  savePotential(executors: ICompanyEntity[], taskId: number, clientCompanyId?: number): Observable<ITaskEntity> {
    this.spinnerService.start();
    return combineLatest(
      executors?.map((executor) => {
        const payload = {
          taskId,
          executorCompanyId: executor.id,
        };
        if (clientCompanyId) {
          payload['clientCompanyId'] = clientCompanyId;
        }
        return this.serviceRequestsService.create(payload);
      }),
    ).pipe(
      switchMap((_) => this.tasksService.get(taskId)),
      finalize(() => this.spinnerService.stop()),
    );
  }

  rejectServiceRequest(serviceRequest: IServiceRequestEntity) {
    return this.dialogs.declineOfferDialog(serviceRequest).pipe(
      filter((p) => !!p),
      mergeMap((dialogResult) => {
        this.spinnerService.start();
        this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_OFFER_DECLINE, {
          taskId: serviceRequest.taskId,
          reason: dialogResult,
        });
        return this.serviceRequestsService.removeServiceRequest(serviceRequest.id, dialogResult).pipe(
          untilDestroyed(this),
          finalize(() => this.spinnerService.stop()),
        );
      }),
    );
  }

  makeCopy(task: ITaskEntity) {
    const title = this.translocoService.translate('copying');
    const message = `Do you really want to copy task "${task.title}"`;
    return this.dialogs
      .confirm({
        title,
        message,
        primaryButton: this.translocoService.translate('yes_accept'),
        slaveButton: this.translocoService.translate('no'),
      })
      .pipe(
        filter((p) => !!p),
        mergeMap((p) => {
          return this.tasksService.makeCopy(task.id);
        }),
        untilDestroyed(this),
      );
  }

  offerPrice(task: ITaskEntity) {
    this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_OFFER_STARTED, { taskId: task.id });
    return this.matDialog
      .open(OfferPriceComponent, {
        data: { task: task },
        width: '537px',
      })
      .afterClosed();
  }

  async initiateWork(task: ITaskEntity) {
    this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_INITIATED, { taskId: task.id });
    const res = await new Promise((resolve) => {
      this.dialogs
        .confirm({
          title: this.translocoService.translate('inner-work'),
          message: this.translocoService.translate('confirm_task', { task_name: task.title }),
          primaryButton: this.translocoService.translate('yes_accept'),
          slaveButton: this.translocoService.translate('no'),
        })
        .pipe(
          filter((p) => !!p),
          untilDestroyed(this),
        )
        .subscribe((res) => resolve(res));
    });
    if (res) {
      const returnedTask: ITaskEntity = await new Promise((resolve) => {
        this.tasksService.innerWork(task.id).subscribe({
          next: (task) => resolve(task),
          error: (err) => this.errorService.handleError(err),
        });
      });
      this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_INITIATED, {
        taskId: task.id,
        prices: task.prices,
      });
      return returnedTask;
    }
  }

  completeWork(task: ITaskEntity) {
    return this.matDialog
      .open(CompleteWorkComponent, {
        data: { task },
      })
      .afterClosed()
      .pipe(
        filter((p) => !!p),
        untilDestroyed(this),
      );
  }

  reopen(task: ITaskEntity) {
    return this.matDialog
      .open(ReopenTaskComponent, {
        data: { taskId: task.id },
        width: '388px',
      })
      .afterClosed()
      .pipe(
        filter((p) => !!p),
        untilDestroyed(this),
      );
  }

  acceptJob(task: ITaskEntity) {
    let result: Promise<any>;
    if (this.selectedCompany.type !== CompanyTypeEnum.PROVIDER_CLIENT) {
      result = new Promise((resolve) => {
        this.matDialog
          .open(AcceptTaskComponent, {
            data: { task },
          })
          .afterClosed()
          .pipe(
            filter((p) => !!p),
            untilDestroyed(this),
          )
          .subscribe((res) => {
            resolve({ text: res?.text, rating: res?.userRating });
          });
      });
    }
    return result.then((res) => {
      return this.tasksService
        .accept(task.id, res)
        .pipe(
          finalize(() => this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_DELIVERED, { price: task.prices })),
        );
    });
  }

  leaveFeedback(task: ITaskEntity) {
    return this.matDialog
      .open(AcceptTaskComponent, {
        data: { task: task, onlyFeedBackMode: true },
      })
      .afterClosed()
      .pipe(
        filter((p) => !!p),
        mergeMap((res) => {
          return this.feedbackService.postFeedback({
            text: res.text,
            task: task,
            rating: res.userRating,
            executorCompany: task.serviceRequests[0].executorCompany,
            clientCompany: task.company,
          });
        }),
        untilDestroyed(this),
      );
  }

  editOffer(task: ITaskEntity) {
    return this.matDialog
      .open(OfferPriceComponent, {
        data: { task: task, adminEditOffer: true },
        width: '537px',
      })
      .afterClosed();
  }

  rejectTask(task: ITaskEntity) {
    const sr = task.serviceRequests[0];
    return this.dialogs.rejectTaskDialog(task).pipe(
      filter((p) => !!p),
      mergeMap((dialogResult) => {
        return this.serviceRequestsService.rejectServiceRequest(sr.id, dialogResult).pipe(untilDestroyed(this));
      }),
    );
  }

  async removeTask(task: ITaskEntity) {
    if (
      [
        this.enums.taskStages.RUNNING,
        this.enums.taskStages.IN_PROGRESS,
        this.enums.taskStages.CHECK,
        this.enums.taskStages.REOPEN,
        this.enums.taskStages.DONE,
      ].includes(task.stage) &&
      !(await this.isAdmin)
    ) {
      await this.dialogs.alert({
        title: 'Comment',
        message:
          'You can not delete the task after offer confirmation. Please write to <a class="text-primary-500 underline" href="mailto:support@ipnote.pro">support@ipnote.pro</a> and we will take care of it.',
        primaryButton: 'Close',
      });
      return false;
    } else {
      const dialogResult = await new Promise((resolve) => {
        this.dialogs
          .deleteTaskDialog(task)
          .pipe(
            filter((dialogResult) => !!dialogResult),
            untilDestroyed(this),
          )
          .subscribe((dialogResult) => resolve(dialogResult));
      });
      const res = await new Promise((resolve, reject) => {
        this.tasksService
          .delete(task.id, dialogResult)
          .pipe(untilDestroyed(this))
          .subscribe({
            next: (res) => resolve(res),
            error: (err) => this.dialogs.error(err),
          });
      });
      this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_DELETED, { deleteReason: dialogResult });
      return res;
    }
  }

  async acceptServiceRequest(sr: IServiceRequestEntity, task: ITaskEntity) {
    let changeAccessAgent = false;

    if (task.oips[0]?.id) {
      let oip: IOIPEntity;
      const { accessAgentId, keyRightHolderId } = task.oips[0];

      if (accessAgentId !== sr.executorCompany?.id) {
        if (accessAgentId === keyRightHolderId) {
          changeAccessAgent = true;
        } else {
          this.spinnerService.start();
          oip = await lastValueFrom(this.oipService.get(task.oips[0].id));
          changeAccessAgent = await lastValueFrom(
            this.dialogs.confirm({
              title: 'Change Agent',
              message: `Would you like to change an agent from "${oip.accessAgent?.name}" to "${sr.executorCompany?.name}"?`,
              primaryButton: 'YES',
              slaveButton: 'NO',
            }),
          );
        }
      }
    }

    this.spinnerService.stop();
    let confirmReason;
    if (this.selectedCompany.type !== CompanyTypeEnum.PROVIDER_CLIENT) {
      confirmReason = await lastValueFrom(this.dialogs.confirmOffer(sr.executorCompany.name).pipe(filter((p) => !!p)));
    }
    const res: ITaskEntity = await new Promise((resolve) => {
      this.serviceRequestsService
        .acceptOffer(sr.offer.id, {
          ...confirmReason,
          changeAccessAgent,
        })
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (res) => resolve(res),
          error: (err) => this.errorService.handleError(err),
        });
    });
    if (res) {
      this.analyticsService.sendEvent(AnalyticsEventEnum.TASK_OFFER_CONFIRMED, {
        taskId: task.id,
        reason: confirmReason,
        country: task.country,
        service: task.service.name,
        oipType: task.oipType,
      });
    }
    return res;
  }
}
