import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { EnumsService } from '../../../../page-modules/desk/services/enums/enums.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '#appState';
import { Store } from '@ngrx/store';
import { selectStateSelectedCompany } from '#selectors';
import { Observable } from 'rxjs';
import { ICompanyEntity } from '@ipnote/interface';
import { ProviderOnboardingService } from '../../../services/onboarding/provider-onboarding.service';
import { ShareLinkDialogComponent } from '../share-link-dialog/share-link-dialog.component';
import {
  actionButtonClicked,
  collapseProviderOnboardingPanel,
  expandProviderOnboardingPanel
} from '../../../../store/actions/onboarding-provider-panel.actions';
import { filter } from 'rxjs/operators';
import { panelStateSelector } from '../../../../store/selectors/onboarding-provider-panel.selector';

import {
  CloseProviderOnboarding,
  CompanySelect,
  ShowProviderOnboarding
} from '../../../../store/actions/company.actions';
import { CompaniesService } from '../../../../page-modules/desk/services/companies/companies.service';
import { DialogService } from '../../../services/dialogs/dialog.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


export function isAllOnboardingStepsDone(this: any): boolean {
  return this.company.onboardingSteps?.isCompanyPublicInfoCompleted &&
    this.company.onboardingSteps?.areServicesAdded &&
    this.company.onboardingSteps?.isInformationShared &&
    this.company.onboardingSteps.isOutgoingTaskCreated;
}

@UntilDestroy()
@Component({
  selector: 'app-provider-onboarding-panel',
  templateUrl: './provider-onboarding-panel.component.html',
  styleUrls: ['./provider-onboarding-panel.component.scss']
})
export class ProviderOnboardingPanelComponent implements OnInit {
  @Output() expandDropDownById = new EventEmitter<string>();
  panelOpenState: boolean;
  company$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  company: ICompanyEntity;
  onboardingProviderPanelState$: Observable<any> = this.store.select(panelStateSelector);

  constructor(public enums: EnumsService,
              public dialogRef: MatDialogRef<ProviderOnboardingPanelComponent>, @Inject(MAT_DIALOG_DATA)
              public data: { panelOpenState: boolean },
              private store: Store<AppState>,
              private onboardingService: ProviderOnboardingService,
              private matDialog: MatDialog,
              private companiesService: CompaniesService,
              private dialogs: DialogService
  ) {

    this.panelOpenState = this.data.panelOpenState;
    this.company$.pipe(
      filter(p => !!p)
    ).subscribe(company => {
      this.company = company;
    });
  }

  ngOnInit() {
    if (this.company.onboardingSteps.isFirstRun) {
      this.companiesService.update(this.company.id, {
        onboardingSteps: {
          id: this.company.onboardingSteps?.id,
          isFirstRun: false
        }
      }).pipe(
        filter(p => !!p),
        untilDestroyed(this)
      ).subscribe(
        (company) => {
          const copiedCompany: ICompanyEntity = JSON.parse(JSON.stringify(this.company));
          copiedCompany.onboardingSteps.isFirstRun = false;
          this.store.dispatch(CompanySelect({ company: copiedCompany }));
        },
        (error) => this.dialogs.error(error));
    }

    this.onboardingProviderPanelState$.pipe(filter(p => p !== null)).subscribe(state => {
      this.panelOpenState = state;
    });
  }

  cancelCheckBox(event$: any) {
    event$.stopPropagation();
    event$.preventDefault();
    return null;
  }

  runPublicProfileTour() {
    if (!this.company.onboardingSteps?.isCompanyPublicInfoCompleted) {
      //this.panelOpenState = false;
      this.store.dispatch(collapseProviderOnboardingPanel());
      this.store.dispatch(actionButtonClicked({ buttonAction: 'company' }));
      this.onboardingService.openPublicProfileTour();
    }
  }

  runServicesTour() {
    if (!this.company.onboardingSteps?.areServicesAdded) {
      //this.panelOpenState = false;
      this.store.dispatch(collapseProviderOnboardingPanel());
      this.store.dispatch(actionButtonClicked({ buttonAction: 'company' }));
      this.onboardingService.openServicesTour();
    }
  }

  showNewTaskButton() {
    if (!this.company.onboardingSteps?.isOutgoingTaskCreated) {
      //this.panelOpenState = false;
      this.store.dispatch(collapseProviderOnboardingPanel());
      this.onboardingService.openNewTaskButtonTour();
    }
  }

  showSharedLink() {
    //this.panelOpenState = false;
    this.store.dispatch(collapseProviderOnboardingPanel());
    this.matDialog.open(ShareLinkDialogComponent, {
      width: '465px',
      panelClass: 'share-link-dialog'
    });
  }

  getTitle() {
    let title = `Welcome ${this.company.name}!`;
    if (this.isAnyOnboardingStepsDone && !this.isAllOnboardingStepsDone) {
      title = 'Well done! Let\'s continue...';
    } else if (this.isAllOnboardingStepsDone) {
      title = 'Congratulations!';
    }
    return title;
  }

  get isAllOnboardingStepsDone() {
    return this.company.onboardingSteps?.isCompanyPublicInfoCompleted &&
      this.company.onboardingSteps?.areServicesAdded &&
      this.company.onboardingSteps?.isInformationShared &&
      this.company.onboardingSteps.isOutgoingTaskCreated;
  }

  get isAnyOnboardingStepsDone() {
    return this.company.onboardingSteps?.isCompanyPublicInfoCompleted ||
      this.company.onboardingSteps?.areServicesAdded ||
      this.company.onboardingSteps?.isInformationShared ||
      this.company.onboardingSteps?.isOutgoingTaskCreated;
  }

  expandPanel() {
    this.store.dispatch(expandProviderOnboardingPanel());
  }

  collapsePanel() {
    this.store.dispatch(collapseProviderOnboardingPanel());
    if (this.dialogRef['_ref'].config.hasBackdrop) {
      this.dialogRef.close();
      this.store.dispatch(CloseProviderOnboarding());
      this.store.dispatch(ShowProviderOnboarding());
    }
  }
}
