<div *ngIf="diffsMap.add">
  <div *ngFor="let diff of diffsMap.add">
    <ng-container *ngIf="diff.value">
      <strong> {{diff.key}}</strong> = {{diff.value._id || diff.value}}
    </ng-container>
  </div>
</div>
<div *ngIf="diffsMap.update">
  <div *ngFor="let diff of diffsMap.update">
    <ng-container
      *ngIf="!['files','countries', 'authors', 'rightHolders', 'parents', 'oips', 'rias', 'territories', 'usage_area', 'business_model'].includes(diff.key)">
      <strong>{{diff.key}}:</strong>
      {{diff.oldValue ? (diff.oldValue._id || diff.oldValue) : '' }}
      &rarr; {{diff.value ? (diff.value._id || diff.value) : ''}}
    </ng-container>
    <ng-container *ngIf="diff.key === 'files'">
      <ng-container *ngFor="let change of diff.changes">
        <ng-container [ngSwitch]="change.type">
          <div *ngSwitchCase="'add'">
            <strong>{{ 'file-added' | transloco }}</strong> {{change.value.name}}</div>
          <div *ngSwitchCase="'remove'">
            <strong>{{ 'file-removed' | transloco }}</strong> {{change.value.name}}</div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<div *ngIf="diffsMap.delete">
  <span>{{ 'deleted' | transloco }}</span>
  <span *ngFor="let diff of diffsMap.delete">{{diff.key}}</span>
</div>
