<div class="onboarding h-full w-full flex flex-col justify-center items-center">
  <div class="welcome">
    <div class="welcome-title mb-3">Welcome to iPNOTE, {{user.name}}</div>
    <div class="welcome-text">Let’s explore iPNOTE together. Click the icon “?” and check our <a target="_blank"
                                                                                                 class="underline"
                                                                                                 href="https://ipnote.pro/en/blog/category/help-center/">Help
      Center.</a>
    </div>
  </div>
  <div class="my-8 text-sm font-medium">
    {{!selectedCompany.isServiceProvider ? 'We recommend watching this video to understand how the workflow in iPNOTE is structured and where to start.' : 'We recommend watching this video to understand how to start getting new orders and work with clients in iPNOTE.'}}
  </div>
  <app-youtube-player [width]="innerWidth/2" [height]="innerWidth/2/1.77"
    [videoId]="!selectedCompany.isServiceProvider ? 'kXrzLyo8LgQ': 'eD6MdtncM9c'">
  </app-youtube-player>
  <a class="mt-10 px-6" mat-raised-button color="primary" *ngIf="!selectedCompany.isServiceProvider"
     [href]="'/ai'" target="_blank">
    {{ 'start now' | transloco }}
  </a>
  <a class="mt-10 px-6" mat-raised-button color="primary" *ngIf="selectedCompany.isServiceProvider"
     [routerLink]="['/desk', selectedCompany?.id, 'company', 'public']">
    {{ 'start now' | transloco }}
  </a>
</div>
