<div class="send-email-wrapper">
  <h1 mat-dialog-title>Share your referral</h1>
  <div class="close-button" (click)="close()">
    <mat-icon size="10px">close</mat-icon>
  </div>
  <div class="flex flex-col" mat-dialog-content>
    <div class="mb-2">Send to</div>
    <mat-form-field class="example-full-width">
      <mat-label>Email</mat-label>
      <input type="email" matInput [formControl]="emailFormControl"
             placeholder="Ex. pat@example.com">
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <span class="mb-2 font-medium">Preview</span>
    <div class="rounded-[4px] border border-[#afbdd1]">
      <div class="my-8 mx-8">
        <h2 class="mb-6 font-medium">{{(userStore$ | async).name + ' ' + (userStore$ | async).secondName}} invited you
          to
          join iPNOTE</h2>
        <div class="text-base mb-6">
          {{(userStore$ | async).name + ' ' + (userStore$ | async).secondName}} from <span
          class="font-medium">'{{(companySelectStore$ | async).name}}'</span> uses iPNOTE to manage and register IP for
          their business and thought you might want to try it too.
        </div>
        <div class="px-4 py-4 rounded-[4px] border bg-[#F0F4F8]">
          <div class="flex items-end mb-1">
            <h2 class="leading-none">$100</h2>
            <span class="text-lg">.00</span>
          </div>
          <div class="border-b border-[#afbdd1] pb-2 mb-2">Referral bonus</div>

          <div>Make your first purchase within 90 days and get a $100 discount.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-2 flex justify-end" mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="close()">Cancel</button>
    <button mat-raised-button color="primary" mat-dialog-close [disabled]="emailFormControl.invalid" (click)="send()"
            cdkFocusInitial>Send
    </button>
  </div>
</div>
