import { Currency, SubscriptionAdditionalFieldsKeys, UserSubscriptionSupportPeriod } from '../../../export-enum';

export const COMPANY_ADDITIONAL_FIELDS_DEFAULT = {
  [SubscriptionAdditionalFieldsKeys.allowedOipCount]: 10,
  [SubscriptionAdditionalFieldsKeys.allowedCompanyCount]: 0,
  [SubscriptionAdditionalFieldsKeys.feeMinSize]: 40,
  [SubscriptionAdditionalFieldsKeys.feeMinCurrency]: Currency.USD,
  [SubscriptionAdditionalFieldsKeys.feeSize]: 10,
  [SubscriptionAdditionalFieldsKeys.incomingFeeSize]: 15,
  [SubscriptionAdditionalFieldsKeys.aiPatentSearchesCount]: 1,
  [SubscriptionAdditionalFieldsKeys.aiTrademarkSearchesCount]: 1,
  [SubscriptionAdditionalFieldsKeys.searchTrademarkCount]: 0,
  [SubscriptionAdditionalFieldsKeys.reportTrademarkCount]: 0,
  [SubscriptionAdditionalFieldsKeys.support]: UserSubscriptionSupportPeriod.NONE,
  [SubscriptionAdditionalFieldsKeys.isPrime]: false,
  [SubscriptionAdditionalFieldsKeys.showProviders]: false,
  [SubscriptionAdditionalFieldsKeys.searchPriority]: false,
  [SubscriptionAdditionalFieldsKeys.autoOfferDelay]: 10,
};

export const COMPANY_ADDITIONAL_FIELDS_PROVIDER_DEFAULT = {
  ...COMPANY_ADDITIONAL_FIELDS_DEFAULT,
  [SubscriptionAdditionalFieldsKeys.support]: UserSubscriptionSupportPeriod.FIRST_MONTH,
};

export const COMPANY_ADDITIONAL_FIELDS_CUSTOMER_DEFAULT = {
  ...COMPANY_ADDITIONAL_FIELDS_DEFAULT,
  [SubscriptionAdditionalFieldsKeys.allowedOipCount]: 20,
};

export const COMPANY_ADDITIONAL_FIELDS_DELETED_VALUE = {
  ...COMPANY_ADDITIONAL_FIELDS_DEFAULT,
  [SubscriptionAdditionalFieldsKeys.searchTrademarkCount]: 0,
  [SubscriptionAdditionalFieldsKeys.reportTrademarkCount]: 0,
  [SubscriptionAdditionalFieldsKeys.aiPatentSearchesCount]: 0,
  [SubscriptionAdditionalFieldsKeys.aiTrademarkSearchesCount]: 0,
};
