<div (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()"
     [class.collapsed]="collapsed"
     [class.open]="collapsed && collapsedOpen$ | async"
     class="sidenav"
     fxLayout="column">
  <div class="sidenav-toolbar" fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
    <img class="w-8 select-none" fxFlex="none" src="assets/img/logo-light.svg">
    <h2 class="title ltr:pl-4 rtl:pr-4 select-none" fxFlex="auto">IPNote</h2>
    <button (click)="toggleCollapse()" class="w-8 h-8 -mr-2 leading-none" fxFlex="none" mat-icon-button type="button">
      <mat-icon *ngIf="!collapsed" [icIcon]="icRadioButtonChecked" size="24px"></mat-icon>
      <mat-icon *ngIf="collapsed" [icIcon]="icRadioButtonUnchecked" size="24px"></mat-icon>
    </button>
  </div>

  <div class="sidenav-items" fxFlex="auto" vexScrollbar>
    <vex-sidenav-item *ngFor="let item of navigationService.items; trackBy: itemTrackBy"
                      [item]="item"
                      [level]="0"></vex-sidenav-item>
    <ng-content></ng-content>
  </div>
</div>
