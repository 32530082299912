<div *ngIf="!isLoading" class="ogp bg-gray-25 flex flex-col">
  <img class="ogp__image mb-8" src="{{ogp?.og?.image}}" alt="">
  <div class="ogp__title ogp--ellipsis-two-stroke text-xl font-bold mb-6">{{ogp?.og?.title}}</div>
  <div class="ogp__description ogp--ellipsis flex-auto">{{ogp?.og?.description}}</div>
  <div class="ogp__read-more read-more flex items-center cursor-pointer" (click)="openLink()">
    <div class="read-more__text flex-auto">Read More</div>
    <div class="read-more__arrow flex items-center justify-center">
      <mat-icon>arrow_forward</mat-icon>
    </div>
  </div>
</div>
