import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  @Output() changePageIndex = new EventEmitter<number>();

  // tslint:disable-next-line:variable-name
  private _pageIndex: number;
  @Input() set pageIndex(pageIndex: number) {
    this._pageIndex = pageIndex;
  }

  get pageIndex(): number {
    return this._pageIndex;
  }

  // tslint:disable-next-line:variable-name
  private _length: number;
  @Input() set length(length: number) {
    this._length = length;
    this.updatePages();
  }

  get length(): number {
    return this._length;
  }

  // tslint:disable-next-line:variable-name
  private _pageSize: number;
  @Input() set pageSize(pageSize: number) {
    this._pageSize = pageSize;
    this.updatePages();
  }

  get pageSize(): number {
    return this._pageSize;
  }

  pages: number;

  constructor() {
  }

  private updatePages() {
    this.pages = Math.ceil(this.length / this.pageSize);
  }

  ngOnInit() {
  }

  previous() {
    this.changePageIndex.emit(this._pageIndex - 1);
  }

  next() {
    this.changePageIndex.emit(this._pageIndex + 1);
  }
}
