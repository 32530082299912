import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import icSearch from '@iconify/icons-ic/twotone-search';
import { ChoiceCompanyDialogComponent } from '../choice-company-dialog/choice-company-dialog.component';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UntypedFormControl } from '@angular/forms';
import { ICompanyEntity } from '@ipnote/interface';

@UntilDestroy()
@Component({
  selector: 'app-choice-companies-button',
  templateUrl: './choice-companies-button.component.html',
  styleUrls: ['./choice-companies-button.component.scss']
})
export class ChoiceCompaniesButtonComponent {
  @Input() companies: ICompanyEntity[];
  @Output() saveCompany = new EventEmitter<ICompanyEntity>();
  @Output() filterCompany = new EventEmitter<string>();

  searchCtrl = new UntypedFormControl();
  activeCompany: ICompanyEntity;
  icSearch = icSearch;

  constructor() {
    this.searchCtrl.valueChanges.pipe(debounceTime(400), untilDestroyed(this)).subscribe((token) => this.filterCompany.emit(token));
  }

  @ViewChild(ChoiceCompanyDialogComponent, { static: true }) dropdown: ChoiceCompanyDialogComponent;
  @ViewChild('ref') ref: ElementRef<HTMLElement>;

  public showDropdown($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.dropdown.show();
  }

  selectCompany(): void {
    this.saveCompany.emit(this.activeCompany);
    this.dropdown.hide();
  }
}
