<button mat-button
        type="button"
        [color]="'primary'"
        (click)="openDialog()"
>
  <mat-icon>add</mat-icon>
  <span>
    {{ ('add_files' | transloco) }}
  </span>
</button>

