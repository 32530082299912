import { Pipe, PipeTransform } from '@angular/core';
import { IUserEntity } from '@ipnote/interface';

@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {
  transform(value: IUserEntity, field: string): string {
    return value ? value[field] : null;
  }
}
