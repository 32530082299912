import { UserSettingsInterface } from './user-settings.interface';
import { RegistrationType } from '../enums/registration-type.enum';
import { ICompanyEntity } from '../../company/interface/company.entity.interface';
import { IPhone } from './phone.interface';

export class IJwtDto {
  expiresIn: string;
  accessToken: string;
}

export class IUserSignInRes {
  id: number;
  isStaff: boolean;
  avatar: string;
  email: string;
  emailConfirmedAt: Date | null;
  phone: IPhone;
  name: string;
  secondName: string;
  shortName: string;
  settings: UserSettingsInterface;
  flatPermissions: string[];
  invitedUsersCount: number;
  registrationType: RegistrationType;
  phoneConfirmedAt: Date | null;
  affiliateCode: {
    code: string;
  };
  twoFASkippedTimes: number;
  skipTwoFAUntil: Date | null;
  userHashIntercom: string; // https://www.intercom.com/
}

export class IUserSignInResDto {
  token: IJwtDto;
  user: IUserSignInRes;
  company: ICompanyEntity;
}

export class IUserSignInSocialResDto extends IUserSignInResDto {
  newRegistration: boolean;
}
