import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'centsToCurrency' })
export class CentsToCurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currencyCode: string = 'USD',
    display: 'symbol' | 'code' | 'name' | 'symbol-narrow' = 'symbol',
    digitsInfo: string = '1.2-2',
    locale: string = 'en-US',
  ): string {
    // Преобразование суммы из центов в доллары (или другую валюту)
    const amountInDollars = value / 100;

    // Использование встроенного currency pipe для форматирования суммы в денежную единицу
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: display,
      minimumFractionDigits: 2, // Минимальное количество десятичных знаков
      maximumFractionDigits: 2, // Максимальное количество десятичных знаков
    }).format(amountInDollars);
  }
}
