import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { FileViewerComponent } from '../../../../ui/file-viewer/file-viewer.component';

@Injectable({
  providedIn: 'root'
})
export class ShowFileService {
  constructor(private dialog: MatDialog) {
  }

  showFile(file: any) {
    // @TODO
    this.dialog.open(FileViewerComponent, { data: { file }, width: '95%', height: '95%' });
  }
}
