import { MarkifyLiveDeadIndicator } from '../enums/markify-live-dead-indicator.enum';
import { MarkifyMarkCurrentStatusCode } from '../enums/markify-mark-current-status-code.enum';
import { MarkifyMarkFeature } from '../enums/markify-mark-feature.enum';
import { MarkifySearchMode } from '../enums/markify-search-mode.enum';

export interface MarkifySearchResponse {
  meta: { totalFound: string; startIndex: string; pageLimit: string; message: string };
  result?: MarkifySearchResultObject[];
}

export interface MarkifySearchResultObject {
  mark: string;
  statusNorm: string;
  owners: string;
  market: string;
  designating: string;
  pfoDate: string;
  pfoEndDate: string;
  level: string;
  mode: MarkifySearchMode;
  applicationNumber: string;
  applicationDate: string;
  niceClassification: number[];
  currentTrademarkStatus: string;
  markFeature: string;
  gas?: string[];
  logo?: {
    filename: string;
    thumbnail: string;
  };
}

export interface MarkifySearchTrademarkResultObject {
  mark: string;
  applicationNumber: string;
  applicationDate: string;
  owners: string;
  currentTrademarkStatus: string;
  market: string;
  designating?: string;
  logo?: {
    filename: string;
    thumbnail: string;
  };
  gas?: string[];
  link?: string;
}

export interface MarkifyImportTrademarkResponse {
  response: {
    TransactionHeader: {
      SenderDetails: {
        RequestProducerDateTime: string;
      };
    };
    TradeMarkTransactionBody: {
      TransactionContentDetails: {
        TransactionCode: string;
        TransactionData: {
          TradeMarkDetails: MarkifyTrademarkDetails;
        };
      };
    };
  };
}

export interface MarkifyTrademarkDetails {
  TradeMark?: MarkifyTrademark | MarkifyTrademark[];
}

export interface MarkifyTrademark {
  RegistrationOfficeCode: string;
  ReceivingOfficeCode?: string;
  ApplicationNumber: string;
  ApplicationDate: string;
  RegistrationNumber?: string;
  RegistrationDate: string;
  ApplicationLanguageCode?: string;
  ExpiryDate: string;
  MarkCurrentStatusCode: string;
  MarkCurrentStatusDate?: string;
  DesignatedCountryDetails?: {
    DesignatedCountry: {
      DesignatedCountryCode: string;
      DesignatedUnderCode: string;
    }[];
  };
  PreviousRegistrationDetails?: {
    PreviousRegistration: PreviousRegistrationItem[];
  };
  BasicRegistrationApplicationDetails?: {
    BasicRegistrationApplication: {
      BasicRegistrationDetails: {
        BasicRegistration: {
          BasicRegistrationNumber: string;
          BasicRegistrationDate: string;
        };
      };
    };
  };
  KindMark?: string;
  MarkFeature: string;
  MarkDescriptionDetails: {
    MarkDescription_languagecode: string[];
    MarkDescription: string[];
  };
  OppositionPeriodStartDate: string;
  OppositionPeriodEndDate: string;
  WordMarkSpecification?: {
    MarkVerbalElementText_languagecode?: string;
    MarkVerbalElementText: string;
  };
  AssociatedMarkDetails?: {
    AssociatedApplicationNumber: string[];
  };
  PriorityDetails?: {
    Priority: {
      PriorityCountryCode: string;
      PriorityNumber: string;
      PriorityDate: string;
      PriorityPartialIndicator: string;
      PriorityStatusCode: string; // maybe enum
    };
  };
  MarkImageDetails: {
    MarkImage: {
      MarkImageFileFormat?: string;
      MarkImageCategory?: {
        CategoryKind: string;
        CategoryCodeDetails: {
          CategoryCode: string;
        };
      };
      MarkImageFilename?: string;
      MarkImageBinary?: string;
      MarkImageColourIndicator?: string;
      MarkImageColourPartClaimedText?: string;
      MarkifyImageURL: string;
    };
  };
  GoodsServicesDetails: {
    GoodsServices: {
      ClassificationKindCode: string;
      ClassDescriptionDetails: {
        ClassDescription: MarkifyClassItem | MarkifyClassItem[];
      };
    };
  };
  PublicationDetails?: IMarkifyPublication;
  ApplicantDetails: {
    Applicant: MarkifyApplicantItem | MarkifyApplicantItem[];
  };
  RepresentativeDetails: {
    Representative: MarkifyRepresentativeItem | MarkifyRepresentativeItem[];
  };
  CorrespondenceAddress?: {
    CorrespondenceAddressBook: AddressBook;
  };
  MarkRecordDetails: {
    MarkRecord: MarkifyMarkRecordItem | MarkifyMarkRecordItem[];
  };
  OppositionDetails: MarkifyOpposition;
  MarkifyPTOLink?: string;
  MarkifySerp2Link?: string;
  MarkifySerp2Permalink?: string;
  MarkifyMarkCurrentStatusCode: MarkifyMarkCurrentStatusCode;
  MarkifyMarkFeature: MarkifyMarkFeature;
  MarkifyMarkFeature3v?: string;
  MarkifyLiveDeadIndicator: MarkifyLiveDeadIndicator;
}

export interface MarkifyClassItem {
  ClassNumber: string;
  ClassNumberStatusCode: string;
  GoodsServicesDescription_languagecode: string | string[];
  GoodsServicesDescription: string | string[];
}

export interface MarkifyApplicantItem {
  ApplicantSequenceNumber?: string;
  ApplicantLegalEntity?: string;
  ApplicantIncorporationState?: string;
  Comment?: string;
  ApplicantAddressBook: AddressBook;
  ApplicantEntitlement?: {
    EntitlementNationalityCode: string;
    EntitlementEstablishment: {
      EntitlementEstablishmentCode: string;
    };
  };
}

export interface MarkifyRepresentativeItem {
  RepresentativeNationalityCode?: string;
  RepresentativeLegalEntity?: string;
  RepresentativeIncorporationCountryCode?: string;
  Comment?: string;
  RepresentativeAddressBook: AddressBook;
}

export interface AddressBook {
  FormattedNameAddress?: {
    Name: {
      FreeFormatName?: {
        FreeFormatNameDetails: {
          FreeFormatNameLine: string;
        };
      };
      FormattedName?: {
        FirstName?: string;
        LastName?: string;
        OrganizationName?: string;
      };
    };
    Address: {
      AddressCountryCode?: string;
      FreeFormatAddress?: {
        FreeFormatAddressLine: string;
      };
      FormattedAddress?: {
        AddressStreet: string;
        AddressCity: string;
        AddressPostcode: string;
      };
    };
  };
  FreeFormatAddressDetails?: {
    FreeFormatNameAddressLine: string;
  };
  PostalAddress?: string;
}

export interface PreviousRegistrationItem {
  PreviousRegistrationNumber: string;
  Comment?: string;
}

export interface IMarkifyPublication {
  Publication: PublicationItem | PublicationItem[];
}

export interface PublicationItem {
  PublicationIdentifier: string;
  PublicationSection: string;
  PublicationDate: string;
}

export interface MarkifyMarkRecordItem {
  BasicRecord: {
    RecordFilingDate?: string;
    RecordNotificationDate?: string;
    RecordEffectiveDate?: string;
    BasicRecordKind?: string;
    RecordReference?: string;
    RecordIdentifier?: string;
    RecordEventDetails?: {
      RecordEvent?: {
        RecordEventDate?: string;
        RecordEventCode?: string;
        Comment?: string;
      };
    };
    RecordInterestedOfficeCode?: string;
    GoodsServicesLimitationDetails?: {
      GoodsServicesLimitation?: {
        GoodsServicesLimitationCode?: string;
        LimitationClassDescriptionDetails?: {
          ClassDescription?: {
            ClassNumber?: string;
            GoodsServicesDescription_languagecode?: string;
            GoodsServicesDescription?: string;
          }[];
        };
      };
    };
    RecordPublicationDetails?: {
      RecordPublication?: {
        PublicationIdentifier?: string;
        PublicationDate?: string;
      };
    };
    RecordDesignatedCountryDetails?: {
      RecordDesignatedCountryCode?: string;
    };
    RecordCurrentStatusDate?: string;
    [key: string]: unknown | unknown[];
  };
}

export interface MarkifyOpposition {
  Opposition: {
    OppositionIdentifier?: string;
    OppositionDate?: string;
    ProceedingLanguageCode?: string;
    OppositionGroundText?: string;
    EarlierMarkDetails?: {
      EarlierMark: MarkifyEarlierMarkItem[];
    };
    OpponentDetails?: {
      Opponent?: {
        OpponentIdentifier?: string;
        OpponentNationalityCode?: string;
        OpponentLegalEntity?: string;
        OpponentIncorporationCountryCode?: string;
        OpponentNationalIdentification?: string;
        OpponentAddressBook: AddressBook;
      };
    };
    RepresentativeDetails?: {
      Representative: MarkifyRepresentativeItem;
    };
  };
}

export interface MarkifyEarlierMarkItem {
  EarlierMarkCountryCode: string;
  ApplicationNumber: string;
  ApplicationDate: string;
  RegistrationNumber: string;
  RegistrationDate: string;
  MarkFeature: string;
  WordMarkSpecification: {
    MarkVerbalElementText: string;
  };
}

export interface IAddTrademarkWatchRes {
  response?: {
    result: {
      id: number;
      ordernumber: string;
      tm: {
        ApplicationNumber: string;
        RegistrationNumber: string;
        ApplicationDate: string;
        MarkVerbalElementText: string;
        MarkifyImageURL: string;
      };
    };
  };
  error?: string[];
}

export interface IGetTrademarkWatchesRes {
  response: {
    result: ITrademarkWatch[];
    meta: {
      totalFound: string;
      pageNumber: number;
      pageLimit: number;
      startIndex: number;
      endIndex: number;
    };
  };
}

export interface ITrademarkWatch {
  id: string;
  appNum: string;
  regNum: string;
  marks: string;
  markets: string;
  timestamp: string;
  comment: string;
  renewal: 0 | 1;
  star: 0 | 1 | 2 | 3 | 4;
  label: string;
  postal_address: string;
  email_address: string;
  contact_name: string;
  partner_email: string;
  staff_name: string;
  bcc_email: string;
  reply_to_email: string;
  externalid: string;
  externalclientid: string;
  in_trial: 0 | 1;
  has_subscription: 0 | 1;
  trial_enddate: string;
  subscription_enddate: string;
  enddate: string;
  status: MarkifyMarkCurrentStatusCode;
}

export interface IDeleteTrademarkWatchRes {
  response: {
    result: {
      id: number;
      deleted_items: number;
    };
  };
}

export interface ITrademarkWatchReport {
  id: number;
  date: string;
  appNum: string;
  regNum: string;
  mark: string;
  market: string;
  diff?: IMarkifyTrademarkDiff[];
}

export interface IMarkifyTrademarkDiff {
  path: (string | number)[];
  type: 'update' | 'append' | 'remove';
  valueFrom: unknown;
  valueTo: unknown;
}

export interface MarkifyDBsRecencyResponse {
  response: {
    result: MarkifyDBsRecencyResult;
  };
}

export interface MarkifyDBsRecencyResult {
  [key: string]: MarkifyDBsRecencyResultItem;
}

export interface MarkifyDBsRecencyResultItem {
  last_application_date: string;
  last_update_date: string;
  lastest_publication_date: string;
  lastes_publication_id: string;
  description: string[];
}
