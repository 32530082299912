import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ICompanyEntity } from '@ipnote/interface';

@Component({
  selector: 'app-avatar-photos',
  templateUrl: './avatar-photos.component.html',
  styleUrls: ['./avatar-photos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarPhotosComponent implements OnInit, OnChanges {
  displayedCompanies: ICompanyEntity[];
  @Input() size = 32;
  @Input() companies: ICompanyEntity[];
  @Input() countOfDisplayedCompanies = 3;
  isLogoExist: boolean;
  constructor() {}
  ngOnInit() {}
  ngOnChanges() {
    this.displayedCompanies = this.companies.slice(0, this.countOfDisplayedCompanies);
  }
}
