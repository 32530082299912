<mat-dialog-content>
  <h2 mat-dialog-title>{{ 'choice-company' | transloco }}</h2>
  <div class="input-icon">
    <mat-icon>search</mat-icon>
    <input matInput [formControl]="searchControl" placeholder="{{ 'find' | transloco }}" autocomplete="off">
  </div>
  <button type="button" mat-button class="create-new-company" (click)="createCompany()"
          *ngIf="selectedCompany.type !== enums.companyTypeEnum.PROVIDER_CLIENT">
    <mat-icon>add</mat-icon>
    {{ ('create-new-company' | transloco).toUpperCase() }}
  </button>
  <mat-progress-spinner mode="indeterminate" *ngIf="!dataSet"></mat-progress-spinner>
  <mat-nav-list *ngIf="dataSet" class="overflow-hidden">
    <a class="title_company " mat-list-item *ngFor="let company of dataSet.data"
       (click)="select(company)"><span class="truncate ">{{ company.name }}</span></a>
  </mat-nav-list>
  <div fxLayoutGap="20px" fxLayout="row">

  </div>

</mat-dialog-content>
<div class="paginator">
  <mat-paginator
    [length]="dataSet?.total"
    [pageSize]="pageSize"
    (page)="pageChanged($event)"
    [pageIndex]="dataSet?.page-1"
    [hidePageSize]=true>
  </mat-paginator>
</div>
<!--<mat-dialog-actions>-->
<!--  <button mat-raised-button color="primary" (click)="selectAll({id:'*'})" fxFlex="50"-->
<!--          *ngIf="!data.disableSelectAll">{{ ('select-all' | transloco).toUpperCase() }}</button>-->
<!--  <app-paginator *ngIf="dataSet" [pageSize]="dataSet.count" [pageIndex]="dataSet.page" fxFlex="50"-->
<!--                 [length]="dataSet.total" (changePageIndex)="pageChanged($event)">-->
<!--  </app-paginator>-->
<!--</mat-dialog-actions>-->
