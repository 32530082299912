import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../base-url/base-url.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import {
  IGenerateAiResultRes,
  IGetManyDefaultResponse,
  INormalizedIpRight,
  ISearchLogsResponseResultSearch,
  ITrademarkWatchEntity,
  MarkifySearchResultObject,
} from '@ipnote/interface';
import { RequestQueryBuilderService } from '../request-query-builder/request-query-builder.service';
import { concatLatestFrom } from '@ngrx/effects';
import { CreateTrademarkWatchDto } from '@ipnote/dto';

@Injectable({
  providedIn: 'root',
})
export class SearchByTrademarkService {
  constructor(
    private http: HttpClient,
    private baseUrl: BaseUrlService,
    private queryBuilder: RequestQueryBuilderService,
  ) {}

  searchByTrademark(body: any): Observable<ISearchLogsResponseResultSearch<MarkifySearchResultObject[]>> {
    return this.baseUrl
      .getBaseUrl('markify/search-trademarks')
      .pipe(mergeMap((url) => this.http.post<ISearchLogsResponseResultSearch<MarkifySearchResultObject[]>>(url, body)));
  }

  aiGenerateReport(body: any): Observable<IGenerateAiResultRes> {
    return this.baseUrl
      .getBaseUrl('markify/ai-result')
      .pipe(mergeMap((url) => this.http.post<IGenerateAiResultRes>(url, body)));
  }

  searchByNumber(number: string, countries: string): Observable<INormalizedIpRight[]> {
    return this.baseUrl.getBaseUrl('markify/normalized-trademark').pipe(
      concatLatestFrom(() => [
        this.queryBuilder.buildRequest({
          additionalOptions: { number, countries },
        }),
      ]),
      mergeMap(([url, params]) => this.http.get<INormalizedIpRight[]>(url, { params })),
    );
  }
  addTrademarkWatches(body: CreateTrademarkWatchDto): Observable<ITrademarkWatchEntity> {
    return this.baseUrl
      .getBaseUrl('trademark-watches')
      .pipe(mergeMap((url) => this.http.post<ITrademarkWatchEntity>(url, body)));
  }

  getTrademarkWatches(): Observable<IGetManyDefaultResponse<ITrademarkWatchEntity>> {
    return this.baseUrl.getBaseUrl('trademark-watches').pipe(
      concatLatestFrom(() => [this.queryBuilder.buildRequest({})]),
      mergeMap(([url, params]) => this.http.get<IGetManyDefaultResponse<ITrademarkWatchEntity>>(url, { params })),
    );
  }
}
