import { TakeAJobDialogComponent } from './../take-a-job-dialog/take-a-job-dialog.component';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-take-a-job',
  templateUrl: './take-a-job.component.html',
  styleUrls: ['./take-a-job.component.scss']
})
export class TakeAJobComponent {
  @ViewChild('ref') ref: ElementRef<HTMLElement>;
  @ViewChild(TakeAJobDialogComponent, { static: true }) dropdown: TakeAJobDialogComponent;

  @Input() nameKey = 'take-a-job';

  @Input() disable: boolean;
  @Input() color: string;
  @Output() reopenJob = new EventEmitter();
  @Output() acceptJob = new EventEmitter();

  constructor() {
  }

  prevent($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
  }

  public showDropdown($event: MouseEvent): void {
    this.prevent($event);
    this.dropdown.show();
  }

  reopenHandler($event: Event): void {
    this.prevent($event);
    this.dropdown.hide();
    this.reopenJob.emit();
  }

  acceptHandler($event: Event): void {
    this.prevent($event);
    this.dropdown.hide();
    this.acceptJob.emit();
  }
}
