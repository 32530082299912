import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-entity-chips',
  templateUrl: './entity-chips.component.html',
  styleUrls: ['./entity-chips.component.scss']
})
export class EntityChipsComponent implements OnInit {
  _reference: HTMLElement | null = null;

  @Input() set reference(value: HTMLElement | null) {
    this._reference = value;
  }

  get reference() {
    return this._reference;
  }

  @ViewChild(CdkPortal, { static: true })
  public contentTemplate: CdkPortal;

  protected overlayRef: OverlayRef;
  private showing: boolean;

  public hide() {
    this.overlayRef.detach();
    this.showing = false;
  }

  public show() {
    this.overlayRef = this.overlay.create(this.getOverlayConfig());

    this.overlayRef.attach(this.contentTemplate);
    this.overlayRef.backdropClick().subscribe(() => this.hide());
    this.showing = true;
  }

  protected getOverlayConfig(): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.reference)
      .withGrowAfterOpen(true)
      .withPositions([
        {
          originX: 'start',
          originY: 'center',
          offsetY: -65,
          offsetX: -250,
          overlayX: 'end',
          overlayY: 'top'
        }
      ]);

    return new OverlayConfig({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop'
    });
  }

  constructor(private overlay: Overlay) {
  }

  ngOnInit() {
  }
}
