import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-button',
  templateUrl: './file-button.component.html',
  styleUrls: ['./file-button.component.scss']
})
export class FileButtonComponent implements OnInit {
  @Output() fileChange = new EventEmitter<File>();

  constructor() {
  }

  ngOnInit() {
  }

  processFile($event: Event) {
    // tslint:disable-next-line:deprecation
    const target = ($event.target || $event.srcElement) as HTMLInputElement;
    const file = target.files.item(0);
    target.value = '';
    this.fileChange.emit(file);
  }
}
