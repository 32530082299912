import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfiguration } from '../../configuration/app.configuration';

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent {
  url: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { file: any },
    sanitizer: DomSanitizer,
    private config: AppConfiguration
  ) {
    /**
     * Проверяем для старых файлов
     */
    if (data.file.url.includes(this.config.contentBaseUrl)) {
      this.url = sanitizer.bypassSecurityTrustResourceUrl(data.file.url);
    } else {
      this.url = sanitizer.bypassSecurityTrustResourceUrl(`${this.config.filesServerBaseUrl}/${data.file.url}`);
    }
  }
}
