import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { Observable } from 'rxjs';
import { IUserSignInRes } from '@ipnote/interface';
import { ICompanyEntity } from '@ipnote/interface';
import { selectStateSelectedCompany, selectStateUserInfo } from '#selectors';
import { NotifierService } from '../../../../page-modules/desk/services/notifier/notifier.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
@UntilDestroy()
@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
})
export class SendEmailDialogComponent {
  public userStore$: Observable<IUserSignInRes> = this.store.select(selectStateUserInfo);
  user: IUserSignInRes;
  public companySelectStore$: Observable<ICompanyEntity> = this.store.select(selectStateSelectedCompany);
  public selectedCompany: ICompanyEntity;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private store: Store<AppState>,
              private dialogRef: MatDialogRef<SendEmailDialogComponent>,
              private notifierService: NotifierService,
              private snackBar: MatSnackBar) {
    this.companySelectStore$.subscribe(company => {
      this.selectedCompany = company;
    });
    this.userStore$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.user = user;
    });
  }

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  close(): void {
    this.dialogRef.close(null);
  }
  send() {
    this.notifierService.sendEmail({
      companyName: this.selectedCompany.name,
      fullUserName: this.user.name + ' ' + this.user.secondName,
      email: this.emailFormControl.getRawValue(),
      affiliateCode: this.user.affiliateCode.code
    }).subscribe(res => {
      this.snackBar.open('Invitation has been sent', 'OK', { duration: 3000 });
    })

  }
}
