<h2>
  <button class="button-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="offer-price-title">
    {{
      (data.task &&
      data.task.serviceRequests[0].offer &&
      data.task.serviceRequests[0].offer.price &&
      data.task.serviceRequests[0].offer.days
        ? 'update_offer'
        : 'offer'
      ) | transloco
    }}
  </div>
</h2>
<mat-dialog-content class="">
  <div class="subtitle">{{ 'cost-of-job' | transloco }}</div>
  <div class="text-muted">{{ 'service_charge_description' | transloco }}</div>
  <form [formGroup]="form" autocomplete="off">
    <div class="input-wrapper flex flex-nowrap flex-row gap-2">
      <mat-form-field class="flex-grow-[1] input-line relative">
        <mat-label>{{ 'cost-of-job' | transloco }}</mat-label>
        <input
          matInput
          mask="separator.2"
          decimalMarker="."
          thousandSeparator=","
          formControlName="price"
          placeholder=""
          required
        />
        <mat-icon
          *ngIf="data.task.serviceRequests[0].offer?.maxRangePrice > 0"
          class="text-red-600 payment-pending-icon"
          [matTooltip]="'You need to specify the price' | transloco"
        >
          error_outline
        </mat-icon>
      </mat-form-field>
      <mat-form-field class="flex-grow-[2] input-currency">
        <mat-label>{{ 'currency' | transloco }}</mat-label>
        <mat-select formControlName="currency">
          <mat-option>
            <ngx-mat-select-search
              [formControl]="currencySearch"
              [placeholderLabel]="'search' | transloco"
              [noEntriesFoundLabel]="'not-found' | transloco"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let currency of filteredCurrency | async" [value]="currency">{{ currency }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="text-muted"
      *ngIf="selectedCompany?.additionalFields?.incomingFeeSize && form.value.price && form.value.currency"
    >
      {{
        'service_charge_help'
          | transloco
            : {
                sum: form.value.price - ipnoteFee | number : '1.0-2' : 'en-US',
                currency: form.value.currency,
                fee: ipnoteFee | number : '1.0-2' : 'en-US'
              }
      }}
    </div>
    <app-call-to-action
      *ngIf="!(selectedCompany$ | async)?.additionalFields?.isPrime && (selectedCompany$ | async).isServiceProvider"
      [cameFrom]="'suggest-offer'"
      [text]="'Upgrade your plan to Prime and reduce commission.'"
    >
    </app-call-to-action>
    <div class="subtitle">{{ 'goverenment_fee' | transloco }}</div>
    <div class="text-muted">{{ 'fee_description' | transloco }}</div>
    <div class="input-wrapper flex flex-nowrap flex-row gap-2">
      <mat-form-field class="flex-grow-[1] input-line">
        <mat-label>{{ 'cost_of_compensation' | transloco }}</mat-label>
        <input
          autocomplete="off"
          matInput
          mask="separator.2"
          decimalMarker="."
          thousandSeparator=","
          formControlName="compensationPrice"
          placeholder=""
          (ngModelChange)="changeCompensationCurrency($event)"
        />
      </mat-form-field>
      <mat-form-field class="flex-grow-[2] input-currency">
        <mat-label>{{ 'currency' | transloco }}</mat-label>

        <mat-select formControlName="compensationCurrency">
          <mat-option>
            <ngx-mat-select-search
              [clearSearchInput]="false"
              [formControl]="compensationCurrencySearch"
              [placeholderLabel]="'search' | transloco"
              [noEntriesFoundLabel]="'not-found' | transloco"
            >
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let currency of filteredCompensationCurrency | async" [value]="currency">{{
            currency
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="text-muted">{{ 'fee_help' | transloco }}</div>
    <div class="subtitle">{{ 'deadline-of-job' | transloco }}</div>
    <div class="text-muted">{{ 'delivery_time_description' | transloco }}</div>
    <div class="input-wrapper inline">
      <mat-form-field class="input-days">
        <mat-label>{{ 'deadline-of-job' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          min="1"
          formControlName="days"
          required
          inputmode="numeric"
          [onlyNumber]="true"
          pattern="[0-9]*"
        />
      </mat-form-field>
      <span>{{ 'work-days' | transloco }}</span>
    </div>
    <!-- <mat-form-field class="textarea-wrapper">
       <mat-label>{{'description' | transloco}}</mat-label>
       <textarea matInput formControlName="description"></textarea>
     </mat-form-field>-->
  </form>
  <div class="subtitle">{{ 'total' | transloco }}</div>
  <div fxLayout="column" fxLayoutGap="6px" class="detail-price">
    <div>
      <span>{{ 'your_reward' | transloco }}</span>
      <span *ngIf="form.value.price">
        {{ form.value.price - ipnoteFee | number : '1.0-2' : 'en-US' }} {{ form.value.currency }}
      </span>
    </div>
    <div>
      <span>{{ 'goverenment_fee_size' | transloco }}</span>
      <span *ngIf="form.value.compensationPrice">
        {{ form.value.compensationPrice - 0 | number : '1.0-2' : 'en-US' }} {{ form.value.compensationCurrency }}
      </span>
    </div>
  </div>
  <div class="offer-price-files" *ngIf="file">
    <span>{{ file?.name }}</span>
    <button (click)="deleteFile()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <input [style.display]="'none'" #fileInput type="file" (change)="handleFile($event)" />
</mat-dialog-content>
<mat-dialog-actions align="start">
  <button
    mat-raised-button
    color="primary"
    class="send-button"
    (click)="send()"
    [disabled]="form.invalid || formSaveInProcess"
  >
    {{ 'send' | transloco }}
  </button>
</mat-dialog-actions>
