import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';

const publicTour = {
  defaultStepOptions: {
    scrollTo: true,
    classes: 'shepherd-theme-custom',
    cancelIcon: {
      enabled: true
    },
    canClickTarget: true,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20]
          }
        }
      ]
    },
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 4
  },
  steps: [
    {
      attachTo: {
        element: 'a[href*="/company/public"]',
        on: 'right'
      },

      title: 'Public profile',
      text: '<p> Please complete your company profile in the section Company / Public profile.</p><p>It will help clients quickly and easily review your information.</p>'
    }
  ]
};
const serviceTour = {
  defaultStepOptions: {
    scrollTo: true,
    classes: 'shepherd-theme-custom',
    cancelIcon: {
      enabled: true
    },
    canClickTarget: true,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20]
          }
        }
      ]
    },
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 4
  },
  steps: [
    {
      attachTo: {
        element: 'a[href*="/company/services"]',
        on: 'right'
      },
      title: 'Add “Services”',
      text: '<p>Open "Services" menu and fill in at least 3 types of services that you provide.</p><p>This step is essential in order for you to appear in the customers\' searches.</p>'
    }
  ]
};
const newTaskButtonTour = {
  defaultStepOptions: {
    classes: 'shepherd-theme-custom',
    scrollTo: true,
    cancelIcon: {
      enabled: true
    },
    canClickTarget: true,
    popperOptions: {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 20]
          }
        }
      ]
    },
    modalOverlayOpeningPadding: 8,
    modalOverlayOpeningRadius: 4
  },
  steps: [
    {
      attachTo: {
        element: '#add-task-btn',
        on: 'right'
      },
      title: 'Click on “New task”',
      text: '<p>Let\'s create a new task to complete this step.</p><p> Please create only real tasks. Otherwise, it would not pass approval.</p>'
    }
  ]
};


@Injectable({
  providedIn: 'root'
})
export class ProviderOnboardingService {


  constructor(private guideTour: ShepherdService) {
  }

  openPublicProfileTour() {
    this.guideTour.defaultStepOptions = publicTour.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    // @ts-ignore
    this.guideTour.addSteps(publicTour.steps);
    this.guideTour.start();
    const target = document.getElementById('publicProfile');
    target.addEventListener('click', () => this.guideTour.hide());
  }

  openServicesTour() {
    this.guideTour.defaultStepOptions = serviceTour.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    // @ts-ignore
    this.guideTour.addSteps(serviceTour.steps);
    this.guideTour.start();
    const target = document.getElementById('services');
    target.addEventListener('click', () => this.guideTour.hide());
  }

  openNewTaskButtonTour() {
    this.guideTour.defaultStepOptions = publicTour.defaultStepOptions;
    this.guideTour.modal = true;
    this.guideTour.confirmCancel = false;
    // @ts-ignore
    this.guideTour.addSteps(newTaskButtonTour.steps);
    this.guideTour.start();
    const target = document.getElementById('add-task-btn');
    target.addEventListener('click', () => this.guideTour.hide());
  }
}
