import { DexatelChannel } from '../enums/dexatel-channel.enum';

export interface IDexatelCreateFlashCallVerificationBody {
  data: {
    phone: string;
    channel: DexatelChannel.VOICE;
    code_length: number;
  };
}

export interface IDexatelCreateSmsVerificationBody {
  data: {
    sender: string;
    phone: string;
    code_length: number;
    template: string;
  };
}
