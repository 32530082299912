import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseUrlService } from '../../../../app-common/services/base-url/base-url.service';
import { flatMap, mergeMap } from 'rxjs/operators';
import { InvitationDto } from '@ipnote/dto';

@Injectable({
  providedIn: 'root'
})
export class NotifierService {

  constructor(private http: HttpClient, private baseUrl: BaseUrlService) {
  }

  sendEmail(body: InvitationDto) {
    return this.baseUrl.getBaseUrl('notifier').pipe(mergeMap((url) => this.http.post<any>(url, body)));
  }

}
