import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { debounceTime, mergeMap, startWith, tap } from 'rxjs/operators';
import { SearchInterface } from '../../../services/search.interface';
import { RelationAvatar } from '../dataset/RelationAvatar';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiSelectDropdownComponent } from '../../multi-select-dropdown/multi-select-dropdown.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import { ErrorService } from '../../../services/error-service/error-service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService } from '../../../services/dialogs/dialog.service';
import { IOIPEntity } from '@ipnote/interface';

@UntilDestroy()
@Component({
  selector: 'app-links-chips',
  templateUrl: './links-chips.component.html',
  styleUrls: [ './links-chips.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LinksChipsComponent),
      multi: true
    }
  ]
})
export class LinksChipsComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  private onChange: (value: any) => void;
  private onTouched: () => void;
  loading = true;
  separatorKeysCodes: number[] = [ ENTER, COMMA ];
  filtered: any[] = [];
  entity: any[] = [];
  selectedValues: any[] = [];
  formControl = new UntypedFormControl();
  // tslint:disable-next-line:variable-name
  _inFilter = false;

  icSearch = icSearch;

  @ViewChild(MultiSelectDropdownComponent, { static: true }) dropdown: MultiSelectDropdownComponent;

  @ViewChild('input') linkInput: ElementRef<HTMLInputElement>;

  @ViewChild('ref') ref: ElementRef<HTMLElement>;
  // @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  @Output() openModel: EventEmitter<any> = new EventEmitter<any>();
  @Input() target: IOIPEntity;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() modelName: string;
  @Input() disable: boolean;
  @Input() searcher: SearchInterface;
  @Input() exclude: number[];
  @Input() currentEntity: string;

  @Input() commonView: boolean;

  @Input()
  set inFilter(value: boolean) {
    this._inFilter = value;
  }

  get inFilter(): boolean {
    return this._inFilter;
  }

  constructor(private cdRef: ChangeDetectorRef,
              private router: Router,
              private route: ActivatedRoute,
              public errorService: ErrorService,
              private transloco: TranslocoService,
              private dialogs: DialogService) {
  }

  ngOnInit(): void {

    this.formControl.valueChanges.pipe(
      startWith(null),
      debounceTime(1000),
      tap(() => this.loading = true),
      mergeMap(searchToken => this.searcher.search(searchToken)),
      untilDestroyed(this)
    ).subscribe({
      next: res => {
        this.filtered = res.filter(result => result?.id !== this.target?.id);
        this.loading = false
      },
      error: err => {
        this.dialogs.error(err)
        this.loading = false
      },
    })
  }

  async openLink(id: string, e: Event): Promise<void> {
    let entity = null;

    switch (this.modelName) {
      case 'parents':
      case 'children':
        entity = this.currentEntity;
        break;
      case 'authors':
      case 'rightHolders':
        entity = 'counterparties';
        break;
      case 'counterparties':
        entity = 'counterparties';
        break;
      default:
        entity = this.modelName;
        break;
    }

    e.preventDefault();

    const path = this.inFilter ? '..' : '../../..';
    try {
      if (entity === 'tasks') {
        await this.router.navigate([ path, entity, id, 'info', 'provider' ], {
          replaceUrl: true,
          relativeTo: this.route.parent
        });
      } else {
        await this.router.navigate([ path, entity, id, 'info' ], {
          replaceUrl: true,
          relativeTo: this.route.parent
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  remove(model: RelationAvatar): void {
    const index = this.selectedValues.indexOf(model);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
      if (this.onChange) {
        this.onChange(this.selectedValues);
      }
    }
  }

  // selected(event: MatAutocompleteSelectedEvent): void {
  //   const object = event.option.value;
  //   this.selectedValues.push(object);
  //
  //   this.linkInput.nativeElement.value = '';
  //   this.formControl.setValue(null);
  //   if (this.onChange) {
  //     this.onChange(this.selectedValues);
  //   }
  // }

  ngAfterViewInit(): void {
    this.setDisabledState(this.disable);
    this.cdRef.detectChanges();
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.selectedValues = [];
    } else {
      this.selectedValues = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    if (disabled) {
      this.formControl.disable();
    } else {
      //this.formControl.enable();
    }
  }

  includes(id: any): boolean {
    return this.exclude.includes(id);
  }

  alreadyIn(id: string): boolean {
    return !!this.selectedValues.find((o) => o.id === id);
  }

  checkboxSelect(object: any): void {
    const index = this.selectedValues.findIndex((o) => o.id === object.id);
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    } else {
      this.selectedValues.push(object);
    }
    if (this.onChange) {
      this.onChange(this.selectedValues);
    }
  }

  public hideDropdown(): void {
    this.linkInput.nativeElement.blur();
    this.dropdown.hide();
  }

  public showDropdown($event: MouseEvent): void {
    $event.stopPropagation();
    this.dropdown.show();
    setTimeout(() => {
      this.linkInput.nativeElement.focus();
    });
  }
}
