<mat-form-field class="example-chip-list">
  <mat-label>{{ label | transloco }}</mat-label>
  <mat-chip-list #chipList aria-label="">
    <div class="chip-info-container">
      <h2 class="link-title" [class.subtitle]="commonView">
        {{ label | transloco }}
      </h2>
      <div #ref class="button-wrapper">
        <button *ngIf="!commonView"
                type="button"
          mat-button
          [disabled]="disable"
          (click)="showDropdown($event)">
          <mat-icon>add</mat-icon>
          {{ 'add' | transloco }}
        </button>
      </div>
    </div>
    <mat-chip
      *ngFor="let value of selectedValues"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(value)"
      (click)="openModel.emit(value)">
      <button (click)="openLink(value.id, $event)">
        <div class="open-link">
          {{value.shortName || value.name || value.title}}
        </div>
      </button>
      <mat-icon *ngIf="!disable && !commonView" matChipRemove>cancel</mat-icon>
    </mat-chip>

    <div fxFlex></div>

    <app-multi-select-dropdown [reference]="ref">
      <div class="dropdown-panel fix-height">
        <div class="dropdown-search">
          <ic-icon [icIcon]="icSearch" size="20px"></ic-icon>
          <input class="input" placeholder="{{ placeholder | transloco }}"
                 title="{{ placeholder | transloco }}"
                 #input
                 [formControl]="formControl">
        </div>
        <div *ngIf="loading" class="flex h-full items-center justify-center">
          <mat-spinner [diameter]="45" class="mx-auto"></mat-spinner>
        </div>
        <div class="entity-list" vexScrollbar *ngIf="!loading">
          <ng-container *ngFor="let found of filtered">
            <div class="links-item">
              <mat-checkbox [checked]="alreadyIn(found.id)" (change)="checkboxSelect(found)">
                <div class="flex flex-auto">
                  <div class="links-item-overflow">{{found.shortName || found.name || found.title}}</div>
                  <img [src]="found?.countries[0] | countryFlag"
                       *ngIf="found?.countries"
                       matTooltipPosition="left"
                       [matTooltip]="found?.countries[0] | transloco"
                       class="country-flag">
                </div>
              </mat-checkbox>
            </div>
          </ng-container>
        </div>
      </div>
    </app-multi-select-dropdown>
  </mat-chip-list>
</mat-form-field>


